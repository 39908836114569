// src/i18n.ts
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enUS from './locales/en-US.json';  // English translations

const savedLanguage = localStorage.getItem('i18nextLng') || 'en'; // Default to English

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: enUS,  // English translations
      }
    },
    lng: savedLanguage,    // Set the initial language from localStorage
    fallbackLng: 'en',     // Fallback language
    interpolation: {
      escapeValue: false,  // React already does escaping
    },
  });

export default i18n;
