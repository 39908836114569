import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash, faEdit } from "@fortawesome/free-solid-svg-icons";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Modal, Button } from "react-bootstrap";
import "./DutyChart.css";

interface FormData {
  khetra: string;
  satsangType: string;
  date: string;
  time: string;
  venue: string;
}

interface TableDataRow {
  srNo: number;
  unitNumber: string;
  branch: string;
  sewaPerception: string;
  note: string;
  signature: string;
}

const DutyChart: React.FC = () => {
  const [formData, setFormData] = useState<FormData>({
    khetra: "III",
    satsangType: "",
    date: "",
    time: "",
    venue: "",
  });

  const [tableData, setTableData] = useState<TableDataRow[]>([
    {
      srNo: 1,
      unitNumber: "",
      branch: "",
      sewaPerception: "",
      note: "",
      signature: "",
    },
  ]);

  const [showSewaModal, setShowSewaModal] = useState<boolean>(false);
  const [showNoteModal, setShowNoteModal] = useState<boolean>(false);
  const [currentRowIndex, setCurrentRowIndex] = useState<number>(-1);
  const [sewaPerceptionContent, setSewaPerceptionContent] =
    useState<string>("");
  const [noteContent, setNoteContent] = useState<string>("");

  const unitNumbers = ["12345", "67890", "54321"];
  const branches: { [key: string]: string } = {
    "12345": "Branch A",
    "67890": "Branch B",
    "54321": "Branch C",
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  const handleTableChange = (index: number, name: string, value: string) => {
    const updatedTableData = [...tableData];
    if (updatedTableData[index]) {
      updatedTableData[index] = {
        ...updatedTableData[index],
        [name]: value,
      };
      if (name === "unitNumber") {
        updatedTableData[index].branch = branches[value] || "";
      }
      setTableData(updatedTableData);
    }
  };

  const addRow = () => {
    setTableData([
      ...tableData,
      {
        srNo: tableData.length + 1,
        unitNumber: "",
        branch: "",
        sewaPerception: "",
        note: "",
        signature: "",
      },
    ]);
  };

  const deleteRow = (index: number) => {
    const updatedTableData = tableData.filter((_, i) => i !== index);
    setTableData(
      updatedTableData.map((row, idx) => ({ ...row, srNo: idx + 1 }))
    );
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log("Form Data:", formData);
    console.log("Table Data:", tableData);
  };

  const openEditor = (index: number, type: "sewa" | "note") => {
    setCurrentRowIndex(index);
    if (type === "sewa") {
      setSewaPerceptionContent(tableData[index].sewaPerception);
      setShowSewaModal(true);
    } else {
      setNoteContent(tableData[index].note);
      setShowNoteModal(true);
    }
  };

  const handleSewaModalClose = () => {
    if (currentRowIndex > -1) {
      const updatedTableData = [...tableData];
      updatedTableData[currentRowIndex].sewaPerception = sewaPerceptionContent;
      setTableData(updatedTableData);
    }
    setShowSewaModal(false);
  };

  const handleNoteModalClose = () => {
    if (currentRowIndex > -1) {
      const updatedTableData = [...tableData];
      updatedTableData[currentRowIndex].note = noteContent;
      setTableData(updatedTableData);
    }
    setShowNoteModal(false);
  };

  return (
    <div className="container-fluid mt-5">
      <div className="row justify-content-center mt-3">
        <div className="col-12 col-lg-12">
          <div className="card shadow">
            <div className="card-body">
              <legend>
                <b>Sant Nirankari Mandal (Regd.)</b>
              </legend>
              <p>
                <b>Sewadal Vibhag, Kshetra: {formData.khetra}</b>
              </p>

              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group mt-3">
                      <label htmlFor="satsangType">Satsang Type</label>
                      <input
                        type="text"
                        className="form-control"
                        id="satsangType"
                        value={formData.satsangType}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mt-3">
                      <label htmlFor="date">Date</label>
                      <input
                        type="date"
                        className="form-control"
                        id="date"
                        value={formData.date}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="time">Time</label>
                      <input
                        type="time"
                        className="form-control"
                        id="time"
                        value={formData.time}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="venue">Venue</label>
                      <input
                        type="text"
                        className="form-control"
                        id="venue"
                        value={formData.venue}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                </div>

                <h5 className="text-center mt-4">
                  <b>Sewa Schedule</b>
                </h5>

                <div className="table-responsive mt-2">
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th>SR. No</th>
                        <th>Unit Number</th>
                        <th>Branch</th>
                        <th>Sewa Perception</th>
                        <th>Note</th>
                        <th>Signature</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {tableData.map((row, index) => (
                        <tr key={index}>
                          <td>{row.srNo}</td>
                          <td>
                            <select
                              name="unitNumber"
                              value={row.unitNumber}
                              onChange={(e) =>
                                handleTableChange(
                                  index,
                                  "unitNumber",
                                  e.target.value
                                )
                              }
                              className="form-control"
                            >
                              <option value="">Select Unit Number</option>
                              {unitNumbers.map((unit) => (
                                <option key={unit} value={unit}>
                                  {unit}
                                </option>
                              ))}
                            </select>
                          </td>
                          <td>
                            <input
                              type="text"
                              name="branch"
                              value={row.branch}
                              readOnly
                              className="form-control"
                            />
                          </td>
                          <td>
                            <button
                              className="editicon"
                              type="button"
                              onClick={() => openEditor(index, "sewa")}
                            >
                              <FontAwesomeIcon
                                icon={faEdit}
                                style={{ color: "#007bff" }}
                              />
                            </button>
                            <span
                              className="text-wrap"
                              dangerouslySetInnerHTML={{
                                __html: row.sewaPerception || "Click to edit",
                              }}
                            />
                          </td>
                          <td>
                            <button
                              className="editicon"
                              type="button"
                              onClick={() => openEditor(index, "note")}
                            >
                              <FontAwesomeIcon
                                icon={faEdit}
                                style={{ color: "#007bff" }}
                              />
                            </button>
                            <span
                              className="text-wrap"
                              dangerouslySetInnerHTML={{
                                __html: row.note || "Click to edit",
                              }}
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              name="signature"
                              value={row.signature}
                              onChange={(e) =>
                                handleTableChange(
                                  index,
                                  "signature",
                                  e.target.value
                                )
                              }
                              className="form-control"
                            />
                          </td>
                          <td>
                            <button
                              className="addRow"
                              type="button"
                              onClick={addRow}
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title="Add Row"
                            >
                              <FontAwesomeIcon
                                icon={faPlus}
                                style={{ color: "#007bff" }}
                              />
                            </button>
                            {index > 0 && (
                              <button
                                className="deleteRow"
                                type="button"
                                onClick={() => deleteRow(index)}
                                style={{ marginLeft: "10px" }}
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title="Delete Row"
                              >
                                <FontAwesomeIcon
                                  icon={faTrash}
                                  style={{ color: "red" }}
                                />
                              </button>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>

                <div style={{ textAlign: "right", marginTop: "1rem" }}>
                  <button type="submit" className="btn btn-primary">
                    Submit
                  </button>
                </div>
              </form>

              <Modal show={showSewaModal} onHide={handleSewaModalClose}>
                <Modal.Header closeButton>
                  <Modal.Title>Edit Sewa Perception</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <ReactQuill
                    value={sewaPerceptionContent}
                    onChange={setSewaPerceptionContent}
                  />
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleSewaModalClose}>
                    Save Changes
                  </Button>
                </Modal.Footer>
              </Modal>

              <Modal show={showNoteModal} onHide={handleNoteModalClose}>
                <Modal.Header closeButton>
                  <Modal.Title>Edit Note</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <ReactQuill value={noteContent} onChange={setNoteContent} />
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleNoteModalClose}>
                    Save Changes
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DutyChart;
