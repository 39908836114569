import React from 'react';
import ReactDOM from 'react-dom/client'; // React 18 API
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './redux/store'; // Make sure to create a Redux store
import 'bootstrap/dist/js/bootstrap.bundle.min.js'; // Import Bootstrap JS
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css'; // Ensure this comes after Bootstrap for custom styling
import './i18n'; // Import i18n configuration
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import App from './App';

// Ensure you use the correct API for React 18
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const queryClient = new QueryClient();

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <Provider store={store}> {/* Wrap the App component with Provider */}
        <Router>
        <App/>
        </Router>
      </Provider>
    </QueryClientProvider>
  </React.StrictMode>
);
