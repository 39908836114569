import React, { useEffect, useState } from 'react';
import "./Units.css"
import { useMutation } from '@tanstack/react-query';
import { UnitDataModel } from 'src/Common/Models/Unit/UnitDataModel';
import { GetUnitApi } from 'src/api/unitApi';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'react-bootstrap';
import { SewadalBulkUploadApi } from 'src/api/sewadalApi';
import { useDispatch } from 'react-redux';
import { hideLoader, showLoader } from 'src/redux/loaderSlice';



const Units: React.FC = () => {
  const [unitData, setUnitData] = useState<UnitDataModel[]>([]);
  const navigate = useNavigate();
  const [file, setFile] = useState<File | null>(null);
  const dispatch = useDispatch();

  const getMutation = useMutation<UnitDataModel[]>({
    mutationFn: GetUnitApi,
    onSuccess: (data: UnitDataModel[]) => {
      setUnitData(data);
      dispatch(hideLoader());
    },
    onError: (error: any) => {
      console.error("Api failed:", error.response?.data || error.message);
    }
  });

  useEffect(() => {
    dispatch(showLoader());
    getMutation.mutate();
  }, [])

  const navigateToAddUnit = () => {
    navigate('/unit-upload-bulk')
  }

  const bulkUploadMutation = useMutation<void, Error, File>({
    mutationFn: SewadalBulkUploadApi,
    onSuccess: () => {
      getMutation.mutate();
      setFile(null);
      const fileInput = document.getElementById('fileUpload') as HTMLInputElement;
      if (fileInput) {
        fileInput.value = ''; // Reset the input field value
      }
    },
    onError: (error: any) => {
      console.error("Api failed:", error.response?.data || error.message);
    }
  });

  const handleSubmit = (selectedFile: File) => {
    if (selectedFile)
      bulkUploadMutation.mutate(selectedFile)
  };

  const addAdhikari = (unitNo: number) => {
    navigate(`/sewadal/add`, {
      state: { unitNo }  // Pass the satsangId as state
    });
  }

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0];
    if (selectedFile) {
        setFile(selectedFile);
        console.log(file);
        handleSubmit(selectedFile);
    }
};

  return (
    <div className="container-fluid mt-5">
  <div className="row justify-content-center mt-3">
    <div className="col-12 col-lg-12">
      <div className="card shadow">
        <div className="card-body p-4">
          <div className='unitsCardHeader'>
            <legend> <b> Units Created</b></legend>
            <button className="btn btn-warning" onClick={navigateToAddUnit}> Add Unit </button>
          </div>

          {/* Scrollable table wrapper */}
          <div className="table-responsive">
            <table className="table table-bordered">
              <thead className="tableHeading">
                <tr>
                  <th rowSpan={2}>S.N</th>
                  <th rowSpan={2}>Unit No/Branch</th>
                  <th colSpan={2}>Registered Sewadal</th>
                  <th colSpan={2}>Unregistered Sewadal</th>
                  <th colSpan={2}>Bal Sewadal</th>
                  <th rowSpan={2}></th>
                </tr>
                <tr>
                  <th>Male</th>
                  <th>Female</th>
                  <th>Male</th>
                  <th>Female</th>
                  <th>Male</th>
                  <th>Female</th>
                </tr>
              </thead>
              <tbody>
                {unitData.map((row, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>                  
                    <td>{row.number + ' - ' + row.name} </td>
                    <td>{row.registeredSewadalCount.gents}</td>
                    <td>{row.registeredSewadalCount.ladies}</td>
                    <td>{row.unregisteredSewadalCount.gents}</td>
                    <td>{row.unregisteredSewadalCount.ladies}</td>
                    <td>{row.balSewadalCount.gents}</td>
                    <td>{row.balSewadalCount.ladies}</td>
                    <td>
                      <input
                        type="file"
                        accept=".xlsx, .xls"
                        onChange={handleFileChange}
                        style={{ display: 'none' }}
                        id="fileUpload"
                      />
                      <Button className="icon-button" onClick={() => addAdhikari(row.number)}>
                        <FontAwesomeIcon icon={faUserPlus} color='#3a69ff' />
                      </Button>
                      <Button className="icon-button" onClick={() => document.getElementById('fileUpload')!.click()}>
                        <FontAwesomeIcon icon={faUpload} color='#3a69ff' />
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div> {/* End table-responsive */}
        </div>
      </div>
    </div>
  </div>
</div>

  )
}

export default Units;