import React, { useState } from "react";
import FollowUpList from "./FollowUpList";
import { Button } from "react-bootstrap";
import FormsMainPage from "../SewadalForms/FormsMainPage";

const FollowUpPage: React.FC = () => {
    const [showFormsMainPage, setShowFormsMainPage] = useState(false);

    const handleButtonClick = () => {
        setShowFormsMainPage(prevState => !prevState);
    };

    return (
        <div>
            <div 
                className="followUpbtn" style={{display: "flex", justifyContent:"flex-end" }}>
                <Button
                    className="btn btn-warning"
                    onClick={handleButtonClick}
                >
                    {showFormsMainPage ? "Back to Follow Up List" : "Sewadal Forms"}
                </Button>
            </div>

            {showFormsMainPage ? <FormsMainPage /> : <FollowUpList />}
        </div>
    );
};

export default FollowUpPage;
