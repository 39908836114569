import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./SewadalCount.css";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faUserMinus, faUserPlus, faUsers } from "@fortawesome/free-solid-svg-icons";

import { useMutation } from "@tanstack/react-query";
import { GetSewadalDashboardAttendanceApi } from "src/api/AttendanceApi";
import { hideLoader } from "src/redux/loaderSlice";
import { useDispatch } from "react-redux";
// import {faUserLarge};
interface SewadalCountProps {
    totalCount: {
        registeredMale: number;
        registeredFemale: number;
        unregisteredMale: number;
        unregisteredFemale: number;
        balMale: number;
        balFemale: number;
        total: number;
    };
}
const SewadalCount: React.FC<SewadalCountProps> = ({ totalCount }) => {

    const dispatch = useDispatch();
    const [count, setCount] = useState<any[]>([]);

    const getMutation = useMutation({
        mutationFn: () => GetSewadalDashboardAttendanceApi(),
        onSuccess: (data: any[]) => {
            if(data && data.length > 0)
                setCount(data)
            dispatch(hideLoader());
        },
        onError: (error: any) => {
            console.error("Api failed:", error.response?.data || error.message);
        }
    });

    useEffect(() => {
        getMutation.mutate()
    }, []);

    return (
        <div className="container-fluid sewadlCountSec">
            {/* Total Sewadal Count Section */}
            <Tabs
                defaultActiveKey="total"
                id="justify-tab-example"
                className=""
                justify
            >
                <Tab eventKey="total" title="Total Sewadal"  >
                    <div className="row justify-content-center align-center mb-5 sewadalCountCard1">
                        <div className="col-lg-2 col-md-3 col-sm-12 mt-5 mb-5 ">
                            <div className="text">
                                {/* <FontAwesomeIcon icon={faUserGroup} /> */}
                                <div className="">
                                    <p>Total Sewadal</p>
                                    <h3>{totalCount.total}</h3>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-5 col-sm-12 mt-5 mb-5">
                            <div className=" text">
                                <div className="">
                                    <div className="d-flex justify-content-around innerCountCard">
                                        {/* <div className="sewadalCounter">
                                            <FontAwesomeIcon icon={faUserPlus} className="icon" />
                                        </div> */}
                                        <div>
                                            <p>Registered Sewadal</p>
                                            <h3>{totalCount.registeredMale + totalCount.registeredFemale}</h3>
                                        </div>
                                        {/* <div className="sewadalCounter">
                                            <FontAwesomeIcon icon={faUserMinus} className="icon" />
                                        </div> */}
                                        <div>
                                            <p>Unregistered Sewadal</p>
                                            <h3>{totalCount.unregisteredMale + totalCount.unregisteredFemale}</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-2 col-md-3 col-sm-12 mt-5 mb-5">
                        <div className=" text sewadalCounter">
                        {/* <FontAwesomeIcon icon={faUsers} className="icon" /> */}
                                <div className="">
                                    <p>Bal Sewadal</p>
                                    <h3>{totalCount.balFemale + totalCount.balMale}</h3>
                                </div>
                            </div>
                        </div>
                    </div>      </Tab>

                <Tab eventKey="total-gents-sewadal-count" title="Total Gents Sewadal Count">
                    <div className="row justify-content-center align-center mb-5 sewadalCountCard1">
                        <div className="col-lg-2 col-md-3 col-sm-12 mt-5 mb-5 ">
                            <div className=" text">
                                <div className="">
                                    <h3>{totalCount.registeredMale + totalCount.unregisteredMale}</h3>
                                    <p>Total Gents Sewadal</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-5 col-sm-12 mt-5 mb-5">
                            <div className=" text">
                                <div className="">
                                    <div className="d-flex justify-content-around ">
                                        <div>
                                            <h3>{totalCount.registeredMale}</h3>
                                            <p>Registered Sewadal</p>
                                        </div>
                                        <div>
                                            <h3>{totalCount.unregisteredMale}</h3>
                                            <p>Unregistered Sewadal</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>      </Tab>
                <Tab eventKey="total-gents-ladies-count" title="Total Ladies Sewadal Count">
                    <div className="row justify-content-center align-center mb-5 sewadalCountCard1">
                        <div className="col-lg-2 col-md-3 col-sm-12 mt-5 mb-5 ">
                            <div className=" text">
                                <div className="">
                                    <h3>{totalCount.registeredFemale + totalCount.unregisteredFemale}</h3>
                                    <p>Total Ladies Sewadal</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-5 col-sm-12 mt-5 mb-5">
                            <div className=" text">
                                <div className="">
                                    <div className="d-flex justify-content-around ">
                                        <div>
                                            <h3>{totalCount.registeredFemale}</h3>
                                            <p>Registered Sewadal</p>
                                        </div>
                                        <div>
                                            <h3>{totalCount.unregisteredFemale}</h3>
                                            <p>Unregistered Sewadal</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>      </Tab>
                <Tab eventKey="bal-sewadal" title="Bal Sewadal" >
                    <div className="row justify-content-center align-center mb-5 sewadalCountCard1">
                        <div className="col-lg-2 col-md-3 col-sm-12 mt-5 mb-5 ">
                            <div className=" text">
                                <div className="">
                                    <h3>{totalCount.balMale + totalCount.balFemale}</h3>
                                    <p>Total Bal Sewadal</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-2 col-md-3 col-sm-12 mt-5 mb-5">
                            <div className=" text">
                                <div className="">
                                    <h3>{totalCount.balMale}</h3>
                                    <p>Gents Bal Sewadal</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-2 col-md-3 col-sm-12 mt-5 mb-5">
                            <div className=" text">
                                <div className="">
                                    <h3>{totalCount.balFemale}</h3>
                                    <p>Ladies Bal Sewadal</p>
                                </div>
                            </div>
                        </div>

                    </div>      </Tab>
            </Tabs>





            {/* Today's Satsang Sewadal Attendance */}
            <div className="col-12">
                <h5 className="text mb-4">Today's Satsang Sewadal Attendance</h5>
            </div>
            <div className="row mb-5 sewadalCountCard2">


                <div className="col-lg-3 col-sm-12 mb-4 mt-5 mb-5 ">
                    <div className=" text">
                        <div className="">
                            <h3>{count[0]?.sewadalCount}</h3>
                            <p>Total Sewadal</p>
                        </div>
                    </div>
                </div>

                <div className="col-lg-3 col-md-5 col-sm-12 mb-4 mt-5 mb-5">
                    <div className=" text">
                        <div className="">
                            <h3>{count[0]?.presentCount}</h3>
                            <p>Present Sewadal</p>
                        </div>
                    </div>
                </div>

                <div className="col-lg-3 col-md-5 col-sm-12 mb-4 mt-5 mb-5">
                    <div className=" text">
                        <div className="">
                            <h3>{count[0]?.sewadalCount - count[0]?.presentCount}</h3>
                            <p>Absent Sewadal</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SewadalCount;
