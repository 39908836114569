import React, {
	useCallback,
	useEffect,
	useState,
} from 'react';

import {
	Button,
	Form,
	Modal,
	Table,
} from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
	DownloadSewadalFormsById,
	GetAllSubmittedSewadalForms,
	UpdateSubmittedSewadalFormsDetailsById,
} from 'src/api/sewadalFormApi';
import {
	FormStatus,
	USER_TYPE,
} from 'src/Common/AppEnum';
import {
	GetSewadalFormsListingResponse,
} from 'src/Common/Models/SewadalForms/SewadalFormsModel';
import Paginator from 'src/Common/Paginator';
import { RootState } from 'src/redux/store';

const SdFormsListing = () => {
	const { user } = useSelector((state: RootState) => state.auth);
	const [data, setData] = useState<GetSewadalFormsListingResponse[]>([]);
	const [selectedItemsPerPage, setSelectedItemsPerPage] = useState<number>(10);
    const [currentPage, setCurrentPage] = useState<number>(1);

	const handleItemsPerPageChange = (items: number) => {
        setSelectedItemsPerPage(items);
        setCurrentPage(1); // Reset to page 1 when changing items per page
    };

    const paginatedData = (data).slice(
        (currentPage - 1) * selectedItemsPerPage,
        currentPage * selectedItemsPerPage
    );

    const handlePageChange = (page: number) => {
        setCurrentPage(page);
    };


	const navigate = useNavigate();
	useEffect(() => {
		console.log("SD");
		const getData = async () => {
			const response = await GetAllSubmittedSewadalForms();
			setData(response);
		};
		getData();
	}, []);

	const downloadForm = useCallback(async (id: number) => {
		try {
			await DownloadSewadalFormsById(id);
		} catch (error) {
			console.error('Failed to download form:', error);
		}
	}, []);

	const [showModal, setShowModal] = useState(false);
	const [selectedForm, setSelectedForm] = useState<any>({});
	const [remarks, setRemark] = useState('');
	const [status, setStatus] = useState('');
	const [id, setId] = useState(0);

	const handleRemarkClick = (form: any) => {
		setSelectedForm(form);
		setRemark(form.remarks || '');
		setStatus(form.status || '');
		setShowModal(true);
		setId(form.id || 0);
	};

	const handleSaveChanges = async () => {
		// Here we would also send data to the server or update state
		if (selectedForm) {
			selectedForm.remarks = remarks;
			selectedForm.status = status;
		}
		await UpdateSubmittedSewadalFormsDetailsById(id, selectedForm)
		setShowModal(false);
	};

	return (
		<div className="container-fluid mt-5">
		<div className="row justify-content-center mt-3">
			<div className="col-12">
				<div className="card shadow-sm">
					<div className="card-body">
						<h4 className="text-center mb-4">
							<b>All Submitted Forms</b>
						</h4>
	
						<div className="table-responsive"> {/* Add this wrapper div */}
							<Table striped bordered hover>
								<thead>
									<tr>
										<th>#</th>
										<th>Form Name</th>
										<th>Unit Number</th>
										<th>Status</th>
										<th>Submitted At</th>
										<th>Last Updated At</th>
										<th>Remarks</th>
										<th>Actions</th>
									</tr>
								</thead>
								<tbody>
									{data.length > 0 ? (
										paginatedData.map((form, index) => (
											<tr key={index}>
												<td>
													{(currentPage - 1) * selectedItemsPerPage + index + 1}
												</td>
												{/* <td>{index + 1}</td> */}
												<td>{form.unitNumber}</td>
												<td>{form.type}</td>
												<td>
													{Object.keys(FormStatus).find(
														(key) =>
															FormStatus[key as keyof typeof FormStatus] ===
															form.status
													)}
												</td>
												<td>{new Date(form.createdAt).toLocaleDateString()}</td>
												<td>{new Date(form.updatedAt).toLocaleDateString()}</td>
												<td>{form.remarks || 'NA'}</td>
												<td className="d-flex justify-content-around">
													<button
														type="button"
														className="btn btn-sm btn-outline-primary mx-1"
														onClick={() => {
															navigate(
																`/sewadalforms/${form.type.toLowerCase()}`,
																{
																	state: { createFrom: false, formId: form.id },
																}
															);
														}}>
														Preview
													</button>
													<button
														type="button"
														className="btn btn-sm btn-outline-primary mx-1"
														onClick={() => downloadForm(form.id)}>
														Download
													</button>
													{user?.userType === USER_TYPE.KSHETRIYA_SANCHALAK && (
														<button
															type="button"
															className="btn btn-sm btn-outline-primary mx-1"
															onClick={() => handleRemarkClick(form)}>
															Remarks
														</button>
													)}
												</td>
											</tr>
										))
									) : (
										<tr>
											<td
												colSpan={
													user?.userType === USER_TYPE.KSHETRIYA_SANCHALAK
														? 6
														: 7
												}
												className="text-center">
												No data found
											</td>
										</tr>
									)}
								</tbody>
							</Table>
						</div>
	
						{data.length > 0 && (
							<Paginator
								totalItems={data.length}
								itemsPerPage={selectedItemsPerPage}
								currentPage={currentPage}
								onPageChange={handlePageChange}
								onItemsPerPageChange={handleItemsPerPageChange}
							/>
						)}
					</div>
				</div>
			</div>
		</div>
	
		<Modal show={showModal} onHide={() => setShowModal(false)}>
			<Modal.Header closeButton>
				<Modal.Title>Add Remark and Status</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form>
					<Form.Group controlId="remark">
						<Form.Label>Remark</Form.Label>
						<Form.Control
							type="text"
							placeholder="Enter remark"
							value={remarks}
							onChange={(e) => setRemark(e.target.value)}
						/>
					</Form.Group>
					<Form.Group controlId="status" className="mt-3">
						<Form.Label>Status</Form.Label>
						<Form.Control
							as="select"
							value={status}
							onChange={(e) => setStatus(e.target.value)}>
							<option value="">Select Status</option>
							{Object.keys(FormStatus).map((key) => (
								<option key={key} value={FormStatus[key as keyof typeof FormStatus]}>
									{key}
								</option>
							))}
						</Form.Control>
					</Form.Group>
				</Form>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="secondary" onClick={() => setShowModal(false)}>
					Close
				</Button>
				<Button variant="primary" onClick={handleSaveChanges}>
					Save Changes
				</Button>
			</Modal.Footer>
		</Modal>
	</div>
	);
};

export default SdFormsListing;
