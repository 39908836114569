//Dashboard.tsx
import React, { useEffect, useState } from "react";
import "./Dashboard.css";
import { birthdayLogoImage } from "./Images/BirthdayLogoImage";
import { useTranslation } from 'react-i18next';
import { USER_TYPE } from "src/Common/AppEnum";
import { adminDashboardCards, sewadalDashboardCards, adhikariDashboardCards } from "src/Common/AppConstant";
import { DashboardCardModel } from "src/Common/Models/Dashboard/DashboardCardModel";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { UnitDataModel, UnitSewadalTotalCount } from "src/Common/Models/Unit/UnitDataModel";
import { GetUnitApi, GetUnitByIdApi } from "src/api/unitApi";
import { useDispatch, useSelector } from "react-redux";
import { hideLoader } from "src/redux/loaderSlice";
import FollowUp from "./FollowUp";
import AttendancePieChart from "./AttendancePieChart";
import SewadalCount from "./SewadalCount";
import AdhikariAttendanceSec from "./AdhikariAttendanceSec";
import { RootState } from "../../redux/store";

interface DashboardProps {
  userType: USER_TYPE | null | undefined;
}
const Dashboard = ({ userType }: DashboardProps) => {
  const { user } = useSelector((state: RootState) => state.auth);
  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);
  const { t } = useTranslation();
  const [cards, setCards] = useState<DashboardCardModel[]>([]);
  const [unitData, setUnitData] = useState<UnitDataModel[]>([]);
  const [totalCount, setTotalCount] = useState<UnitSewadalTotalCount>();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isDashboardClass, setDashboardClass] = useState("");

  const togglePopup = () => setIsPopupOpen(!isPopupOpen);

  const getMutation = useMutation<UnitDataModel[]>({
    mutationFn: GetUnitApi,
    onSuccess: (data: UnitDataModel[]) => {
      setUnitData(data);
      dispatch(hideLoader());
      calculateTotal(data);
    },
    onError: (error: any) => {
      console.error("Api failed:", error.response?.data || error.message);
    }
  });

  const getUnitMutation = useMutation<UnitDataModel, Error, number>({
    mutationFn: (unitNumber: number) => GetUnitByIdApi(unitNumber),
    onSuccess: (data: UnitDataModel) => {
      console.log("getUnitMutation:", data);
      dispatch(hideLoader());
      calculateTotal([data]);
    },
    onError: (error: any) => {
      console.error("Api failed:", error.response?.data || error.message);
    }
  });

  useEffect(() => {
    if (userType === USER_TYPE.ATTENDANCE) {
      setCards(sewadalDashboardCards);
    }
    else if (userType === USER_TYPE.ADHIKARI) {
      setCards(adhikariDashboardCards)
      setDashboardClass(userType);
      getUnitMutation.mutate(user?.unitNo as number);
    }
    else {
      setCards(adminDashboardCards);
      // if (userType !== USER_TYPE.KSHETRIYA_SANCHALAK) {
      getMutation.mutate();
      // }
    }
  }, [userType])
  // Sample birthday data
  const birthdayData = [
    {
      name: "John Doe",
      date: "12/09/2024",
      profilePic: "https://via.placeholder.com/50", // Placeholder image
    },
    {
      name: "Jane Smith",
      date: "19/09/2024",
      profilePic: "https://via.placeholder.com/50", // Placeholder image
    },
  ];
  // Calculate the totals
  const calculateTotal = (data: UnitDataModel[]) => {
    // Use reduce to accumulate totals
    const totalCount = data.reduce((acc, element) => {
      return {
        balMale: acc.balMale + (element.balSewadalCount?.gents ?? 0),
        balFemale: acc.balFemale + (element.balSewadalCount?.ladies ?? 0),
        registeredMale: acc.registeredMale + (element.registeredSewadalCount?.gents ?? 0),
        registeredFemale: acc.registeredFemale + (element.registeredSewadalCount?.ladies ?? 0),
        unregisteredMale: acc.unregisteredMale + (element.unregisteredSewadalCount?.gents ?? 0),
        unregisteredFemale: acc.unregisteredFemale + (element.unregisteredSewadalCount?.ladies ?? 0),
        total: 0,
      };
    }, {
      balMale: 0,
      balFemale: 0,
      registeredMale: 0,
      registeredFemale: 0,
      unregisteredMale: 0,
      unregisteredFemale: 0,
      total: 0,
    });

    totalCount.total = totalCount.balMale
      + totalCount.balFemale
      + totalCount.registeredMale
      + totalCount.registeredFemale
      + totalCount.unregisteredMale
      + totalCount.unregisteredFemale;

    setTotalCount(totalCount);
  };

  const handleNavigation = (route: string) => {
    navigate(route);
  }

  const dashboardName = (userType === USER_TYPE.KSHETRIYA_SANCHALAK || userType === USER_TYPE.ADMIN) ? ('Kshetra-' + user?.kshetraId) : ('Unit-' + user?.unitNo);

  return (
    <>
      <p className="dashboardText">
          <b>{t("dashboard-label", { dashboardName })}</b>
      </p>


      <div className="dashboard-container">
        <div className={`${isDashboardClass}`}>
          {/* Cards Section */}
          <div className="row">
            <div className="col-8">
              <div className={`${isDashboardClass} first-column`}>
                {cards.map((card: DashboardCardModel) => (
                  <div
                    className={`${card.className} card-clickable`}
                    key={card.key}
                    onClick={() => handleNavigation(card.route)}
                  >
                    <p>{t(card.label)}</p>
                    <img
                      src={card.image}
                      alt="Small Icon"
                      className="small-image"
                    />
                  </div>
                ))}
              </div>
            </div>


            {(userType === USER_TYPE.KSHETRIYA_SANCHALAK || userType === USER_TYPE.ADMIN) && (
              <AdhikariAttendanceSec />
            )}
          </div>


          {/* Follow-up Section */}
          {/* {userType === USER_TYPE.ADHIKARI && (
            <FollowUp birthdayData={birthdayData} t={t} />
          )} */}
        </div>

      </div>

      {userType === USER_TYPE.ADHIKARI && (
        <div className="sewadalCountSec">
          {totalCount && <SewadalCount totalCount={totalCount as UnitSewadalTotalCount} />}
        </div>
      )}


      {userType === USER_TYPE.ADHIKARI && <AttendancePieChart />}
      {/* Responsive Table using Bootstrap */}
      {userType !== USER_TYPE.ATTENDANCE && userType !== USER_TYPE.ADHIKARI && (
        <div className="tablecontainer mt-4">
          <div>
            <p style={{ display: "flex", justifyContent: "space-evenly" }}>
              <b> {t("kshetra-strength-label")} Mumbai-III</b>
            </p>
          </div>
          <div className="table-responsive">
            <table className="table table-bordered">
              <thead className="tableHeading">
                <tr>
                  <th rowSpan={2}>{t("table-column.SN-label")}</th>
                  <th rowSpan={2}>{t("table-column.unit-no")}</th>
                  <th colSpan={2}>{t("table-column.registered-sewadal")}</th>
                  <th colSpan={2}>{t("table-column.unregistered-sewadal")}</th>
                  <th colSpan={2}>{t("table-column.bal-sewadal")}</th>
                  <th rowSpan={2}>{t("table-column.total")}</th>
                </tr>
                <tr>
                  <th>{t("gender-male-label")}</th>
                  <th>{t("gender-female-label")}</th>
                  <th>{t("gender-male-label")}</th>
                  <th>{t("gender-female-label")}</th>
                  <th>{t("gender-male-label")}</th>
                  <th>{t("gender-female-label")}</th>
                </tr>
              </thead>
              <tbody>
                {unitData.map((row, i) => (
                  <tr key={i}>
                    <td>{i + 1}</td>
                    <td>{row.number + '-' + row.name}</td>
                    <td>{row.registeredSewadalCount.gents}</td>
                    <td>{row.registeredSewadalCount.ladies}</td>
                    <td>{row.unregisteredSewadalCount.gents}</td>
                    <td>{row.unregisteredSewadalCount.ladies}</td>
                    <td>{row.balSewadalCount.gents}</td>
                    <td>{row.balSewadalCount.ladies}</td>
                    <td>
                      {row.registeredSewadalCount.gents +
                        row.registeredSewadalCount.ladies +
                        row.unregisteredSewadalCount.gents +
                        row.unregisteredSewadalCount.ladies +
                        row.balSewadalCount.gents +
                        row.balSewadalCount.ladies}
                    </td>
                  </tr>
                ))}
                {/* Totals Row */}
                <tr className="table-info">
                  <td colSpan={2}>
                    <b>{t("table-column.total")}</b>
                  </td>
                  <td>{totalCount?.registeredMale}</td>
                  <td>{totalCount?.registeredFemale}</td>
                  <td>{totalCount?.unregisteredMale}</td>
                  <td>{totalCount?.unregisteredFemale}</td>
                  <td>{totalCount?.balMale}</td>
                  <td>{totalCount?.balFemale}</td>
                  <td>
                    <b>{totalCount?.total}</b>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      )}

      {/* Floating Birthday Quick Access Logo */}
      {userType !== USER_TYPE.ATTENDANCE && (
        <div className="floating-logo" onClick={togglePopup}>
          <img src={birthdayLogoImage} alt="Quick Access Birthday Logo" />
        </div>
      )}

      {/* Popup */}
      {isPopupOpen && (
        <div className="birthday-popup">
          <p>
            <b>{t("monthly-birthday-list-label")}</b>
          </p>
          {birthdayData.map((person, index) => (
            <div key={index} className="birthday-entry">
              <img
                src={person.profilePic}
                alt={`${person.name}'s profile`}
                className="popup-profile-pic"
              />
              <div className="popup-info">
                <p className="popup-name">{person.name}</p>
                <p className="popup-date">{person.date}</p>
              </div>
              <button className="send-wishes-btn">
                {t("send-wished-label")}
              </button>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default Dashboard;
