import React, { useEffect, useState } from "react";
import { PRESENTY_STATUS } from "src/Common/AppEnum";
import { AttendanceDataModel } from "src/Common/Models/Attendance/AttendanceDataModel";

interface MarkAttendancePopupProps {
  selectedSewadal: AttendanceDataModel;
  type: PRESENTY_STATUS;
  handleSave: (sewadal: AttendanceDataModel, status: PRESENTY_STATUS, time: string) => void;
  handleDelete: (sewadal: AttendanceDataModel) => void;
  isDelete: boolean;
  handleClose: () => void;
}

const MarkAttendancePopup = ({ selectedSewadal, type, handleSave, handleDelete, isDelete, handleClose }: MarkAttendancePopupProps) => {
 
    const getCurrentTime = () => {
        const current = new Date();
        const hours = String(current.getHours()).padStart(2, "0");
        const minutes = String(current.getMinutes()).padStart(2, "0");
        return `${hours}:${minutes}`;
      };

    const [time, setTime] = useState<string>((selectedSewadal && selectedSewadal.time) || getCurrentTime());

  // Function to get the current time in HH:mm format
  

  // Set the initial time when the component mounts
 

  return (
    <div className={"modal fade show"} style={{ display: 'block' }}>
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="attendanceModalLabel">{isDelete ? "Delete Attendance" : "Mark Attendance"}</h5>
            <button type="button" className="btn-close" onClick={handleClose} aria-label="Close"></button>
          </div>
          <div className="modal-body">
            {isDelete ? (
              <p>Are you sure you want to delete the attendance entry for {selectedSewadal.name}?</p>
            ) : (
              <div>
                <label>Attendance Time</label>
                <input type="time" value={time} onChange={(e) => setTime(e.target.value)} />
              </div>
            )}
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" onClick={handleClose}>Cancel</button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => { handleSave(selectedSewadal, (isDelete ? PRESENTY_STATUS.UNSET : type), ((isDelete ? "" : time)));
              }}
            >
              {isDelete ? "Delete" : "Save"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MarkAttendancePopup;
