export const formatDate = (date: Date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
};

export const generateRandomPassword = (length: number): string => {
    if (length < 8) {
      throw new Error("Password length must be at least 8 characters.");
    }
  
    const upperCaseChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const lowerCaseChars = "abcdefghijklmnopqrstuvwxyz";
    const numberChars = "0123456789";
    const specialChars = "@$!%*?&"; // Using a limited set for simplicity
  
    // Ensure the password contains at least one of each required character type
    const getRandomChar = (str: string) => str[Math.floor(Math.random() * str.length)];
    
    let password = [
      getRandomChar(upperCaseChars),    // At least one uppercase
      getRandomChar(lowerCaseChars),    // At least one lowercase
      getRandomChar(numberChars),       // At least one number
      getRandomChar(specialChars)       // At least one special character
    ];
  
    // Fill the remaining characters randomly
    const allChars = upperCaseChars + lowerCaseChars + numberChars + specialChars;
    for (let i = 4; i < length; i++) {
      password.push(getRandomChar(allChars));
    }
  
    // Shuffle the password array to randomize character positions
    return password.sort(() => Math.random() - 0.5).join('');
  }