import './index.css';

import React, {
  useEffect,
  useState,
} from 'react';

import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  GetAdhikariFilterApi,
  GetFilterApi,
} from 'src/api/filterApi';
import {
  FILTER_INPUT_TYPE,
  FILTER_LISTING_TYPE,
  REPORT_TYPE,
  USER_TYPE,
} from 'src/Common/AppEnum';
import { useReportsFilterFields } from 'src/Common/FilterConstant';
import {
  FilterField,
  KeyValueObject,
} from 'src/Common/Models/Filter/FilterModel';
import { RootState } from 'src/redux/store';
import { formatDate } from 'src/utils/utils';

import { useMutation } from '@tanstack/react-query';

interface FilterProps {
    onlySearch: boolean;
    filterType: FILTER_LISTING_TYPE,
    onApply?: (appliedFilters: { [key: string]: string | number }, reportType: REPORT_TYPE) => void;
    onReset?: () => void;
    searchPlaceholder: string;
    onSearch: (searchTerm: string) => void;
    initialValues?: { [key: string]: string | number };
    canExport?: boolean;
    handleExport?: () => void;
}

const ReportsFilter: React.FC<FilterProps> = ({ onlySearch, filterType, onApply, onReset, searchPlaceholder, onSearch, initialValues, canExport = false, handleExport }) => {
    const { t } = useTranslation();
    // Set initial filter data
    const { user } = useSelector((state: RootState) => state.auth);
    const [filterFields, setFilterFields] = useState<FilterField[]>([]);
    const [filterData, setFilterData] = useState<{ [key: string]: string | number }>();
    const [reportType, setReportType] = useState<REPORT_TYPE>(
                REPORT_TYPE.NONE
    );
    const reportsFilterFields = useReportsFilterFields(user!.userType!,reportType);
    const [allUnits, setAllUnits] = useState();
    const getFilterDataMutation = useMutation<KeyValueObject[]>({
        mutationFn: () => GetFilterApi({ filterType: FILTER_LISTING_TYPE.REPORTS }),
        onSuccess: (data: any) => {
            setAllUnits(data.units);
        },
        onError: (error: any) => {
            console.error("Api failed:", error.response?.data || error.message);
        }
    });

    const getAdhikariFilterDataMutation = useMutation<KeyValueObject[], Error, { unitNo: number, sewadalType: string }>({
        mutationFn: ({ unitNo, sewadalType }) => GetAdhikariFilterApi(unitNo, sewadalType),
        onSuccess: (data: any) => {
            let adhikariFilter = filterFields.findIndex(field => field.key === FILTER_INPUT_TYPE.SEWADAL);
            if (adhikariFilter > -1) {
                filterFields[adhikariFilter].options = data;
            }

        },
        onError: (error: any) => {
            console.error("Api failed:", error.response?.data || error.message);
        }
    });

    useEffect(() => {
        if(user?.userType === USER_TYPE.ADMIN || user?.userType === USER_TYPE.KSHETRIYA_SANCHALAK)
            getFilterDataMutation.mutate();
    }, []);

    const exportData = () => {
        if(handleExport)
            handleExport();
    }

    const handleReportTypeChange = (e: any) => {
        let type: REPORT_TYPE = e.target.value as REPORT_TYPE
        setReportType(type);
        let newFilterFields = reportsFilterFields.filter(
            (field) => {
                return field.allowedReportType?.includes(Number(type)) ?? false;
            }
        );

        const currentDate = new Date();
        const currentMonth = currentDate.toISOString().slice(0, 7); // YYYY-MM format for month input
        const today = currentDate.toISOString().slice(0, 10); // YYYY-MM-DD format for date input

        const threeMonthsBack = new Date(currentDate);
        threeMonthsBack.setMonth(currentDate.getMonth() - 2); // Subtract 3 months
        const startMonth = threeMonthsBack.toISOString().slice(0, 7); // Format as YYYY-MM for month input

        // Create a new filter data object to hold the default values
        const newFilterData: Record<string, string> = {};

        newFilterFields.forEach(field => {
            if (field.type === 'date') {
                if (field.dateType === 'month') {
                    if (field.key === FILTER_INPUT_TYPE.START_MONTH) {
                        newFilterData[field.key] = startMonth;
                    } else {
                        newFilterData[field.key] = currentMonth; // Set current month for month type
                    }
                } else {
                    newFilterData[field.key] = today; // Set today's date for date type
                }
            }
        });

        // Update filter data state with the new default values
        setFilterData(newFilterData);

        // You can also update the filter fields state if needed
        setFilterFields(newFilterFields);

        let unitFilter = newFilterFields.findIndex(field => field.key === FILTER_INPUT_TYPE.UNIT);
        if (unitFilter > -1) {
            newFilterFields[unitFilter].options = allUnits;
        }
        setFilterFields(newFilterFields);
    }

    const getMonthDifference = (startMonth: string, endMonth: string): number => {
        const [startYear, startMonthNum] = startMonth.split('-').map(Number);
        const [endYear, endMonthNum] = endMonth.split('-').map(Number);

        return (endYear - startYear) * 12 + (endMonthNum - startMonthNum);
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>, key: FILTER_INPUT_TYPE, fieldType: string, dependentIndex: number) => {
        const value = e.target.value;
        const updatedFilterData: { [key: string]: string | number } = { ...filterData, [key]: value };
        setFilterData({ ...filterData, [key]: value });

        let unitNo = (user?.userType !== USER_TYPE.ADMIN && user?.userType !== USER_TYPE.KSHETRIYA_SANCHALAK) ? user?.unitNo : 0;
        if(unitNo === 0) {
            unitNo = key == FILTER_INPUT_TYPE.UNIT ? Number(value) : Number(filterData?.[FILTER_INPUT_TYPE.UNIT]);
        }

        if (unitNo && unitNo > 0 && (key == FILTER_INPUT_TYPE.UNIT || key == FILTER_INPUT_TYPE.SEWADAL_DESIGNATION_TYPE)) {
            getAdhikariFilterDataMutation.mutate({
                unitNo: unitNo,
                sewadalType: key == FILTER_INPUT_TYPE.SEWADAL_DESIGNATION_TYPE ? value.toString() : (filterData?.[FILTER_INPUT_TYPE.SEWADAL_DESIGNATION_TYPE]?.toString() || "")
            })
        }
        if (key === FILTER_INPUT_TYPE.START_MONTH || key === FILTER_INPUT_TYPE.END_MONTH) {
            const startMonth = updatedFilterData[FILTER_INPUT_TYPE.START_MONTH];
            const endMonth = updatedFilterData[FILTER_INPUT_TYPE.END_MONTH];

            if (startMonth && endMonth) {
                const monthDifference = getMonthDifference(startMonth as string, endMonth as string);

                if (monthDifference > 3) {
                    alert(t('error-date-range-exceed'));
                    return;
                }
            }
        }

        setFilterData({ ...filterData, [key]: value });
    };

    const getMinDate = (): string => {
        const today = new Date();
        if (filterType === FILTER_LISTING_TYPE.ATTENDANCE) {
            const sevenDaysAgo = new Date();
            sevenDaysAgo.setDate(today.getDate() - 7);
            return formatDate(sevenDaysAgo);
        }
        else {
            const oneYearBack = new Date(today);
            oneYearBack.setFullYear(today.getFullYear() - 1);
            return formatDate(oneYearBack);
        }
    }

    const getMaxDate = () => {
        const today = new Date();
        return formatDate(today);
    }

    const getMinMonthDate = (): string => {
        const today = new Date();
        const oneYearBack = new Date(today);
        oneYearBack.setFullYear(today.getFullYear() - 1);
        return formatInitialDate(oneYearBack, "YYYY-MM");
    };

    const getMaxMonthDate = (): string => {
        const today = new Date();
        return formatInitialDate(today, "YYYY-MM");
    };

    const formatInitialDate = (date: Date, format: "YYYY-MM" | "YYYY-MM-DD" = "YYYY-MM-DD"): string => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');

        if (format === "YYYY-MM") {
            return `${year}-${month}`; // Format for months
        } else {
            return `${year}-${month}-${day}`; // Format for full dates
        }
    };

    const resetFilter = () => {
        setFilterData(undefined);
        onReset!();
    }
    const isFilterComplete = (): boolean => {
        return filterFields.filter(field => field.required).every(field => (filterData && filterData[field.key] && filterData![field.key] !== undefined && filterData![field.key] !== ""));
    };

    const applyFilter = () => {
        onApply!(filterData!, reportType);
    }

    return (
			<>
				<div>
					<div className="row">
						<div className="text-end mb-3">
							<Button
								onClick={exportData}
								disabled={!handleExport}>
								{' '}
								Export
							</Button>
						</div>
					</div>

					<div
						id="collapseOne"
						className="accordion-collapse"
						aria-labelledby="headingOne"
						data-bs-parent="#accordionExample">
						<div className="accordion-body">
							<div className="row">
								<div className="col-md-4">
									<div className="input-group mb-3">
										<select
											className="form-select"
											value={reportType}
											onChange={(e) => handleReportTypeChange(e)}>
											<option value={REPORT_TYPE.NONE}>
												Select Report Type
											</option>
											{user!.userType !== USER_TYPE.KSHETRIYA_SANCHALAK && (
												<option value={REPORT_TYPE.ATTENDANCE_DETAILS}>
													Individual Sewadal Attendance
												</option>
											)}
											{user!.userType === USER_TYPE.KSHETRIYA_SANCHALAK && (
												<option value={REPORT_TYPE.ADHIKARI_INDIVIDUAL}>
													Individual Adhikari Attendance
												</option>
											)}
											<option value={REPORT_TYPE.UNITWISE}>
												{user!.userType !== USER_TYPE.KSHETRIYA_SANCHALAK
													? 'Sewadal Attendance on Vardi'
													: 'Unit Wise Attendance'}
											</option>
											<option value={REPORT_TYPE.BELOW_60}>
												Below 60% Attendance
											</option>
											<option value={REPORT_TYPE.TRADE}>Trade</option>
											<option value={REPORT_TYPE.BLOOD_GROUP}>
												Blood Group
											</option>
										</select>
									</div>
								</div>
								{/* <div className="col-md-4">
									<div className="input-group mb-3">
										<input
											type="text"
											className="form-control"
											placeholder={searchPlaceholder}
											aria-label="Search"
											value={searchTerm}
											onChange={handleSearchInputChange}
											aria-describedby="basic-addon1"
										/>
									</div>
								</div> */}
							</div>
							{reportType !== REPORT_TYPE.NONE && (
								<div className="row">
									{filterFields.map((field, index) => (
										<div
											key={index}
											className="col-md-4">
											<label>{field.label}</label>
											<div className="input-group mb-3">
												{field.type === 'select' && field.options ? (
													<select
														className="form-select"
														value={(filterData && filterData[field.key]) || ''}
														onChange={(e) =>
															handleInputChange(
																e,
																field.key,
																'select',
																field.dependentIndex
															)
														}>
														<option value="">{field.placeholder}</option>
														{field.options.map((option) => (
															<option
																key={option.key}
																value={option.key}>
																{option.value}
															</option>
														))}
													</select>
												) : field.type === 'date' ? (
													<input
														type={field.dateType}
														className="form-control"
														value={
															(filterData && filterData[field.key]) ||
															formatDate(new Date())
														} // Set today's date as default
														onChange={(e) =>
															handleInputChange(
																e,
																field.key,
																'date',
																field.dependentIndex
															)
														}
														max={
															field.dateType === 'date'
																? getMaxDate()
																: getMaxMonthDate()
														} // Today's date as the max date
														min={
															field.dateType === 'date'
																? getMinDate()
																: getMinMonthDate()
														} // 7 days ago as the min date
													/>
												) : (
													field.type === 'text' && (
														<input
															type={field.type}
															className="form-control"
															placeholder={field.placeholder}
															value={
																(filterData && filterData![field.key]) || ''
															}
															onChange={(e) =>
																handleInputChange(
																	e,
																	field.key,
																	'text',
																	field.dependentIndex
																)
															}
														/>
													)
												)}
											</div>
										</div>
									))}
								</div>
							)}

							{reportType !== REPORT_TYPE.NONE && (
								<div className="row j-end me-2">
									<div className="col-md-1">
										<button
											type="button"
											className="btn btn-secondary action-btn"
											onClick={resetFilter}>
											{t('reset-btn-label')}
										</button>
									</div>
									<div className="col-md-1 ms-2">
										<button
											type="button"
											className="btn btn-primary action-btn"
											disabled={!isFilterComplete()}
											onClick={applyFilter}>
											{t('apply-btn-label')}
										</button>
									</div>
								</div>
							)}
						</div>
					</div>
				</div>
			</>
		);
};

export default ReportsFilter;
