import React, { useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./AddSewadalDetails.css"
import { useLocation, useNavigate } from "react-router-dom";
import SewadalForm from "./SewadalForm";
import { AddSewadalFormModel } from "src/Common/Models/Sewadal/SewadalModel";
import { useMutation } from "@tanstack/react-query";
import { AddSewadalApi } from "src/api/sewadalApi";
import { RootState } from "src/redux/store";
import { useSelector } from "react-redux";
import { SEWADAL_TYPE } from "src/Common/AppEnum";
import UploadSewadalBulk from "../UploadSewadal/UploadSewadalBulk";

const AddSewadalDetails: React.FC = () => {

  const navigate = useNavigate();
  const { user } = useSelector((state: RootState) => state.auth);
  const [formData, setFormData] = useState<AddSewadalFormModel>(new AddSewadalFormModel(SEWADAL_TYPE.SEWADAL));
  const [addBulk, setAddBulk] = useState<boolean>(true);

  const location = useLocation();
  const unitNo = location.state?.unitNo || null;

  const postMutation = useMutation({
    mutationFn: AddSewadalApi,
    onSuccess: () => {
      if (unitNo && unitNo > 0) {
        navigate('/units');
      } else {
        navigate('/sewadal');
      }
    },
    onError: (error: any) => {
      console.error('Api failed:', error.response?.data || error.message);
    },
  });

  const handleFormSubmit = (e: React.FormEvent, formData: AddSewadalFormModel) => {
    e.preventDefault();
    console.log("Form Data Submitted: ", formData);
    formData.unitNumber = unitNo || user?.unitNo || 0;
    postMutation.mutate(formData);

  };

  const handleBackClick = () => {
    // navigate(-1);
    setAddBulk(true);
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { id, value } = e.target;
    setFormData({
      ...formData,
      [id]: value, // Dynamically update form fields
    });
  };

  return (
    <div className="container-fluid mt-5">
      <div className="row justify-content-center mt-3">
        <div className="col-12 col-lg-12">
          <div className="card shadow">
            <div className="card-body p-4">
              <legend> <b>{unitNo && unitNo > 0 ? 'Add Adhikari' : 'Add Sewadal Details'}</b></legend>
              {addBulk ? (
                <UploadSewadalBulk addManual={() => setAddBulk(false)} />
              ) : (
                <SewadalForm handleFormSubmit={handleFormSubmit} handleBackClick={handleBackClick} fromUnit={unitNo && unitNo > 0} handleChange={handleChange} formData={formData} unitNo={unitNo || 0} />
              )}

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddSewadalDetails;
