
import React from "react"
import "./AdhikariAttendanceSec.css";
const AdhikariAttendanceSec:React.FC=()=>{
    return(
<div className="col-12 col-md-6 col-lg-4 mb-4">
<div className="card shadow-sm h-100 p-2 border-0 attendanceCard ">
  <div className="d-flex justify-content-between align-items-center mb-2 adhikariAttendanceSingleCard">
    <div className="adhikariAttendanceText" >Adhikari Attendance Below 60% in vardi</div>
    <div className="attendanceCount d-flex justify-content-center align-items-center text-white rounded-circle">
      4
    </div>
  </div>
  <div className="d-flex justify-content-between align-items-center adhikariAttendanceSingleCard">
    <div className="adhikariAttendanceText">Overall Adhikari Attendance Below 60%</div>
    <div className="attendanceCount d-flex justify-content-center align-items-center text-white rounded-circle">
      17
    </div>
  </div>
</div>
</div>
    )
}

export default AdhikariAttendanceSec;
