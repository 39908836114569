import React from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from './redux/store';
import { USER_TYPE } from './Common/AppEnum';

interface ProtectedRouteProps {
  children: React.ReactNode;
  userType: USER_TYPE | null | undefined;
  requiredPermission: number | null; // null indicates no specific permission required (e.g., for dashboard)
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children, requiredPermission, userType }) => {
  const { isAuthenticated, permissions } = useSelector((state: RootState) => state.auth);

  // Check if user is authenticated
  if (!isAuthenticated) {
    return <Navigate to="/" />;
  }

  // If no specific permission is required (e.g., for dashboard), just return the component
  if (requiredPermission === null) {
    return <>{children}</>;
  }

  // Check if the user has the required permission
  if ((permissions & requiredPermission) !== requiredPermission) {
    if (userType === USER_TYPE.ATTENDANCE) {
      return <Navigate to="/attendance" />;
    }
    else {
      return <Navigate to="/" />;
    }
  }

  return <>{children}</>;
};

export default ProtectedRoute;
