import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../FormsMainPage.css";

const Sd6RetirementForm: React.FC = () => {
  // State for form data
  const [formData, setFormData] = useState({
    unitNumber: "12345",
    branch: "Branch A",
    khetra: "Kshetra Y",
    zone: "Zone 1",
    personalNumber: "",
    post: "",
    dob: "",
    age: "",
    fatherHusbandName: "",
    reasonForRetirement: "",
  });

  const [nameSelected, setNameSelected] = useState(false);

  // Sample members data
  const membersData = [
    {
      name: "John Doe",
      personalNumber: "PN12345",
      post: "Adhikari",
      dob: "1980-01-15",
      age: "44",
      fatherHusbandName: "David Doe",
    },
    {
      name: "Jane Smith",
      personalNumber: "PN54321",
      post: "Sevadar",
      dob: "1985-02-20",
      age: "39",
      fatherHusbandName: "Mark Smith",
    },
    // Add more members as needed
  ];

  // Handle input changes for editable fields
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  // Handle selection of name from dropdown
  const handleNameChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedMember = membersData.find(
      (member) => member.name === e.target.value
    );

    if (selectedMember) {
      setFormData((prevData) => ({
        ...prevData,
        personalNumber: selectedMember.personalNumber,
        post: selectedMember.post,
        dob: selectedMember.dob,
        age: selectedMember.age,
        fatherHusbandName: selectedMember.fatherHusbandName,
      }));
      setNameSelected(true); // Show other fields
    } else {
      setNameSelected(false); // Hide fields if no name selected
    }
  };

  // Handle form submission
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log("Form Data:", formData);
  };

  return (
    <div className="container-fluid mt-5">
      <div className="row justify-content-center mt-3">
        <div className="col-12 col-lg-12">
          <div className="card shadow">
            <div className="card-body">
              <legend className="sd6BRetirementForm">
                <b>SD-6-B Retirement Form for Adhikaries</b>
              </legend>
              <p>
                Please enter the necessary details for SD-6-B Retirement Form
                for Adhikaries.
              </p>

              <form onSubmit={handleSubmit}>
                <div className="row">
                  {/* Row 1 */}
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="unitNumber">Unit Number</label>
                      <input
                        type="text"
                        className="form-control"
                        id="unitNumber"
                        value={formData.unitNumber}
                        disabled
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="branch">Branch</label>
                      <input
                        type="text"
                        className="form-control"
                        id="branch"
                        value={formData.branch}
                        disabled
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  {/* Row 2 */}
                  <div className="col-md-6">
                    <div className="form-group mt-3">
                      <label htmlFor="khetra">Kshetra</label>
                      <input
                        type="text"
                        className="form-control"
                        id="khetra"
                        value={formData.khetra}
                        disabled
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group mt-3">
                      <label htmlFor="zone">Zone</label>
                      <input
                        type="text"
                        className="form-control"
                        id="zone"
                        value={formData.zone}
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  {/* Row 3 */}
                  <div className="col-md-6">
                    <div className="form-group mt-3">
                      <label htmlFor="name">Name</label>
                      <select
                        className="form-control"
                        id="name"
                        onChange={handleNameChange}
                      >
                        <option value="">Select Name</option>
                        {membersData.map((member) => (
                          <option
                            key={member.personalNumber}
                            value={member.name}
                          >
                            {member.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>

                {nameSelected && (
                  <>
                    <div className="row">
                      {/* Row 4 */}
                      <div className="col-md-6">
                        <div className="form-group mt-3">
                          <label htmlFor="personalNumber">
                            Personal Number
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="personalNumber"
                            value={formData.personalNumber}
                            disabled
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group mt-3">
                          <label htmlFor="post">Post</label>
                          <input
                            type="text"
                            className="form-control"
                            id="post"
                            value={formData.post}
                            disabled
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      {/* Row 5 */}
                      <div className="col-md-6">
                        <div className="form-group mt-3">
                          <label htmlFor="dob">Date of Birth</label>
                          <input
                            type="date"
                            className="form-control"
                            id="dob"
                            value={formData.dob}
                            disabled
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group mt-3">
                          <label htmlFor="age">Age</label>
                          <input
                            type="text"
                            className="form-control"
                            id="age"
                            value={formData.age}
                            disabled
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      {/* Row 6 */}
                      <div className="col-md-6">
                        <div className="form-group mt-3">
                          <label htmlFor="fatherHusbandName">
                            Father/Husband Name
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="fatherHusbandName"
                            value={formData.fatherHusbandName}
                            disabled
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group mt-3">
                          <label htmlFor="reasonForRetirement">
                            Reason for Retirement
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="reasonForRetirement"
                            value={formData.reasonForRetirement}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                    </div>
                  </>
                )}

                <div className="d-flex justify-content-end mt-4">
                  <button type="submit" className="btn btn-primary">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sd6RetirementForm;
