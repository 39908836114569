import { API_ROUTES } from "src/Common/ApiRoutes";
import { AddUnitData, UnitDataModel } from "src/Common/Models/Unit/UnitDataModel";
import axiosInstance from "src/utils/axios/axiosInstance";

export const GetUnitApi = async (): Promise<UnitDataModel[]>=> {
    const response = await axiosInstance.get(API_ROUTES.GET_UNITS);
    return response.data; 
};

export const GetUnitByIdApi = async (id: number): Promise<UnitDataModel>=> {
    const response = await axiosInstance.get(API_ROUTES.GET_UNIT + `/${id}`);
    return response.data; 
};

export const AddUnitApi = async (payload: AddUnitData)=> {
    const response = await axiosInstance.post(API_ROUTES.UNIT, payload);
    return response.data; 
};

export const UnitBulkUploadApi = async (payload: File)=> {
    const formData = new FormData();
    formData.append("file", payload);
    const response = await axiosInstance.post(API_ROUTES.UNIT_BULK_UPLOAD, formData);
    return response.data; 
};

export const UnitDownloadBulkUploadFileApi = async ()=> {
    const response = await axiosInstance.get(API_ROUTES.UNIT_BULK_UPLOAD,{responseType: 'blob'});
    return response.data; 
};

