import { API_ROUTES } from "src/Common/ApiRoutes";
import { ReportDataModel, ReportsFilterDataModel } from "src/Common/Models/Reports/ReportsDataModel";
import axiosInstance from "src/utils/axios/axiosInstance";

export const GetReportsApi = async (payload: any): Promise<ReportDataModel[]> => {
    const response = await axiosInstance.post(API_ROUTES.REPORTS, payload);
    return response.data; 
};

export const ExportReportsApi = async (payload: any): Promise<ReportDataModel[]> => {
    const response = await axiosInstance.post(API_ROUTES.REPORTS_EXPORT, payload, {responseType: 'blob'});
    return response.data; 
};
