import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import "../FormsMainPage.css";

const Sd3AUniformClothForm: React.FC = () => {
  // State for form data
  const [formData, setFormData] = useState({
    unitNumber: "12345",
    branch: "Branch A",
    khetra: "Kshetra Y",
    Date: "",
  });

  // Hardcoded data for now
  useEffect(() => {
    const hardcodedData = {
      unitNumber: "12345",
      branch: "Branch A",
      khetra: "Kshetra Y",
      Date: "",
    };

    setFormData(hardcodedData);
  }, []);

  // Handle input changes for editable fields
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  const [tableData, setTableData] = useState([
    {
      name: "",
      personalNumber: "",
      numberofUniforms: "",
      amount: "",
      receiptNumber: "",
      date: "",
    },
  ]);

  // Sample members data with dummy images for John
  const membersData = [
    {
      name: "John",
      personalNumber: "PN12345",
      numberofUniforms: "",
      amount: "",
      receiptNumber: "",
      date: "",
    },
    {
      name: "Jane",
      personalNumber: "PN54321",
      numberofUniforms: "",
      amount: "",
      receiptNumber: "",
      date: "",
    },
    // Add more members as needed
  ];

  const handleTableChange = (
    index: number,
    e: React.ChangeEvent<
      HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = e.target;
    const updatedTableData = [...tableData];
    if (updatedTableData[index]) {
      updatedTableData[index] = {
        ...updatedTableData[index],
        [name]: value,
      };
      setTableData(updatedTableData);
    }
  };

  const handleNameChange = (
    index: number,
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const selectedName = e.target.value;
    const selectedMember = membersData.find(
      (member) => member.name === selectedName
    );

    const updatedTableData = [...tableData];
    if (updatedTableData[index]) {
      updatedTableData[index] = {
        ...updatedTableData[index],
        ...selectedMember, // Populate the row with the selected member's details
      };
      setTableData(updatedTableData);
    }
  };

  const addRow = () => {
    setTableData([
      ...tableData,
      {
        name: "",
        personalNumber: "",
        numberofUniforms: "",
        amount: "",
        receiptNumber: "",
        date: "",
      },
    ]);
  };

  const deleteRow = (index: number) => {
    const updatedTableData = tableData.filter((_, i) => i !== index);
    setTableData(updatedTableData);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log("Form Data:", formData);
    console.log("Table Data:", tableData);
  };

  return (
    <div className="container-fluid mt-5">
      <div className="row justify-content-center mt-3">
        <div className="col-12 col-lg-12">
          <div className="card shadow">
            <div className="card-body">
              <legend className="sd3AUniformCloth">
                <b>SD-3A Uniform Cloth Form</b>
              </legend>
              <p>Please enter the necessary details for SD-3A Uniform Cloth.</p>

              <form onSubmit={handleSubmit}>
                <div className="row">
                  {/* Column 1 */}
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="unitNumber">Unit Number</label>
                      <input
                        type="text"
                        className="form-control"
                        id="unitNumber"
                        value={formData.unitNumber}
                        disabled
                      />
                    </div>
                    <div className="form-group mt-3">
                      <label htmlFor="branch">Branch</label>
                      <input
                        type="text"
                        className="form-control"
                        id="branch"
                        value={formData.branch}
                        disabled
                      />
                    </div>
                  </div>

                  {/* Column 2 */}
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="khetra">Kshetra</label>
                      <input
                        type="text"
                        className="form-control"
                        id="khetra"
                        value={formData.khetra}
                        disabled
                      />
                    </div>
                    <div className="form-group mt-3">
                      <label htmlFor="Date">Date</label>
                      <input
                        type="date"
                        className="form-control"
                        id="Date"
                        value={formData.Date} // Bind the value to formData.Date
                        onChange={handleInputChange} // Handle the change
                      />
                    </div>
                  </div>
                </div>

                <div className="table-responsive mt-4">
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Personal Number</th>
                        <th>Number Of Uniforms</th>
                        <th>Amount</th>
                        <th>Receipt Number </th>
                        <th>Date</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {tableData.map((row, index) => (
                        <tr key={index}>
                          <td>
                            <select
                              name="name"
                              value={row.name}
                              onChange={(e) => handleNameChange(index, e)}
                            >
                              <option value="">Select Name</option>
                              {membersData.map((member) => (
                                <option key={member.name} value={member.name}>
                                  {member.name}
                                </option>
                              ))}
                            </select>
                          </td>
                          <td>
                            <input
                              type="text"
                              name="personalNumber"
                              value={row.personalNumber}
                              onChange={(e) => handleTableChange(index, e)}
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              name="numberofUniforms"
                              value={row.numberofUniforms}
                              onChange={(e) => handleTableChange(index, e)}
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              name="amount"
                              value={row.amount}
                              onChange={(e) => handleTableChange(index, e)}
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              name="receiptNumberorDate"
                              value={row.receiptNumber}
                              onChange={(e) => handleTableChange(index, e)}
                            />
                          </td>
                          <td>
                            <input
                              type="date"
                              name="date"
                              value={row.date}
                              onChange={(e) => handleTableChange(index, e)}
                            />
                          </td>
                          <td>
                            <button className="addRow" type="button" onClick={addRow}>
                              <FontAwesomeIcon
                                icon={faPlus}
                                style={{ color: "#007bff" }}
                              />
                            </button>
                            {index > 0 && (
                              <button
                                className="deleteRow"
                                type="button"
                                onClick={() => deleteRow(index)}
                                style={{ marginLeft: "10px" }}
                              >
                                <FontAwesomeIcon
                                  icon={faTrash}
                                  style={{ color: "red" }}
                                />
                              </button>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>

                <div className="d-flex justify-content-end mt-4">
                  <button type="submit" className="btn btn-primary">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sd3AUniformClothForm;
