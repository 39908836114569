import { useTranslation } from 'react-i18next';

import {
  BLOOD_GROUP,
  DB_GENDER,
  FILTER_INPUT_TYPE,
  REPORT_TYPE,
  SEWADAL_TYPE,
  USER_TYPE,
} from './AppEnum';
import { FilterField } from './Models/Filter/FilterModel';

export const useAttendanceFilterFields = (): FilterField[] => {
  const { t } = useTranslation();

  return [
    {
      type: "date",
      dateType: "date",
      label: "Select Date",
      key: FILTER_INPUT_TYPE.DATE,
      placeholder: t("select-date-label"),
      dependentIndex: -1,
      required: true,
    },
    {
      type: "select",
      label: "Select Satsang",
      key: FILTER_INPUT_TYPE.SATSANG,
      placeholder: t("select-satsang-label"),
      options: [],
      dependentIndex: 2,
      required: true,
    },
    {
      type: "select",
      label: "Select Satsang Type",
      key: FILTER_INPUT_TYPE.SATSANG_TYPE,
      placeholder: t("select-satsang-type-label"),
      options: [],
      dependentIndex: -1,
      required: true,
    },
  ];
};

export const useReportsFilterFields = (userType: USER_TYPE,reportType:REPORT_TYPE): FilterField[] => {
  const { t } = useTranslation();
  let filterOptions:FilterField[] = [];
  if (
    userType === USER_TYPE.ADMIN ||
    userType === USER_TYPE.KSHETRIYA_SANCHALAK
  )
    filterOptions = [
      {
        type: "select",
        label: "Select Unit",
        key: FILTER_INPUT_TYPE.UNIT,
        placeholder: t("select-all-default-option"),
        options: [],
        dependentIndex: -1,
        required: false,
        allowedReportType: [
          REPORT_TYPE.ADHIKARI_INDIVIDUAL,
          REPORT_TYPE.UNITWISE,
          REPORT_TYPE.BLOOD_GROUP,
          REPORT_TYPE.BELOW_60,
          REPORT_TYPE.TRADE,
        ],
      },
      {
        type: "date",
        dateType: "month",
        label: "Select Start Month",
        key: FILTER_INPUT_TYPE.START_MONTH,
        placeholder: t("select-start-date-label"),
        dependentIndex: -1,
        required: true,
        allowedReportType: [
          REPORT_TYPE.ADHIKARI_INDIVIDUAL,
          REPORT_TYPE.UNITWISE,
          REPORT_TYPE.BELOW_60,
          REPORT_TYPE.TRADE,
        ],
      },
      {
        type: "date",
        dateType: "month",
        label: "Select End Month",
        key: FILTER_INPUT_TYPE.END_MONTH,
        placeholder: t("select-end-date-label"),
        dependentIndex: -1,
        required: true,
        allowedReportType: [
          REPORT_TYPE.ADHIKARI_INDIVIDUAL,
          REPORT_TYPE.UNITWISE,
          REPORT_TYPE.BELOW_60,
          REPORT_TYPE.TRADE,
        ],
      },
      {
        type: "select",
        label: t("select-sewadal-designation-type"),
        key: FILTER_INPUT_TYPE.SEWADAL_DESIGNATION_TYPE,
        placeholder: t("select-all-default-option"),
        options: [
          { key: SEWADAL_TYPE.SANCHALAK, value: "Sanchalak" },
          { key: SEWADAL_TYPE.SHIKSHAK, value: "Shikshak" },
          { key: SEWADAL_TYPE.SANCHALIKA, value: "Sanchalika" },
          { key: SEWADAL_TYPE.S_SHIKSHAK, value: "S.Shikshak" },
          { key: SEWADAL_TYPE.S_SHIKSHIKA, value: "S.Shikshika" },
        ],
        dependentIndex: -1,
        required: false,
        allowedReportType: [
          REPORT_TYPE.ADHIKARI_INDIVIDUAL,
          REPORT_TYPE.UNITWISE,
          REPORT_TYPE.BELOW_60,
          REPORT_TYPE.TRADE,
        ],
      },
      {
        type: "select",
        label: t("select-sewadal-adhikari-type"),
        key: FILTER_INPUT_TYPE.SEWADAL,
        placeholder: t("select-all-default-option"),
        options: [{ key: 0, value: "Select Adhikari" }],
        dependentIndex: -1,
        required: false,
        allowedReportType: [
          REPORT_TYPE.ADHIKARI_INDIVIDUAL,
          REPORT_TYPE.UNITWISE,
          REPORT_TYPE.BELOW_60,
        ],
      },
      {
        type: "select",
        label: t("select-sewadal-gender-type"),
        key: FILTER_INPUT_TYPE.SEWADAL_GENDER_TYPE,
        placeholder: t("select-all-default-option"),
        options: [
          { key: DB_GENDER.MALE, value: "Gents" },
          { key: DB_GENDER.FEMALE, value: "Ladies" },
        ],
        dependentIndex: -1,
        required: false,
        allowedReportType: [
          REPORT_TYPE.BLOOD_GROUP,
          REPORT_TYPE.TRADE,
          REPORT_TYPE.BELOW_60,
          REPORT_TYPE.UNITWISE
        ],
      },
       {
        type: "select",
        label: t("select-blood-group-label"),
        key: FILTER_INPUT_TYPE.BLOOD_GROUP,
        placeholder: t("select-all-default-option"),
        options: Object.entries(BLOOD_GROUP).map(([key, value]) => ({
          key,
          value,
        })),
        dependentIndex: -1,
        required: false,
        allowedReportType: [REPORT_TYPE.BLOOD_GROUP],
      },
      {
        type: "select",
        label: t("select-trade-label"),
        key: FILTER_INPUT_TYPE.TRADE,
        placeholder: t("select-all-default-option"),
        options:  [
          { key: 'Trade 1', value: "TRADE 1" },
          { key: 'Trade 2', value: "TRADE 2" },
        ],
        dependentIndex: -1,
        required: false,
        allowedReportType: [REPORT_TYPE.TRADE],
      },
    ];
  else
    filterOptions = [
      {
        type: "date",
        dateType: "month",
        label: "Select Start Date",
        key: FILTER_INPUT_TYPE.START_MONTH,
        placeholder: t("select-start-date-label"),
        dependentIndex: -1,
        required: true,
        allowedReportType: [
          REPORT_TYPE.ATTENDANCE_DETAILS,
          REPORT_TYPE.UNITWISE,
          REPORT_TYPE.BELOW_60,
        ],
      },
      {
        type: "date",
        dateType: "month",
        label: "Select End Date",
        key: FILTER_INPUT_TYPE.END_MONTH,
        placeholder: t("select-end-date-label"),
        dependentIndex: -1,
        required: true,
        allowedReportType: [
          REPORT_TYPE.ATTENDANCE_DETAILS,
          REPORT_TYPE.UNITWISE,
          REPORT_TYPE.BELOW_60,
        ],
      },
      {
        type: "select",
        label: t("select-blood-group-label"),
        key: FILTER_INPUT_TYPE.BLOOD_GROUP,
        placeholder: t("select-all-default-option"),
        options: Object.entries(BLOOD_GROUP).map(([key, value]) => ({
          key,
          value,
        })),
        dependentIndex: -1,
        required: false,
        allowedReportType: [REPORT_TYPE.BLOOD_GROUP],
      },
       {
        type: "select",
        label: t("select-sewadal-gender-type"),
        key: FILTER_INPUT_TYPE.SEWADAL_GENDER_TYPE,
        placeholder: t("select-all-default-option"),
        options: [
          { key: DB_GENDER.MALE, value: "Gents" },
          { key: DB_GENDER.FEMALE, value: "Ladies" },
        ],
        dependentIndex: -1,
        required: false,
         allowedReportType: [
          REPORT_TYPE.ATTENDANCE_DETAILS,
          REPORT_TYPE.BELOW_60,
          REPORT_TYPE.UNITWISE,
          REPORT_TYPE.BLOOD_GROUP,
          REPORT_TYPE.TRADE,
         ],
      },
        {
        type: "select",
        label: t("select-trade-label"),
        key: FILTER_INPUT_TYPE.TRADE,
        placeholder: t("select-all-default-option"),
         options:  [
          { key: 'Trade 1', value: "TRADE 1" },
          { key: 'Trade 2', value: "TRADE 2" },
        ],
        dependentIndex: -1,
        required: false,
        allowedReportType: [REPORT_TYPE.TRADE],
      },

    ];
  return filterOptions;
};
