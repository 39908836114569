import { API_ROUTES } from 'src/Common/ApiRoutes';
import { SewadalDataModelList } from 'src/Common/Models/Sewadal/SewadalModel';
import {
  GetSewadalFormsListingResponse,
  SewadalFormsDetailResponse,
  SubmitSD1Form,
} from 'src/Common/Models/SewadalForms/SewadalFormsModel';
import axiosInstance from 'src/utils/axios/axiosInstance';

export const GetSewadalFormsDetails = async (
  unitNo: number,
  type: string
): Promise<SewadalFormsDetailResponse> => {
  const response = await axiosInstance.get(
    `${API_ROUTES.SEWADAL_FORMS_DETAILS}/${unitNo}/${type}`
  );
  return response.data;
};

export const GetAllSubmittedSewadalForms = async (): Promise<GetSewadalFormsListingResponse[]>=> {
    const response = await axiosInstance.get(`${API_ROUTES.SEWADAL_FORMS}`);
    return response.data; 
};

export const GetSubmittedSewadalFormsDetailsById = async (id:number): Promise<any>=> {
    const response = await axiosInstance.get(`${API_ROUTES.SEWADAL_FORMS}/${id}`);
    return response.data; 
};

export const UpdateSubmittedSewadalFormsDetailsById = async (id:number,payload: any): Promise<SewadalDataModelList>=> {
    const response = await axiosInstance.patch(`${API_ROUTES.SEWADAL_FORMS}/${id}`,payload);
    return response.data; 
};

export const SubmitSewadalFormsDetails = async (payload: SubmitSD1Form): Promise<SewadalDataModelList>=> {
    const response = await axiosInstance.post(`${API_ROUTES.SEWADAL_FORMS}`,payload);
    return response.data; 
};

export const DownloadSewadalFormsById = async (id: number): Promise<void> => {
    const response = await axiosInstance.get(`${API_ROUTES.SEWADALFORMS}/download/${id}`, {
        responseType: 'arraybuffer',
    });
    const blob = new Blob([response.data], { type: 'application/zip' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `SewadalForms_${id}.zip`);
    document.body.appendChild(link);
    link.click();
    link.parentNode?.removeChild(link);
};
