import { API_ROUTES } from "src/Common/ApiRoutes";
import { SEWADAL_TYPE } from "src/Common/AppEnum";
import { AttendanceFilterModel, KeyValueObject } from "src/Common/Models/Filter/FilterModel";
import axiosInstance from "src/utils/axios/axiosInstance";

export const GetFilterApi = async (filterType: any): Promise<any>=> {
    const response = await axiosInstance.post(API_ROUTES.FILTER, filterType);
    return response.data.data; 
};

export const GetAdhikariFilterApi = async (unitNo: number, sewadalType: string): Promise<any>=> {
    const response = await axiosInstance.get(API_ROUTES.FILTER_ADHIKARI_KEY_VALUE + `?unitNo=${unitNo}&sewadalType=${sewadalType}`);
    return response.data; 
};

export const GetSewadalKeyValue = async (sewadalType: SEWADAL_TYPE[], unitNo: number): Promise<KeyValueObject[]>=> {
    let data = {
        type: sewadalType,
        unitNo: unitNo
    }
    const response = await axiosInstance.post(API_ROUTES.SEWADAL_KEY_VALUE, data);
    return response.data.data; 
};

