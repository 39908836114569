import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ToastState {
  visible: boolean;
  title: string;
  message: string;
  time: string;
}

const initialState: ToastState = {
  visible: false,
  title: '',
  message: '',
  time: '',
};

const toastSlice = createSlice({
  name: 'toast',
  initialState,
  reducers: {
    showToast: (state, action: PayloadAction<{ title: string; message: string; time: string }>) => {
      state.visible = true;
      state.title = action.payload.title;
      state.message = action.payload.message;
      state.time = action.payload.time;
    },
    hideToast: (state) => {
      state.visible = false;
      state.title = '';
      state.message = '';
      state.time = '';
    },
  },
});

export const { showToast, hideToast } = toastSlice.actions;
export default toastSlice.reducer;
