import { API_ROUTES } from "src/Common/ApiRoutes";
import { AttendanceDataModel, AttendanceMarkPayloadModel } from "src/Common/Models/Attendance/AttendanceDataModel";
import { AttendanceFilterDataModel } from "src/Common/Models/Attendance/FilterDataModel";
import axiosInstance from "src/utils/axios/axiosInstance";

export const GetSewadalAttendanceApi = async (payload: AttendanceFilterDataModel): Promise<AttendanceDataModel[]> => {
    const response = await axiosInstance.post(API_ROUTES.GET_ATTENDANCE, payload);
    return response.data; 
}; 

export const MarkAttendanceApi = async (payload: AttendanceMarkPayloadModel): Promise<void> => {
    const response = await axiosInstance.post(API_ROUTES.MARK_ATTENDANCE, payload);
    return response.data; 
};

export const GetSewadalDashboardAttendanceApi = async () => {
    const response = await axiosInstance.get(API_ROUTES.DASHBOARD_ATTENDANCE_COUNT);
    return response.data; 
};
