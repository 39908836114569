// ./api/satsangApi.ts
import { API_ROUTES } from "src/Common/ApiRoutes";
import { SatsangModel, SatsangResultDataModel } from "src/Common/Models/Satsang/SatsangModel";
import axiosInstance from "src/utils/axios/axiosInstance";

export const AddSatsangApi = async (satsangData: SatsangModel) => {
    const response = await axiosInstance.post(API_ROUTES.SATSANG, satsangData);
    return response.data; 
};

export const GetSatsangApi = async (): Promise<SatsangResultDataModel>=> {
    const response = await axiosInstance.get(API_ROUTES.SATSANG);
    return response.data; 
};

export const GetSatsangByIdApi = async (id: number): Promise<SatsangModel>=> {
    const response = await axiosInstance.get(API_ROUTES.SATSANG + `/${id}`);
    return response.data; 
};

export const UpdateSatsangByIdApi = async (id: number, data: SatsangModel) => {
    const response = await axiosInstance.patch(API_ROUTES.SATSANG + `/${id}`, data);
    return response.data; 
};

export const DeleteSatsangByIdApi = async (id: number ) => {
    const response = await axiosInstance.delete(API_ROUTES.SATSANG + `/${id}`);
    return response.data; 
};

export const UndoDeleteSatsangByIdApi = async (id: number ) => {
    const response = await axiosInstance.patch(API_ROUTES.UNDO_SATSANG_DELETE + `/${id}`);
    return response.data; 
};
