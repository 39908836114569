// useDebounce.ts
import { useEffect, useState } from 'react';

/**
 * Custom hook to debounce a value after a delay.
 *
 * @param value - The input value to debounce.
 * @param delay - The delay in milliseconds.
 * @returns The debounced value.
 */
const useDebounce = (value: string, delay: number) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler); // Cleanup the timeout on value or delay change
    };
  }, [value, delay]);

  return debouncedValue;
};

export default useDebounce;
