import { API_ROUTES } from 'src/Common/ApiRoutes';
import { AttendanceUserModel } from 'src/Common/Models/Sewadal/AttendanceUserModel';
import {
  AddSewadalFormModel,
  GetSewadalApiParams,
  SewadalDataModel,
  SewadalDataModelList,
} from 'src/Common/Models/Sewadal/SewadalModel';
import axiosInstance from 'src/utils/axios/axiosInstance';

export const GetSewadalApi = async (params: GetSewadalApiParams): Promise<SewadalDataModelList> => {
    const response = await axiosInstance.get(`${API_ROUTES.GET_SEWADAL}`, {
        params: {
            gender: params.gender,
            unitNo: params.unitNo,
            page: params.page,
            pageSize: params.pageSize,
            searchTerm: params.searchTerm,
            type: params.type,
            timestamp: new Date().getTime(), // Unique timestamp for every call
        },
    });
    return response.data;
};


export const GetSewadalApi1 = async (): Promise<SewadalDataModel[]>=> {
    const response = await axiosInstance.get(API_ROUTES.GET_SEWADAL);
    return response.data; 
};


export const AddSewadalApi = async (payload: AddSewadalFormModel)=> {
    const response = await axiosInstance.post(API_ROUTES.SEWADAL, payload);
    return response.data; 
};

export const SewadalBulkUploadApi = async (payload: File)=> {
    const formData = new FormData();
    formData.append("file", payload);
    const response = await axiosInstance.post(API_ROUTES.SEWADAL_BULK_UPLOAD, formData);
    return response.data; 
};

export const SewadalDownloadBulkUploadFileApi = async ()=> {
    const response = await axiosInstance.get(API_ROUTES.SEWADAL_BULK_UPLOAD, {responseType: 'blob'});
    return response.data; 
};

export const AddAttendanceUserApi = async (payload: AttendanceUserModel)=> {
    const response = await axiosInstance.post(API_ROUTES.ADD_ATTENDANCE_USER, payload);
    return response.data; 
};
