import React, { useState } from "react";
import { Pie } from "react-chartjs-2";
import { Chart } from "chart.js"; // Import Chart from chart.js
import "chart.js/auto";
import "./AttendancePieChart.css";
import "@fortawesome/fontawesome-free/css/all.min.css";

interface Adhikari {
  name: string;
  role: string;
  registeredSewadalDataInPersent: { present: number; absent: number };
  unregisteredSewadalDataInPersent: { present: number; absent: number };
  unregisteredSewadalDataInCount: { present: number; absent: number };
  registeredSewadalDataInCount: { present: number; absent: number };
}

// Custom plugin to display total count
const totalCountPlugin = {
  id: "totalCountPlugin",
  beforeDraw: (chart: any) => {
    const { ctx, data, chartArea } = chart;
    const total = data.datasets[0].totalCount.reduce(
      (sum: any, value: any) => sum + value,
      0
    );
    if (chartArea) {
      ctx.save();

      // Calculate the radius based on the chart area
      const radius = Math.min(chartArea.width, chartArea.height) / 2;

      // Set the font size
      const fontSize = radius / 6; // Adjust the divisor to control font size
      ctx.font = `bold ${fontSize}px Poppins`;
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";

      // Create a linear gradient
      const gradient = ctx.createLinearGradient(
        chartArea.left + (chartArea.right - chartArea.left) / 2,
        chartArea.top,
        chartArea.left + (chartArea.right - chartArea.left) / 2,
        chartArea.bottom
      );
      gradient.addColorStop(0, "#007bb6"); // First color
      gradient.addColorStop(1, "#6853d2"); // Second color

      // Set the gradient as fill style
      ctx.fillStyle = gradient;

      // Positioning for the "Total:" text
      const totalTextY =
        chartArea.top + (chartArea.bottom - chartArea.top) / 2 - fontSize * 0.5; // Move it up slightly
      ctx.fillText(
        "Total",
        chartArea.left + (chartArea.right - chartArea.left) / 2,
        totalTextY
      );

      // Positioning for the total count text
      const countTextY =
        chartArea.top + (chartArea.bottom - chartArea.top) / 2 + fontSize * 0.5; // Move it down slightly
      ctx.fillText(
        total.toString(),
        chartArea.left + (chartArea.right - chartArea.left) / 2,
        countTextY
      );

      ctx.restore();
    }
  },
};

// Register the custom plugin with Chart
Chart.register(totalCountPlugin);

const AttendancePieChart: React.FC = () => {
  const [activePersonIndex, setActivePersonIndex] = useState(0);
  const [activeTab, setActiveTab] = useState<"Gents" | "Ladies">("Gents");

  const adhikariDataGents: Adhikari[] = [
    {
      name: "Sitaram Kaskar Ji",
      role: "Sanchalak",
      registeredSewadalDataInPersent: { present: 60, absent: 30 },
      unregisteredSewadalDataInPersent: { present: 50, absent: 50 },
      registeredSewadalDataInCount: { present: 150, absent: 50 },
      unregisteredSewadalDataInCount: { present: 10, absent: 5 },
    },
    {
      name: "Yogesh Madekar Ji",
      role: "Shikshak",
      registeredSewadalDataInPersent: { present: 70, absent: 30 },
      unregisteredSewadalDataInPersent: { present: 45, absent: 55 },
      registeredSewadalDataInCount: { present: 100, absent: 30 },
      unregisteredSewadalDataInCount: { present: 20, absent: 7 },
    },
    {
      name: "Vinayak Wadkar Ji",
      role: "Sanchalak",
      registeredSewadalDataInPersent: { present: 60, absent: 30 },
      unregisteredSewadalDataInPersent: { present: 50, absent: 50 },
      registeredSewadalDataInCount: { present: 150, absent: 50 },
      unregisteredSewadalDataInCount: { present: 10, absent: 5 },
    },
    {
      name: "Bipin Bhagat Ji",
      role: "Shikshak",
      registeredSewadalDataInPersent: { present: 70, absent: 30 },
      unregisteredSewadalDataInPersent: { present: 45, absent: 55 },
      registeredSewadalDataInCount: { present: 100, absent: 30 },
      unregisteredSewadalDataInCount: { present: 20, absent: 7 },
    },
    {
      name: "Sunil Hinge Ji",
      role: "Sanchalak",
      registeredSewadalDataInPersent: { present: 60, absent: 30 },
      unregisteredSewadalDataInPersent: { present: 50, absent: 50 },
      registeredSewadalDataInCount: { present: 150, absent: 50 },
      unregisteredSewadalDataInCount: { present: 10, absent: 5 },
    },
    {
      name: "Sandeep Borate Ji",
      role: "Shikshak",
      registeredSewadalDataInPersent: { present: 70, absent: 30 },
      unregisteredSewadalDataInPersent: { present: 45, absent: 55 },
      registeredSewadalDataInCount: { present: 100, absent: 30 },
      unregisteredSewadalDataInCount: { present: 20, absent: 7 },
    },
  ];

  const adhikariDataLadies: Adhikari[] = [
    {
      name: "Nanda Ranpise Ji",
      role: "Sanchalika",
      registeredSewadalDataInPersent: { present: 65, absent: 35 },
      unregisteredSewadalDataInPersent: { present: 55, absent: 45 },
      registeredSewadalDataInCount: { present: 120, absent: 40 },
      unregisteredSewadalDataInCount: { present: 6, absent: 15 },
    },
    {
      name: "Sangita Matekar Ji",
      role: "Shikshika",
      registeredSewadalDataInPersent: { present: 75, absent: 25 },
      unregisteredSewadalDataInPersent: { present: 40, absent: 60 },
      registeredSewadalDataInCount: { present: 90, absent: 50 },
      unregisteredSewadalDataInCount: { present: 40, absent: 15 },
    },
    {
      name: "Poonam Bondre Ji",
      role: "S.Shikshika",
      registeredSewadalDataInPersent: { present: 65, absent: 35 },
      unregisteredSewadalDataInPersent: { present: 55, absent: 45 },
      registeredSewadalDataInCount: { present: 120, absent: 40 },
      unregisteredSewadalDataInCount: { present: 6, absent: 15 },
    },
    {
      name: "Pooja Yadav Ji",
      role: "S.Shikshika",
      registeredSewadalDataInPersent: { present: 75, absent: 25 },
      unregisteredSewadalDataInPersent: { present: 40, absent: 60 },
      registeredSewadalDataInCount: { present: 90, absent: 50 },
      unregisteredSewadalDataInCount: { present: 40, absent: 15 },
    },
  ];

  const createPieData = (
    present: number,
    absent: number,
    presentCount: number,
    absentCount: number
  ) => ({
    labels: ["Present", "Absent"],
    datasets: [
      {
        data: [present, absent],
        totalCount: [presentCount, absentCount],
        backgroundColor: ["#3fa744", "#f14343"],
        hoverBackgroundColor: ["#70c468", "#ff5757"],
        borderWidth: 2,
        borderColor: "#fff",
        hoverBorderColor: "#fff",
      },
    ],
  });

  const options = {
    plugins: {
      tooltip: {
        backgroundColor: "#fff",
        titleColor: "#333",
        bodyColor: "#666",
        borderColor: "#ccc",
        borderWidth: 1,
        callbacks: {
          label: function (context: any) {
            const label = context.label || "";
            const value = context.raw || "";
            return `${label}: ${value}%`;
          },
        },
        displayColors: false,
      },
      totalCountPlugin: true, // Ensure the plugin is registered here
    },
    responsive: true,
    maintainAspectRatio: false,
    cutout: "60%",
    animation: {
      animateRotate: true,
      animateScale: true,
    },
  };

  const handlePrevious = () => {
    setActivePersonIndex((prevIndex) =>
      activeTab === "Gents"
        ? prevIndex === 0
          ? adhikariDataGents.length - 1
          : prevIndex - 1
        : prevIndex === 0
        ? adhikariDataLadies.length - 1
        : prevIndex - 1
    );
  };

  const handleNext = () => {
    setActivePersonIndex((prevIndex) =>
      activeTab === "Gents"
        ? prevIndex === adhikariDataGents.length - 1
          ? 0
          : prevIndex + 1
        : prevIndex === adhikariDataLadies.length - 1
        ? 0
        : prevIndex + 1
    );
  };

  const activeData =
    activeTab === "Gents"
      ? adhikariDataGents[activePersonIndex]
      : adhikariDataLadies[activePersonIndex];

  return (
    <div className="adhikari-stats-container fancy-background">
      <h5>Avg Of Particular Adhikari</h5>

      <div className="tabs">
        <button
          className={`tab ${activeTab === "Gents" ? "active" : ""}`}
          onClick={() => setActiveTab("Gents")}
        >
          Gents Sewadal
        </button>
        <button
          className={`tab ${activeTab === "Ladies" ? "active" : ""}`}
          onClick={() => setActiveTab("Ladies")}
        >
          Ladies Sewadal
        </button>
      </div>

      <div className="slideshow-container">
        <div className="person-content fancy-glass">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <button className="arrow left" onClick={handlePrevious}>
              <i className="fas fa-chevron-left"></i>
            </button>
            <button className="arrow right" onClick={handleNext}>
              <i className="fas fa-chevron-right"></i>
            </button>
          </div>
          <div className="adhikari-name-role">
            <h6 className="adhikari-name">{activeData.name}</h6>
            <span className="adhikari-role">({activeData.role})</span>
          </div>
          <div className="charts-row">
            <div className="chart fancy-chart">
              <h6>Register Sewadal</h6>
              <div className="pie-container">
                <Pie
                  data={createPieData(
                    activeData.registeredSewadalDataInPersent.present,
                    activeData.registeredSewadalDataInPersent.absent,
                    activeData.registeredSewadalDataInCount.present,
                    activeData.registeredSewadalDataInCount.absent
                  )}
                  options={options}
                />
              </div>
              <div className="color-indicator-box">
                <div className="box-item">
                  <span
                    className="box-color"
                    style={{ backgroundColor: "#3fa744" }}
                  ></span>
                  <span>
                    Present Count:{" "}
                    {activeData.registeredSewadalDataInCount.present}
                  </span>
                </div>
                <div className="box-item">
                  <span
                    className="box-color"
                    style={{ backgroundColor: "#f14343" }}
                  ></span>
                  <span>
                    Absent Count:{" "}
                    {activeData.registeredSewadalDataInCount.absent}
                  </span>
                </div>
              </div>
            </div>
            <div className="chart fancy-chart">
              <h6>Unregister Sewadal</h6>
              <div className="pie-container">
                <Pie
                  data={createPieData(
                    activeData.unregisteredSewadalDataInPersent.present,
                    activeData.unregisteredSewadalDataInPersent.absent,
                    activeData.unregisteredSewadalDataInCount.present,
                    activeData.unregisteredSewadalDataInCount.absent
                  )}
                  options={options}
                />
              </div>
              <div className="color-indicator-box">
                <div className="box-item">
                  <span
                    className="box-color"
                    style={{ backgroundColor: "#3fa744" }}
                  ></span>
                  <span>
                    Present Count:{" "}
                    {activeData.unregisteredSewadalDataInCount.present}
                  </span>
                </div>
                <div className="box-item">
                  <span
                    className="box-color"
                    style={{ backgroundColor: "#f14343" }}
                  ></span>
                  <span>
                    Absent Count:{" "}
                    {activeData.unregisteredSewadalDataInCount.absent}
                  </span>
                </div>
              </div>
            </div>
          </div>
          {/* Box for color indicators and counts */}
          <div className="color-indicator-box">
            <div className="box-item">
              <span>
                <b>
                  Total Sewadal:{" "}
                  {activeData.registeredSewadalDataInCount.present +
                    activeData.unregisteredSewadalDataInCount.present +
                    activeData.registeredSewadalDataInCount.absent +
                    activeData.unregisteredSewadalDataInCount.absent}
                </b>
              </span>
            </div>
            <div className="box-item">
              <span
                className="box-color"
                style={{ backgroundColor: "#3fa744" }}
              ></span>
              <span>
                Overall Present:{" "}
                {activeData.registeredSewadalDataInCount.present +
                  activeData.unregisteredSewadalDataInCount.present}
              </span>
            </div>
            <div className="box-item">
              <span
                className="box-color"
                style={{ backgroundColor: "#f14343" }}
              ></span>
              <span>
                Overall Absent:{" "}
                {activeData.registeredSewadalDataInCount.absent +
                  activeData.unregisteredSewadalDataInCount.absent}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AttendancePieChart;
