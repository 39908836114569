// ./Common/AppConstant.ts
import { addAttendanceImage } from "src/Component/Dashboard/Images/AddAttendanceImage";
import { addSewadalImage } from "src/Component/Dashboard/Images/AddSewadalImage";
import { addUnitImage } from "src/Component/Dashboard/Images/AddUnitImage";
import { sewadalFormImage } from "src/Component/Dashboard/Images/SewadalFormImage";

import { USER_TYPE } from "./AppEnum";
import { DashboardCardModel } from "./Models/Dashboard/DashboardCardModel";

export const adminDashboardCards: DashboardCardModel[] = [
  {
    key: "addUnit",
    className: "bluebox",
    label: "add-unit-label",
    route: "/unit/add",
    image: addUnitImage,
  },
  {
    key: "addSewadal",
    className: "yellowbox",
    label: "add-sewadal-label",
    route: "/sewadal/add",
    image: addSewadalImage,
  },
  {
    key: "sewadalForms",
    className: "purplebox",
    label: "sewadal-forms-label",
    image: sewadalFormImage,
    route: "/sewadalForms",
  },
  // {
  //   key: "du",
  //   className: "purplebox",
  //   label: "sewadal-forms-label",
  //   image: sewadalFormImage,
  //   route: "/sewadalForms",
  // },
];

export const adhikariDashboardCards: DashboardCardModel[] = [
  {
    key: "attendanceDetails",
    className: "bluebox",
    label: "attendance-details",
    route: "",
    image: addUnitImage,
  },
  {
    key: "sewadalForms",
    className: "purplebox",
    label: "sewadal-forms-label",
    image: sewadalFormImage,
    route: "/sewadalForms",
  },
  {
    key: "pendingFollowUp",
    className: "yellowbox",
    label: "Pending Follow Up",
    route: "/followUpListAdhikari",
    image: addSewadalImage,
  },
];

export const sewadalDashboardCards: DashboardCardModel[] = [
  {
    key: "addSewadalAttendance",
    className: "darkbluebox",
    label: "add-sewadal-attendance-label",
    route: "/attendance",
    image: addAttendanceImage,
  },
];

export const sidebarItems = [
  {
    label: "Dashboard",
    icon: "faDashboard",
    route: "/dashboard",
    permission: null,
    role: [USER_TYPE.ADHIKARI, USER_TYPE.ADMIN, USER_TYPE.KSHETRIYA_SANCHALAK],
  },
  {
    label: "Add Attendance",
    icon: "faUserPlus",
    route: "/attendance",
    permission: 524288,
    role: [USER_TYPE.ADHIKARI, USER_TYPE.ADMIN, USER_TYPE.ATTENDANCE],
  },
  {
    label: "Master Data",
    icon: "faUserFriends",
    route: "#",
    dropdown: true,
    permission: 1048576,
    role: [USER_TYPE.ADHIKARI, USER_TYPE.ADMIN, USER_TYPE.KSHETRIYA_SANCHALAK],
    subItems: [
      {
        label: "Satsang",
        // icon: "faUserFriends",
        route: "/satsang",
        permission: 524288,
        role: [USER_TYPE.ADHIKARI, USER_TYPE.ADMIN],
      },
      {
        label: "Groups",
        // icon: "faUserFriends",
        route: "/groups",
        permission: 524288,
        role: [USER_TYPE.ADHIKARI, USER_TYPE.ADMIN],
      },
      {
        label: "Units",
        // icon: "faUserFriends",
        route: "/units",
        permission: 524288,
        role: [USER_TYPE.KSHETRIYA_SANCHALAK, USER_TYPE.ADMIN],
      },
      {
        label: "Sewadal",
        // icon: "faUserFriends",
        route: "/sewadal",
        permission: 524288,
        role: [
          USER_TYPE.KSHETRIYA_SANCHALAK,
          USER_TYPE.ADHIKARI,
          USER_TYPE.ADMIN,
        ],
      },
    ],
  },
  {
    label: "SD Forms",
    icon: "faUserFriends",
    route: "/sewadalForms",
    dropdown: true,
    permission: 1048576,
    role: [USER_TYPE.ADHIKARI, USER_TYPE.ADMIN, USER_TYPE.KSHETRIYA_SANCHALAK],
    subItems: [
      {
        label: "Submitted Forms",
        icon: "faUserFriends",
        route: "/sewadalForms/allSubmittedForms",
        permission: 524288,
        role: [
          USER_TYPE.ADHIKARI,
          USER_TYPE.ADMIN,
          USER_TYPE.KSHETRIYA_SANCHALAK,
        ],
      },
      {
        label: "SD1",
        icon: "faUserFriends",
        route: "/sewadalForms/SD1",
        permission: 524288,
        role: [USER_TYPE.ADHIKARI, USER_TYPE.ADMIN],
      },
    ],
  },
  {
    label: "Entry",
    icon: "faListAlt",
    route: "#",
    dropdown: true,
    permission: 2097152,
    role: [USER_TYPE.ADHIKARI, USER_TYPE.ADMIN, USER_TYPE.KSHETRIYA_SANCHALAK],
    subItems: [
      {
        label: "Add Unit",
        // icon: "faUserFriends",
        route: "/unit-upload-bulk",
        permission: 524288,
        role: [USER_TYPE.KSHETRIYA_SANCHALAK, USER_TYPE.ADMIN],
      },
      {
        label: "Add Satsang",
        // icon: "faUserFriends",
        route: "/satsang/add",
        permission: 524288,
        role: [USER_TYPE.ADHIKARI, USER_TYPE.ADMIN],
      },
      {
        label: "Add Sewadal",
        icon: "",
        route: "/sewadal/add",
        permission: 524288,
        role: [
          USER_TYPE.KSHETRIYA_SANCHALAK,
          USER_TYPE.ADHIKARI,
          USER_TYPE.ADMIN,
        ],
      },

      {
        label: "Duty Chart",
        // icon: "faUserFriends",
        route: "/entry/dutyChart",
        permission: 524288,
        role: [USER_TYPE.KSHETRIYA_SANCHALAK, USER_TYPE.ADMIN],
      },
    ],
  },
  {
    label: "Reports",
    icon: "farChartBar",
    route: "/reports",
    dropdown: false,
    permission: 4194304,
    role: [USER_TYPE.ADHIKARI, USER_TYPE.ADMIN, USER_TYPE.KSHETRIYA_SANCHALAK],
    // subItems: [
    //   { label: "Report 1", route: "/report/report-1", permission: 4194304 },
    //   { label: "Report 2", route: "/report/report-2", permission: 4194304 },
    //   { label: "Report 3", route: "/report/report-3", permission: 4194304 },
    // ],
  },
  // {
  //   label: "Settings",
  //   icon: "faCogSolid",
  //   route: "/settings",
  //   permission: 8388608,
  //   role: [USER_TYPE.ADHIKARI, USER_TYPE.ADMIN, USER_TYPE.KSHETRIYA_SANCHALAK],
  // },
];

export const BloogGroupData = [
  { key: "A+", value: "A+" },
  { key: "A-", value: "A-" },
  { key: "B+", value: "B+" },
  { key: "B-", value: "B-" },
  { key: "AB+", value: "AB+" },
  { key: "AB-", value: "AB-" },
  { key: "O+", value: "O+" },
  { key: "O-", value: "O-" },
];

export const SectorMasterData = [
  { name: "Sector 15", value: 15 },
  { name: "Sector 16", value: 16 },
  { name: "Sector 20", value: 20 },
  { name: "Sector 21", value: 21 },
  { name: "Sector 22", value: 22 },
];
