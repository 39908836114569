import 'bootstrap/dist/css/bootstrap.min.css';
import '../FormsMainPage.css'; // Custom CSS for styling the component

import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';

import {
  Button,
  Modal,
} from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { GetSewadalApi } from 'src/api/sewadalApi';
import {
  GetSewadalFormsDetails,
  GetSubmittedSewadalFormsDetailsById,
  SubmitSewadalFormsDetails,
} from 'src/api/sewadalFormApi';
import {
  DB_GENDER,
  FormType,
  SEWADAL_TYPE,
} from 'src/Common/AppEnum';
import {
  SewadalDataModel,
  SewadalDataModelList,
} from 'src/Common/Models/Sewadal/SewadalModel';
import {
  SewadalFormsDetail,
  SewadalFormsDetailResponse,
  SubmitSD1Form,
} from 'src/Common/Models/SewadalForms/SewadalFormsModel';
import { RootState } from 'src/redux/store';

import {
  faEdit,
  faPlus,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type FetchMultipleAPIsData = {
  allUnregisterSewadalList: SewadalDataModelList;
  defaultSD1FormData: SewadalFormsDetailResponse;
};


const Sd1EnrolmentForm: React.FC = () => {

	const location = useLocation();
	const { state } = location;
	const [createForm, setCreateForm] = useState<boolean>(true);
	const formId = state?.formId;

	const { user } = useSelector((state: RootState) => state.auth);
	const [sewadalData, setSewadalData] = useState<FetchMultipleAPIsData>(
		{} as FetchMultipleAPIsData
	);
	const [showModal, setShowModal] = useState(false);
	const [currentRow, setCurrentRow] = useState<any>(null);
	const [isEditMode, setIsEditMode] = useState(false);
	const [formData, setFormData] = useState<SewadalFormsDetail>(
		{} as SewadalFormsDetail
	);


	const addRow = () => {
		setIsEditMode(false);
		setCurrentRow({});
		setShowModal(true);
	};

	const deleteRow = (index: number) => {
		setSelectedRows(selectedRows.splice(index,1));
	};

	const openModal = (index?: number) => {
		if (index !== undefined) {
			setIsEditMode(true);
			setCurrentRow(selectedRows[index]);
		} else {
			setIsEditMode(false);
			setCurrentRow(null);
		}
		setShowModal(true);
	};

	const closeModal = () => setShowModal(false);

	const saveRow = () => {
		if (isEditMode && currentRow) {
			const updatedTableData = [...selectedRows];
			const index = selectedRows.findIndex((row) => row.name === currentRow.name);
			updatedTableData[index] = currentRow;
			setSelectedRows(updatedTableData);
		} else {
			setSelectedRows([...selectedRows, currentRow]);
			updateData(false);
		}
		setShowModal(false);
	};

	const updateData = (isDelete: boolean) => {
		if (!isDelete) {
			setSewadalData(sewadalData);
			if (currentRow.gender === DB_GENDER.MALE) {
				formData.enrolledSewadalCountGents += 1;
			} else {
				formData.enrolledSewadalCountLadies += 1;
			}
			setFormData(formData);
		} else {
			setSewadalData(sewadalData);
			if (currentRow.gender === DB_GENDER.MALE) {
				formData.enrolledSewadalCountGents -= 1;
			} else {
				formData.enrolledSewadalCountLadies -= 1;
			}
			setFormData(formData);
		}
	};

	const useSubmitForm = () => {
		const submitForm = async (data: SubmitSD1Form) => {
			try {
				const response = await SubmitSewadalFormsDetails(data);
				return response
					? 'Form submitted successfully'
					: 'Error submitting form';
			} catch (error) {
				console.error('Submission error:', error);
				return 'Error submitting form';
			}
		};
		return { submitForm };
	};

	const { submitForm } = useSubmitForm();

	const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		const data: SubmitSD1Form = {
			type: FormType.SD1,
			unitNumber: formData.unitNumber,
			weeklySatsangGentsCount: parseInt(
				formData.weeklySatsangGentsCount as unknown as string
			),
			weeklySatsangLadiesCount: parseInt(
				formData.weeklySatsangLadiesCount as unknown as string
			),
			district: formData.district,
			enrolledSewadalCountGents: formData.enrolledSewadalCountGents,
			enrolledSewadalCountLadies: formData.enrolledSewadalCountLadies,
			sD1Details: selectedRows.map((a: SewadalDataModel) => ({
				sewadalId: parseInt(a.id as unknown as string),
				note: a.remarks,
			})),
		};
		const formStatus = await submitForm(data);
		alert(formStatus);
	};

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { id, value } = e.target;
		setFormData((prevData: any) => ({
			...prevData,
			[id]: value,
		}));
	};

	const [selectedRows, setSelectedRows] = useState<SewadalDataModel[]>([]);

	const toggleRowSelection = (sewadal:SewadalDataModel) => {
		const isSelected = selectedRows.find(row => row.id === sewadal.id);
		if (isSelected) {
			setSelectedRows(selectedRows.filter(row => row.id !== sewadal.id));
		} else {
			setSelectedRows([...selectedRows, sewadal]);
		}
	};

	const fetchMultipleAPIs = useCallback(async () => {
		try {
			const params = {
				unitNo: user?.unitNo || 0,
				page: 0,
				pageSize: 0,
				searchTerm: '',
				type: SEWADAL_TYPE.UR_SEWADAL,
			};

			const [allUnregisterSewadalList, defaultSD1FormData] = await Promise.all([
				GetSewadalApi(params),
				GetSewadalFormsDetails(user?.unitNo || 0, FormType.SD1),
			]);

			return { allUnregisterSewadalList, defaultSD1FormData };
		} catch (error) {
			console.error('Error fetching APIs:', error);
			throw error;
		}
	}, [user]);

	const fetchSubmittedFormDetails =useCallback(async () => {
		try {
			const submittedFormResponse = await GetSubmittedSewadalFormsDetailsById(formId);
			const submittedSewadalDetails = submittedFormResponse.sewadalDetails;
			setSelectedRows(submittedSewadalDetails);
			setFormData({
				unitNumber: submittedFormResponse.unitNo,
				branchName: submittedFormResponse.branch,
				district: submittedFormResponse.district || '',
				state: submittedFormResponse.tahsil,
				kshetraName: submittedFormResponse.kshetra,
				zoneName: submittedFormResponse.zone,
				weeklySatsangGentsCount: submittedFormResponse.satsangCount.gents,
				weeklySatsangLadiesCount: submittedFormResponse.satsangCount.ladies,
				registeredSewadalCountGents:
					submittedFormResponse.currentSewadalCount.gents,
				registeredSewadalCountLadies:
					submittedFormResponse.currentSewadalCount.gents,
				enrolledSewadalCountGents:
					submittedFormResponse.proposedSewadalCount.gents,
				enrolledSewadalCountLadies:
					submittedFormResponse.proposedSewadalCount.ladies,
				sD1Details: submittedSewadalDetails,
			});			
		} catch (error) {
			console.error('Error fetching APIs:', error);
			throw error;
		}
	},[formId])

	useEffect(() => {
		const fetchData = async () => {
			try {
				const result = await fetchMultipleAPIs();
				setSewadalData(result);
				const data = result.defaultSD1FormData;
				setFormData({
					unitNumber: data.unitNumber,
					branchName: data.branchName,
					district: data?.district || '',
					state: data?.state,
					kshetraName: data?.kshetraName,
					zoneName: data?.zoneName,
					weeklySatsangGentsCount: 0,
					weeklySatsangLadiesCount: 0,
					registeredSewadalCountGents: 0,
					registeredSewadalCountLadies: 0,
					enrolledSewadalCountGents: 0,
					enrolledSewadalCountLadies: 0,
					sD1Details: [],
				});
			} catch (error) {
				console.error('Error fetching data:', error);
			}
		};
		if (createForm && !formId) {
			fetchData();
			setSelectedRows([]);
			setFormData({} as SewadalFormsDetail);
		} else {
			if(formId && !createForm)
			fetchSubmittedFormDetails();
		}
	}, [fetchMultipleAPIs, fetchSubmittedFormDetails, createForm, formId]);
	
	useEffect(() => {
		if(state){
			setCreateForm(state.createForm)
		}else{
			setCreateForm(true);
		}
	},[state])

	return (
		<div className="container-fluid mt-5">
			<div className="row justify-content-center mt-3">
				<div className="col-12 col-lg-12">
					<div className="card shadow-sm">
						<div className="card-body">
							<h4 className="text-center mb-4">
								<b>SD-1 Enrolment Form</b>
							</h4>
							<p className="text-center text-muted">
								Please fill out the form below to complete your SD-1 enrolment.
							</p>

							<form onSubmit={handleSubmit}>
								{/* Form Content */}
								<div className="row">
									{/* Section 1: Unit Details */}
									<div className="col-md-3">
										<div className="form-group">
											<label htmlFor="unitNumber">Unit Number</label>
											<input
												type="text"
												className="form-control"
												id="unitNumber"
												value={formData.unitNumber}
												disabled
											/>
										</div>
										<div className="form-group">
											<label htmlFor="branch">Branch</label>
											<input
												type="text"
												className="form-control"
												id="branch"
												value={formData.branchName}
												disabled
											/>
										</div>
										<div className="form-group">
											<label htmlFor="state">Kshetra</label>
											<input
												type="text"
												className="form-control"
												id="state"
												value={formData.kshetraName}
												disabled
											/>
										</div>
									</div>

									{/* Section 2: Location Details */}
									<div className="col-md-3">
										<div className="form-group">
											<label htmlFor="state">State</label>
											<input
												type="text"
												className="form-control"
												id="state"
												value={formData.state}
												disabled
											/>
										</div>
										<div className="form-group">
											<label htmlFor="district">District</label>
											<input
												type="text"
												className="form-control"
												id="district"
												disabled={!createForm}
												onChange={handleInputChange}
												value={formData.district}
											/>
										</div>
										<div className="form-group">
											<label htmlFor="district">Zone</label>
											<input
												type="text"
												className="form-control"
												id="district"
												value={formData.zoneName}
												disabled
											/>
										</div>
									</div>

									{/* Section 3: Satsang and Enrolment Counts */}
									<div className="col-md-3">
										<div className="form-group">
											<label htmlFor="weeklySatsangFemale">
												Weekly Satsang Count (Female)
											</label>
											<input
												type="number"
												className="form-control"
												id="weeklySatsangLadiesCount"
												value={formData?.weeklySatsangLadiesCount}
												onChange={handleInputChange}
												disabled={!createForm}
												placeholder="Enter female count"
											/>
										</div>
										<div className="form-group">
											<label htmlFor="weeklySatsangFemale">
												Total Sewadal Count (Female)
											</label>
											<input
												type="number"
												className="form-control"
												id="registeredSewadalCountLadies"
												value={formData?.registeredSewadalCountLadies}
												onChange={handleInputChange}
												disabled={!createForm}
												placeholder="Enter female count"
											/>
										</div>
										<div className="form-group">
											<label htmlFor="enrolmentFemale">
												Enrolment Count (Female)
											</label>
											<input
												type="number"
												className="form-control"
												id="enrolledSewadalCountLadies"
												disabled
												value={formData?.enrolledSewadalCountLadies}
												placeholder="Enter female enrolment count"
											/>
										</div>
									</div>

									<div className="col-md-3">
										<div className="form-group">
											<label htmlFor="weeklySatsangMale">
												Weekly Satsang Count (Male)
											</label>
											<input
												type="number"
												className="form-control"
												id="weeklySatsangGentsCount"
												value={formData?.weeklySatsangGentsCount}
												disabled={!createForm}
												onChange={handleInputChange}
												placeholder="Enter male count"
											/>
										</div>
										<div className="form-group">
											<label htmlFor="enrolmentMale">
												Total Sewadal Count (Male)
											</label>
											<input
												type="number"
												className="form-control"
												id="registeredSewadalCountGents"
												value={formData?.registeredSewadalCountGents}
												onChange={handleInputChange}
												disabled={!createForm}
												placeholder="Enter male present count"
											/>
										</div>
										<div className="form-group">
											<label htmlFor="enrolmentMale">
												Enrolment Count (Male)
											</label>
											<input
												type="number"
												className="form-control"
												id="enrolledSewadalCountGents"
												disabled
												value={formData?.enrolledSewadalCountGents}
												placeholder="Enter male enrolment count"
											/>
										</div>
									</div>
								</div>

								{/* Table Section */}
								<div className="table-responsive mt-5">
									<table className="table table-bordered table-striped">
										<thead className="table-primary">
											<tr>
												<th>Personal Number</th>
												<th>Start Date of Sewa</th>
												<th>Name</th>
												<th>Father/Husband's Name</th>
												<th>Date of Birth</th>
												<th>Blood Group</th>
												<th>Qualification</th>
												<th>Occupation</th>
												<th>Address</th>
												<th>Mobile Number</th>
												<th>Note</th>
												{createForm && <th>Actions</th>}
											</tr>
										</thead>
										<tbody>
											{selectedRows && selectedRows.length > 0 ? (
												selectedRows.map((row, index) => (
													<tr key={index}>
														<td>{row.personalNumber}</td>
														<td>{row.dateOfAdd}</td>
														<td>{row.name}</td>
														<td>{row.fathersName}</td>
														<td>{row.dateOfBirth}</td>
														<td>{row.bloodGroup}</td>
														<td>{row.qualification}</td>
														<td>{row.occupation}</td>
														<td>{row.address}</td>
														<td>{row.contactNo}</td>
														<td>{row.remarks}</td>
														{createForm && (
															<td>
																<button
																	className="btn btn-outline-primary btn-sm mx-1"
																	type="button"
																	onClick={(e) => {
																		e.stopPropagation();
																		openModal(index);
																	}}>
																	<FontAwesomeIcon icon={faEdit} />
																</button>
																<button
																	className="btn btn-outline-danger btn-sm"
																	type="button"
																	onClick={(e) => {
																		e.stopPropagation();
																		deleteRow(index);
																	}}>
																	<FontAwesomeIcon icon={faTrash} />
																</button>
															</td>
														)}
													</tr>
												))
											) : (
												<tr>
													<td
														colSpan={createForm ? 11 : 12}
														className="text-center">
														No data available
													</td>
												</tr>
											)}
										</tbody>
									</table>

									{/* Conditionally show Add New Row button */}
									{createForm && (
										<button
											className="btn btn-outline-success mt-3"
											type="button"
											onClick={addRow}>
											<FontAwesomeIcon icon={faPlus} /> Add New Row
										</button>
									)}
								</div>

								{/* Conditionally show Submit Button */}
								{createForm && (
									<div className="d-flex justify-content-center mt-4">
										<button
											type="submit"
											className="btn btn-primary">
											Submit
										</button>
									</div>
								)}
							</form>

							{/* Modal Code remains the same */}
							{/* Modal for adding/editing table rows */}
							<Modal
								show={showModal}
								onHide={closeModal}
								size="lg"
								centered>
								<Modal.Header closeButton>
									<Modal.Title>
										{isEditMode ? 'Edit Row' : 'Add New Row'}
									</Modal.Title>
								</Modal.Header>
								<Modal.Body>
									{!isEditMode && (
										<table className="table">
											<thead>
												<tr>
													<th>Select</th>
													<th>Name</th>
													<th>Father/Husband's Name</th>
													<th>Date of Birth</th>
													<th>Blood Group</th>
												</tr>
											</thead>
											<tbody>
												{sewadalData?.allUnregisterSewadalList?.sewadals.map(
													(sewadal) => (
														<tr key={sewadal.id}>
															<td>
																<input
																	type="checkbox"
																	checked={
																		!!selectedRows.find(
																			(row) => row.id === sewadal.id
																		)
																	}
																	onChange={() => {
																		toggleRowSelection(sewadal);
																		setShowModal(true);
																	}}
																/>
															</td>
															<td>{sewadal.name}</td>
															<td>{sewadal.fathersName}</td>
															<td>
																{new Date(
																	sewadal.dateOfBirth
																).toLocaleDateString()}
															</td>
															<td>{sewadal.bloodGroup}</td>
														</tr>
													)
												)}
											</tbody>
										</table>
									)}
									{isEditMode && (
										<form>
											<div className="container-fluid">
												<div className="row">
													{/* First Column */}
													<div className="col-md-6">
														<div className="form-group mb-3">
															<label>Name</label>
															<select
																className="form-control"
																value={currentRow?.id || ''}
																onChange={(e) => {
																	const selectedId = e.target.value;
																	const selectedSewadal =
																		sewadalData?.allUnregisterSewadalList?.sewadals.find(
																			(sewadal) =>
																				sewadal.id.toString() === selectedId
																		);
																	if (selectedSewadal) {
																		setCurrentRow({
																			...selectedSewadal,
																			id: selectedId,
																		});
																	}
																}}>
																<option value="">Select Name</option>
																{sewadalData?.allUnregisterSewadalList?.sewadals.map(
																	(sewadal) => (
																		<option
																			disabled={sewadal.disabled}
																			key={sewadal.id}
																			value={sewadal.id}>
																			{sewadal.name}
																		</option>
																	)
																)}
															</select>
														</div>
														<div className="form-group mb-3">
															<label>Father/Husband's Name</label>
															<input
																type="text"
																className="form-control"
																value={currentRow?.fathersName || ''}
																onChange={(e) =>
																	setCurrentRow({
																		...currentRow,
																		fathersName: e.target.value,
																	})
																}
															/>
														</div>
														<div className="form-group mb-3">
															<label>Start Date of Sewa</label>
															<input
																type="date"
																className="form-control"
																value={
																	currentRow?.dateOfAdd
																		? new Date(currentRow.dateOfAdd)
																				.toISOString()
																				.split('T')[0]
																		: ''
																}
																onChange={(e) =>
																	setCurrentRow({
																		...currentRow,
																		dateOfAdd: new Date(
																			e.target.value
																		).toISOString(),
																	})
																}
															/>
														</div>
														<div className="form-group mb-3">
															<label>Date of Birth</label>
															<input
																type="date"
																className="form-control"
																value={
																	currentRow?.dateOfBirth
																		? new Date(currentRow.dateOfBirth)
																				.toISOString()
																				.split('T')[0]
																		: ''
																}
																onChange={(e) =>
																	setCurrentRow({
																		...currentRow,
																		dateOfBirth: new Date(
																			e.target.value
																		).toISOString(),
																	})
																}
															/>
														</div>
													</div>

													{/* Second Column */}
													<div className="col-md-6">
														<div className="form-group mb-3">
															<label>Blood Group</label>
															<input
																type="text"
																className="form-control"
																value={currentRow?.bloodGroup || ''}
																onChange={(e) =>
																	setCurrentRow({
																		...currentRow,
																		bloodGroup: e.target.value,
																	})
																}
															/>
														</div>
														<div className="form-group mb-3">
															<label>Qualification</label>
															<input
																type="text"
																className="form-control"
																value={currentRow?.qualification || ''}
																onChange={(e) =>
																	setCurrentRow({
																		...currentRow,
																		qualification: e.target.value,
																	})
																}
															/>
														</div>
														<div className="form-group mb-3">
															<label>Occupation</label>
															<input
																type="text"
																className="form-control"
																value={currentRow?.occupation || ''}
																onChange={(e) =>
																	setCurrentRow({
																		...currentRow,
																		occupation: e.target.value,
																	})
																}
															/>
														</div>
														<div className="form-group mb-3">
															<label>Mobile Number</label>
															<input
																type="text"
																className="form-control"
																value={currentRow?.contactNo || ''}
																onChange={(e) =>
																	setCurrentRow({
																		...currentRow,
																		contactNo: e.target.value,
																	})
																}
															/>
														</div>
													</div>

													{/* Third Column */}
													<div className="col-md-6">
														<div className="form-group mb-3">
															<label>Address</label>
															<textarea
																className="form-control"
																value={currentRow?.address || ''}
																onChange={(e) =>
																	setCurrentRow({
																		...currentRow,
																		address: e.target.value,
																	})
																}></textarea>
														</div>
														<div className="form-group mb-3">
															<label>Personal Number</label>
															<input
																type="text"
																className="form-control"
																value={currentRow?.personalNumber || ''}
																onChange={(e) =>
																	setCurrentRow({
																		...currentRow,
																		personalNumber: e.target.value,
																	})
																}
															/>
														</div>
														<div className="form-group mb-3">
															<label>Note</label>
															<textarea
																className="form-control"
																value={currentRow?.remarks || ''}
																onChange={(e) =>
																	setCurrentRow({
																		...currentRow,
																		remarks: e.target.value,
																	})
																}></textarea>
														</div>
													</div>
												</div>
											</div>
										</form>
									)}
								</Modal.Body>
								<Modal.Footer>
									<Button
										variant="secondary"
										onClick={closeModal}>
										Close
									</Button>
									<Button
										variant="primary"
										onClick={saveRow}>
										Save Changes
									</Button>
								</Modal.Footer>
							</Modal>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Sd1EnrolmentForm;
