//AddUnit.tsx
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import 'bootstrap/dist/css/bootstrap.min.css';
import "./AddUnit.css"
import { useNavigate } from "react-router-dom";
import { AddUnitApi, GetUnitByIdApi } from "src/api/unitApi";
import { useMutation } from "@tanstack/react-query";
import { AddUnitData, UnitDataModel } from "src/Common/Models/Unit/UnitDataModel";
import SewadalForm from "../AddSewadalDetails/SewadalForm";
import { AddSewadalFormModel } from "src/Common/Models/Sewadal/SewadalModel";
import { SEWADAL_TYPE } from "src/Common/AppEnum";
import { SectorMasterData } from "src/Common/AppConstant";

const AddUnit: React.FC = () => {
  const navigate = useNavigate(); // Hook for navigation
  const [unitNo, setUnitNo] = useState<string>('');
  const [branchName, setBranchName] = useState<string>('');
  const [sectorName, setSectorName] = useState<number>(1);
  const [formNo, setFormNo] = useState<number>(0);
  const [isUnitNoTaken, setIsUnitNoTaken] = useState<boolean>(false);
  const [formData, setFormData] = useState<AddSewadalFormModel>(new AddSewadalFormModel(SEWADAL_TYPE.SANCHALAK));

  const postMutation = useMutation<void, Error, AddUnitData>({
    mutationFn: AddUnitApi,
    onSuccess: () => {
      navigate('/units');
    },
    onError: (error: any) => {
      console.error('Api failed:', error.response?.data || error.message);
    },
  });

  const getMutation = useMutation<UnitDataModel, Error, number>({
    mutationFn: GetUnitByIdApi,
    onSuccess: (data: UnitDataModel) => {
      if (data) {
        setIsUnitNoTaken(true);
      } else {
        setIsUnitNoTaken(false);
      }
    },
    onError: (error: any) => {
      console.error('Api failed:', error.response?.data || error.message);
    },
  });

  useEffect(() => {
    if (unitNo.trim().length)
      getMutation.mutate(Number(unitNo));
  }, [unitNo]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { id, value } = e.target;
    setFormData({
      ...formData,
      [id]: value, // Dynamically update form fields
    });
  };

  // Handle form submission
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault(); // Prevent default form submission behavior
    if (formNo === 0) {
      setFormNo(1);
      return;
    }
    return;
  }

  const handleSewadalFormSubmit = (e: React.FormEvent, formData: AddSewadalFormModel) => {
    e.preventDefault(); // Prevent default form submission behavior
    formData.unitNumber = Number(unitNo);
    // if (formData?.groupNo === "") {
    //   // delete the groupNo field from formData
    //   delete formData?.groupNo;
    // }
    const newUnitData: AddUnitData = {
      name: branchName,
      number: Number(unitNo),
      sectorNumber: sectorName,
      sewadalData: formData
    };

    postMutation.mutate(newUnitData);
  };

  const navigateBack = () => {
    if (formNo === 1) { 
      console.log("formNo value resetting")
      setFormNo(() => 0);
    }
    else {
      navigate('/unit/add');
    }
  }

  return (
    <div className="container-fluid mt-5">
      {/* <div className="routes "> Entry / <b> Add / Create Unit {formNo} </b> </div> */}

      <div className="row justify-content-center mt-3">
        <div className="col-12 col-lg-12">
          <div className="card shadow">
            <div className="card-body p-4">
              <legend>
                <b> {formNo ? 'Add Adhikari' : 'Create Unit'}</b>
              </legend>
              {formNo === 0 ? (
                <>
                  <form onSubmit={handleSubmit}>

                    <div className="row">
                      <div className="col-md-6 mt-2">
                        <div className="mandatory-text">Fields marked with * are mandatory</div>
                        <label htmlFor="kshetraName" className="form-label">
                          Kshetra Name <div className="astric"> * </div>
                        </label>
                        <input
                          type="text"
                          id="kshetraName"
                          className="form-control byDefaultInput "
                          placeholder="Mumbai III"
                          disabled
                        />
                      </div>

                      <div className="col-md-6 mt-4">
                        <label htmlFor="unitNo" className="form-label">
                          Unit No. <div className="astric"> * </div>
                        </label>
                        <input
                          type="number"
                          className="form-control "
                          id="unitNo"
                          value={unitNo}
                          onChange={(e) => setUnitNo(e.target.value)}
                          required
                        />
                        {isUnitNoTaken && (
                          <b className="error-help-text" >Unit No already exists. </b>
                        )}
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6 mt-4">
                        <label htmlFor="branchName" className="form-label">
                          Branch Name <div className="astric"> * </div>
                        </label>
                        <input
                          type="text"
                          className="form-control "
                          id="branchName"
                          value={branchName}
                          onChange={(e) => setBranchName(e.target.value)}
                          required
                        />
                      </div>

                      <div className="col-md-6 mt-4">
                        <label htmlFor="sectorName" className="form-label">
                          Sector Name <div className="astric"> * </div>
                        </label>
                        <select
                          id="sectorName"
                          className="form-select "
                          value={sectorName}
                          onChange={(e) => setSectorName(Number(e.target.value))}
                          required
                        >
                          <option value={0}>Select Sector</option>
                          {SectorMasterData.map(sector => (
                            <option value={sector.value}> {sector.name} </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    <div className="col-md-6 mt-4">
                      <label htmlFor="zone" className="form-label">
                        Zone <div className="astric"> * </div>
                      </label>
                      <input
                        type="text"
                        id="zone"
                        className="form-control byDefaultInput "
                        placeholder="39"
                        disabled
                      />
                    </div>

                    <div className="d-flex justify-content-between align-items-center border-top pt-3 mt-4">
                      <button className="btn" onClick={navigateBack}>
                        <FontAwesomeIcon icon={faChevronLeft} /> Back
                      </button>
                      <button type="submit" className="btn btn-primary" disabled={isUnitNoTaken} >
                        {formNo ? 'Submit' : 'Next'}
                      </button>
                    </div>
                  </form>
                </>
              ) : (
                <SewadalForm handleFormSubmit={handleSewadalFormSubmit} handleBackClick={navigateBack} fromUnit={true} handleChange={handleChange} formData={formData} unitNo={Number(unitNo)} />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddUnit;