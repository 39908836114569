import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import './index.css';
import { formatDate } from "src/utils/utils";
import { FILTER_INPUT_TYPE, FILTER_LISTING_TYPE, USER_TYPE } from "src/Common/AppEnum";
import { AttendanceFilterModel, FilterField, KeyValueObject } from "src/Common/Models/Filter/FilterModel";
import { GetFilterApi } from "src/api/filterApi";
import { useMutation } from "@tanstack/react-query";
import { useAttendanceFilterFields, useReportsFilterFields } from "src/Common/FilterConstant";
import { useSelector } from "react-redux";
import { RootState } from "src/redux/store";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";



interface FilterProps {
    onlySearch: boolean;
    filterType: FILTER_LISTING_TYPE,
    onApply?: (appliedFilters: { [key: string]: string | number }) => void;
    onReset?: () => void;
    searchPlaceholder: string;
    onSearch: (searchTerm: string) => void;
    initialValues?: { [key: string]: string | number };
    canExport?: boolean;
    handleExport?: () => void;
    resetSearchBar?: boolean;
}

const Filter: React.FC<FilterProps> = ({ onlySearch, filterType, onApply, onReset, searchPlaceholder, onSearch, initialValues, canExport = false, handleExport, resetSearchBar }) => {
    const { t } = useTranslation();
    // Set initial filter data
    const { isAuthenticated, user } = useSelector((state: RootState) => state.auth);
    const [filterFields, setFilterFields] = useState<FilterField[]>([]);
    const [filterData, setFilterData] = useState<{ [key: string]: string | number }>();
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [tempFilterData, setTempFilterData] = useState<FilterField[]>([]);
    const attendanceFilterFields = useAttendanceFilterFields();
    // const reportsFilterFields = useReportsFilterFields(user!.userType!);


    const initialFilterData = (fields: FilterField[]) => {
        const data: { [key: string]: string | number } = {};
        fields.forEach(field => {
            if (field.type === 'date') {
                // data[field.key] = initialValues && initialValues[field.key]
                //     ? initialValues[field.key]
                //     : formatDate(new Date()); // Set today's date if no initial values
                if (field.dateType === 'month') {
                    // If dateType is 'month', format date as 'YYYY-MM'
                    data[field.key] = initialValues && initialValues[field.key]
                        ? initialValues[field.key]
                        : formatInitialDate(new Date(), "YYYY-MM"); // Set today's month as default
                } else {
                    // If dateType is 'date', format date as 'YYYY-MM-DD'
                    data[field.key] = initialValues && initialValues[field.key]
                        ? initialValues[field.key]
                        : formatInitialDate(new Date(), "YYYY-MM-DD"); // Set today's date as default
                }
            } else if (initialValues && initialValues[field.key] !== undefined) {
                data[field.key] = initialValues[field.key]; // Use provided initial values for non-date fields
            } else {
                data[field.key] = ''; // Default empty string for other fields
            }
        });
        return data;
    };

    const formatInitialDate = (date: Date, format: "YYYY-MM" | "YYYY-MM-DD" = "YYYY-MM-DD"): string => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');

        if (format === "YYYY-MM") {
            return `${year}-${month}`; // Format for months
        } else {
            return `${year}-${month}-${day}`; // Format for full dates
        }
    };

    const getFilterDataMutation = useMutation<AttendanceFilterModel>({
        mutationFn: () => GetFilterApi({ filterType: filterType }),
        onSuccess: (data: any) => {
            const fields: FilterField[] = getFilterFieldsByListingType(data);

            setFilterFields([...fields]);
            setTempFilterData([...fields]);
            setFilterData(initialFilterData(fields))
        },
        onError: (error: any) => {
            console.error("Api failed:", error.response?.data || error.message);
        }
    });

    useEffect(() => {
        getFilterDataMutation.mutate()
    }, []);

    const getFilterFieldsByListingType = (data: any): FilterField[] => {
        let fields: FilterField[] = [];
        switch (filterType) {
            case FILTER_LISTING_TYPE.ATTENDANCE:
                fields = attendanceFilterFields;
                fields[1].options = data?.satsangs;
                fields[2].options = data?.satsangTypes;
                break;

            // case FILTER_LISTING_TYPE.REPORTS:
            //     fields = reportsFilterFields;
            //     fields[0].options = data?.units;
            //     break;
        }
        return fields;
    }

    // Function to calculate the difference in months between two dates formatted as 'YYYY-MM'
    const getMonthDifference = (startMonth: string, endMonth: string): number => {
        const [startYear, startMonthNum] = startMonth.split('-').map(Number);
        const [endYear, endMonthNum] = endMonth.split('-').map(Number);

        return (endYear - startYear) * 12 + (endMonthNum - startMonthNum);
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>, key: FILTER_INPUT_TYPE, fieldType: string, dependentIndex: number) => {
        const value = e.target.value;
        const updatedFilterData: { [key: string]: string | number } = { ...filterData, [key]: value };
        if (key === FILTER_INPUT_TYPE.START_MONTH || key === FILTER_INPUT_TYPE.END_MONTH) {
            const startMonth = updatedFilterData[FILTER_INPUT_TYPE.START_MONTH];
            const endMonth = updatedFilterData[FILTER_INPUT_TYPE.END_MONTH];

            if (startMonth && endMonth) {
                const monthDifference = getMonthDifference(startMonth as string, endMonth as string);

                if (monthDifference > 3) {
                    alert(t('error-date-range-exceed'));
                    return;
                }
            }
        }

        if (dependentIndex > -1) {
            let fieldsTemp = JSON.parse(JSON.stringify(filterFields));
            let tempData = JSON.parse(JSON.stringify(tempFilterData));
            const filterSelectionOptions = tempData[dependentIndex].options?.filter(
                (option: KeyValueObject) => option.parentKey === Number(value)
            );

            fieldsTemp[dependentIndex].options = filterSelectionOptions;
            setFilterFields([...fieldsTemp]);
        }
        setFilterData({ ...filterData, [key]: value });
    };

    const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const term = e.target.value;
        setSearchTerm(term);
        onSearch(term);  // Emit the search term to the parent component
    };

    const resetFilter = () => {
        setFilterData(initialFilterData(filterFields));
        onReset!();
    };

    const applyFilter = () => {
        onApply!(filterData!);
    };

    const isFilterComplete = (): boolean => {
        return filterFields.filter(field => field.required).every(field => (filterData![field.key] !== undefined && filterData![field.key] !== ""));
    };

    const getMinDate = (): string => {
        const today = new Date();
        if (filterType === FILTER_LISTING_TYPE.ATTENDANCE) {
            const sevenDaysAgo = new Date();
            sevenDaysAgo.setDate(today.getDate() - 7);
            return formatDate(sevenDaysAgo);
        }
        else {
            const oneYearBack = new Date(today);
            oneYearBack.setFullYear(today.getFullYear() - 1);
            return formatDate(oneYearBack);
        }
    }

    const getMaxDate = () => {
        const today = new Date();
        return formatDate(today);
    }

    const getMinMonthDate = (): string => {
        const today = new Date();
        const oneYearBack = new Date(today);
        oneYearBack.setFullYear(today.getFullYear() - 1);
        return formatInitialDate(oneYearBack, "YYYY-MM");
    };

    const getMaxMonthDate = (): string => {
        const today = new Date();
        return formatInitialDate(today, "YYYY-MM");
    };

    const exportData = () => {
        if (handleExport)
            handleExport();
    }

    const clearSearch = () => {
        setSearchTerm(''); // Clear the search term
        onSearch('');
    };

    useEffect(() => {
        setSearchTerm('');
      }, [resetSearchBar]);

    return (
        <div>
            <div className="row">
                {!onlySearch && (
                    <div className="col-md-3">
                        <div className="accordion" id="accordionExample">
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingOne">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                        {t('filters-label')}
                                    </button>
                                </h2>
                            </div>
                        </div>
                    </div>
                )}
                <div className={onlySearch ? (canExport ? "col-md-11 mt-2" : "col-md-12 mt-2") : (canExport ? "col-md-8" : "col-md-9")}>
                    <div className="input-group mb-3 mblmargin">
                        <input
                            type="text"
                            className="form-control"
                            placeholder={searchPlaceholder}
                            aria-label="Search"
                            value={searchTerm}
                            onChange={handleSearchInputChange}
                            aria-describedby="basic-addon1"
                        />
                        {searchTerm && ( // Show only if there is a search term
                            <div className="input-group-append">
                                <button
                                    className="btn btn-outline-secondary icon-button"
                                    type="button"
                                    onClick={clearSearch}
                                    id="search-clear-button"
                                >
                                    <FontAwesomeIcon icon={faTimes} color='#3a69ff' />
                                </button>
                            </div>
                        )}
                    </div>
                </div>
                {canExport && (
                    <>
                        <div className="col-md-1">
                            <Button onClick={exportData} disabled={!handleExport}> Export</Button>
                        </div>
                    </>
                )}
            </div>
            {!onlySearch && (
                <div id="collapseOne" className="accordion-collapse collapse " aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                        <div className="row">
                            {/* Render dynamic filter fields */}
                            {filterFields.map((field, index) => (
                                <div key={index} className="col-md-4">
                                    <label>{field.label}</label>
                                    <div className="input-group mb-3">
                                        {field.type === "select" && field.options ? (
                                            <select
                                                className="form-select"
                                                value={filterData![field.key] || ""}
                                                onChange={(e) => handleInputChange(e, field.key, "select", field.dependentIndex)}
                                            >
                                                <option value="">{field.placeholder}</option>
                                                {field.options.map((option) => (
                                                    <option key={option.key} value={option.key}>
                                                        {option.value}
                                                    </option>
                                                ))}
                                            </select>
                                        ) : field.type === "date" ? (
                                            <input
                                                type={field.dateType}
                                                className="form-control"
                                                value={filterData![field.key] || formatDate(new Date())} // Set today's date as default
                                                onChange={(e) => handleInputChange(e, field.key, "date", field.dependentIndex)}
                                                max={field.dateType === 'date' ? getMaxDate() : getMaxMonthDate()} // Today's date as the max date
                                                min={field.dateType === 'date' ? getMinDate() : getMinMonthDate()} // 7 days ago as the min date
                                            />
                                        ) : field.type === "text" && (
                                            <input
                                                type={field.type}
                                                className="form-control"
                                                placeholder={field.placeholder}
                                                value={filterData![field.key] || ""}
                                                onChange={(e) => handleInputChange(e, field.key, "text", field.dependentIndex)}
                                            />
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="d-flex j-end me-2">
                            <div className="row">
                                <div className="col-5">
                                    <button type="button" className="btn btn-secondary action-btn" onClick={resetFilter}>
                                        {t('reset-btn-label')}
                                    </button>
                                </div>
                                <div className="col-5">
                                    <button type="button" className="btn btn-primary action-btn" disabled={!isFilterComplete()} onClick={applyFilter}>
                                        {t('apply-btn-label')}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Filter;
