import React, { useEffect, useState } from 'react';
import "./index.css";
import Filter from '../Filter';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';
import { faSort, faSortUp, faSortDown, faSync } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FILTER_LISTING_TYPE } from 'src/Common/AppEnum';
import { GetGroupApi } from 'src/api/groupApi';
import { GroupModel } from 'src/Common/Models/Group/GroupModel';
import { Button } from 'react-bootstrap';

const Group = () => {

    const { t } = useTranslation();

    const [searchTerm, setSearchTerm] = useState<string>("");
    const [sortConfig, setSortConfig] = useState<{ key: string; direction: 'ascending' | 'descending' | '' }>({
        key: 'adhikariName',
        direction: 'ascending',
    });
    const [groupData, setGroupData] = useState<GroupModel[]>([]);
    const [filteredData, setFilteredData] = useState<GroupModel[]>([]);

    const escapeRegExp = (value: string) => {
        return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&')
    }

    useEffect(() => {
        getMutation.mutate();
    }, [])

    const getMutation = useMutation({
        mutationFn: GetGroupApi,
        onSuccess: (data: GroupModel[]) => {
            setGroupData(data);
        },
        onError: (error: any) => {
            console.error("Api failed:", error.response?.data || error.message);
        }
    });

    const handleSearch = (searchTerm: string) => {
        setSearchTerm(searchTerm)

        const searchRegex = new RegExp(escapeRegExp(searchTerm), 'i')

        const filteredRows = groupData.filter(row => {
            return Object.keys(row).some(field => {

                // @ts-ignore

                return searchRegex.test(row[field]?.toString());
            })
        })
        if (searchTerm.length > 0) {
            setFilteredData(filteredRows)
        } else {
            setFilteredData([])
        }
    }

    const handleSort = (key: string) => {
        let direction: 'ascending' | 'descending' = 'ascending';

        // Toggle sorting direction
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }

        // Update the sorting configuration
        setSortConfig({ key, direction });

        // Sorting logic for both 'data' and 'filteredData'
        const sortFunction = (a: GroupModel, b: GroupModel) => {
            if (a[key as keyof GroupModel]! < b[key as keyof GroupModel]!) {
                return direction === 'ascending' ? -1 : 1;
            }
            if (a[key as keyof GroupModel]! > b[key as keyof GroupModel]!) {
                return direction === 'ascending' ? 1 : -1;
            }
            return 0;
        };

        // Sort the main data and the filtered data
        const sortedData = [...groupData].sort(sortFunction);
        const sortedFilteredData = [...filteredData].sort(sortFunction);

        // Update both states with sorted data
        setGroupData(sortedData);
        setFilteredData(sortedFilteredData);
    };

    const getSortIcon = (key: string) => {
        if (sortConfig.key !== key) {
            return <FontAwesomeIcon icon={faSort} />;
        }
        return sortConfig.direction === 'ascending' ? <FontAwesomeIcon icon={faSortUp} /> : <FontAwesomeIcon icon={faSortDown} />;
    };

    return (
        <div className='satsang-container'>
            <div className='row mb-1 align-items-center'>
                <div className='col-md-6'>
                    <h3>{t('group.group-title-label')}</h3>
                </div>
                {/* <div className='col-md-6 d-flex justify-content-end'>
                    <button
                        type="button"
                        className="btn btn-secondary "
                        aria-label="Add Satsang"
                        onClick={navigateToAddSatsang}
                    >
                        {t('satsang.add-satsang-btn-label')}
                    </button>
                </div> */}
            </div>

            <Filter
                onlySearch={true}
                filterType={FILTER_LISTING_TYPE.ATTENDANCE}
                onSearch={handleSearch}
                searchPlaceholder={t('search-only-name-placeholer-label')}
            />

            <div className="table-responsive">
                <table className="table table-bordered">
                    <thead className="tableHeading">
                        <tr>
                            <th onClick={() => handleSort('name')}>
                                {t('group.adhikari-column-label')} {getSortIcon('name')}
                            </th>
                            <th>
                                {t('group.designation-column-label')}
                            </th>
                            <th>{t('group.group-no-column-label')}</th>
                            <th>{t('group.sewadal-count-column-label')}</th>
                            <th>{t('action-label')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {(searchTerm || filteredData.length > 0 ? filteredData : groupData).map((row, i) => (
                            <tr key={i} >
                                <td>{row.adhikariName}</td>
                                <td>{row.adhikariType}</td>
                                <td>
                                    {row.groupNo}
                                </td>
                                <td>
                                    {row.groupNo}
                                </td>
                                <td>
                                    <Button className="icon-button">
                                        <FontAwesomeIcon icon={faSync} color='#3a69ff' />
                                    </Button>
                                </td>
                            </tr>
                        ))}

                    </tbody>
                </table>
                {searchTerm && filteredData.length === 0 && (
                    <div className="d-flex j-center">{t('no-results-found-help-text')}</div>
                )}
                {groupData.length === 0 && (
                    <div className="d-flex j-center help-text">{t('group.no-group-record-found')}</div>
                )}
            </div>
        </div>
    )
}

export default Group;