import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import "./UnitSuccess.css";
import { useNavigate } from "react-router-dom";

const UnitSuccess: React.FC = () => {

  const navigate = useNavigate();

  const navigateBack = () => {
    navigate(-1);
  }

  const navigateToAddUnit = () => {
    navigate("/unit/add")
  }

  return (
    <div className="container-fluid mt-5">
      <div className="routes"> Entry / <b> Add / Create Unit</b> </div>

      <div className="row justify-content-center mt-3">
        <div className="col-12 col-lg-12">
          <div className="card shadow">
            <div className="card-body p-4">

              <div className="successCard">
                <div>
                  <img className="successImg" src="./success.png" alt=""/>
                </div>
                <div>Unit Created Successfully</div>

              </div>
              <div className="d-flex justify-content-between align-items-center border-top pt-3 mt-4">
                <button className="btn" onClick={navigateBack}>
                  <FontAwesomeIcon icon={faChevronLeft}/> Back
                </button>
                <button type="submit" className="btn btn-primary" onClick={navigateToAddUnit}>Click here to add sewadal</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}


export default UnitSuccess;