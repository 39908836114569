import React, { useState } from "react"
import { Button, Modal } from "react-bootstrap"
import Form from 'react-bootstrap/Form';
import { AttendanceUserModel } from "src/Common/Models/Sewadal/AttendanceUserModel";
import { SewadalDataModel } from "src/Common/Models/Sewadal/SewadalModel";
import { generateRandomPassword } from "src/utils/utils";
import { faEyeSlash, faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface AddAttendanceUserModalProps {
    show: boolean;
    handleClose: () => void;
    sewadal: SewadalDataModel | null;
    handleSave: (AttendanceUserModel: AttendanceUserModel) => void;
}
const AddAttendanceUserModal: React.FC<AddAttendanceUserModalProps> = ({ show, handleClose, sewadal, handleSave }) => {
    const [password, setPassword] = useState(generateRandomPassword(8));
    const [showPassword, setShowPassword] = useState(false);

    const handleUserSave = () => {
        const attendanceUser: AttendanceUserModel = {
            sewadalId: sewadal?.id || 0,
            password: password
        }
        handleSave(attendanceUser);
    }

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    }
    return (
        <Modal
            aria-labelledby="contained-modal-title-vcenter"
            centered show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title className='contained-modal-title-vcenter'>{sewadal!.name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3 position-relative">
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                            type={showPassword ? 'text' : 'password'}
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                        {/* Eye icon to toggle password visibility */}
                        <FontAwesomeIcon
                            icon={showPassword ? faEyeSlash : faEye}
                            onClick={togglePasswordVisibility}
                            className="password-toggle-icon"
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={handleUserSave}>
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default AddAttendanceUserModal;