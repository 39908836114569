export class AttendanceFilterDataModel {
  public date: string;
  // public groupDutyId: string;
  public satsangId: number;
  public satsangTypeId: number;

  constructor(date: string) {
    this.date = date;
    // this.groupDutyId = "";
    this.satsangId = 0;
    this.satsangTypeId = 0;
  }
}
