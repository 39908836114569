import {
  DB_GENDER,
  GENDER,
  SEWADAL_TYPE,
} from 'src/Common/AppEnum';
import { generateRandomPassword } from 'src/utils/utils';

export class AddSewadalFormModel {
  public uniquePersonalNumber: string;
  public personalNumber: string;
  public name: string;
  public groupNo?: string;
  public unitNumber: number;
  public gender: GENDER;
  public dateOfAdd: string;
  public fathersName: string;
  public dateOfBirth: string;
  public qualification: string;
  public occupation: string;
  public address: string;
  public contactNo: string;
  public bloodGroup: number;
  public dateOfBadge: string;
  public dateOfEntry: string;
  public dateOfDeletion: string;
  public trade: string;
  public email: string;
  public uploadPhoto: string;
  public remarks: string;
  public password?: string;
  public type: SEWADAL_TYPE;

  constructor(sewadalType: SEWADAL_TYPE) {
    this.uniquePersonalNumber = "";
    this.personalNumber = "";
    this.name = "";
    this.unitNumber = 0;
    this.gender = GENDER.UNSET;
    this.dateOfAdd = "";
    this.fathersName = "";
    this.dateOfBirth = "";
    this.qualification = "";
    this.occupation = "";
    this.address = "";
    this.contactNo = "";
    this.bloodGroup = 1;
    this.dateOfBadge = "";
    this.dateOfEntry = "";
    this.dateOfDeletion = "";
    this.trade = "";
    this.email = "";
    this.uploadPhoto = "";
    this.remarks = "";
    this.password = generateRandomPassword(8);
    this.type = sewadalType;
  }
}

export interface SewadalDataModelList{
  totalCount: number;
  sewadals:SewadalDataModel[]
}

export class SewadalDataModel {
  public id: number;
  public uniquePersonalNumber: string;
  public personalNumber: string;
  public name: string;
  public groupNo: string;
  public unitNumber: number;
  public gender: GENDER;
  public dateOfAdd: string;
  public fathersName: string;
  public dateOfBirth: string;
  public qualification: string;
  public occupation: string;
  public address: string;
  public contactNo: string;
  public bloodGroup: number;
  public dateOfBadge: string;
  public dateOfEntry: string;
  public dateOfDeletion: string;
  public trade: string;
  public email: string;
  public remarks: string;
  public type: SEWADAL_TYPE;
  public disabled?: boolean;

  constructor(sewadalType: SEWADAL_TYPE) {
    this.id = 0;
    this.uniquePersonalNumber = "";
    this.personalNumber = "";
    this.name = "";
    this.groupNo = "";
    this.unitNumber = 0;
    this.gender = GENDER.UNSET;
    this.dateOfAdd = "";
    this.fathersName = "";
    this.dateOfBirth = "";
    this.qualification = "";
    this.occupation = "";
    this.address = "";
    this.contactNo = "";
    this.bloodGroup = 1;
    this.dateOfBadge = "";
    this.dateOfEntry = "";
    this.dateOfDeletion = "";
    this.trade = "";
    this.email = "";
    this.remarks = "";
    this.type = sewadalType;
  }
}


export interface GetSewadalApiParams { gender?:DB_GENDER, unitNo: number; page: number; pageSize: number; searchTerm: string; type: SEWADAL_TYPE; }
