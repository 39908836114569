import './index.css';

import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {
  ExportReportsApi,
  GetReportsApi,
} from 'src/api/ReportsApi';
import {
  FILTER_INPUT_TYPE,
  FILTER_LISTING_TYPE,
  PRESENTY_STATUS,
  REPORT_TYPE,
  USER_TYPE,
} from 'src/Common/AppEnum';
import {
  ReportDataModel,
  ReportsFilterPayloadModel,
} from 'src/Common/Models/Reports/ReportsDataModel';
import Paginator from 'src/Common/Paginator';
import {
  hideLoader,
  showLoader,
} from 'src/redux/loaderSlice';

import {
  faSort,
  faSortDown,
  faSortUp,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMutation } from '@tanstack/react-query';

import ReportsFilter from './ReportsFilter';

interface ReportsProps {
	userType: USER_TYPE | null | undefined;
}

const Reports = ({ userType }: ReportsProps) => {
	const { t } = useTranslation();

	const [reportsData, setReportsData] = useState<ReportDataModel[]>([]);
	const [filteredData, setFilteredData] = useState<ReportDataModel[]>([]);
	const [totalSatsangCount, setTotalSatsangCount] = useState<number>(0);
	const [searchTerm, setSearchTerm] = useState<string>('');
	const [isFilterApplied, setIsFilterApplied] = useState<boolean>(false);
	const [reportType, setReportType] = useState<REPORT_TYPE>(REPORT_TYPE.NONE);
	const [sortConfig, setSortConfig] = useState<{
		key: string;
		direction: 'ascending' | 'descending' | '';
	}>({
		key: 'uniquePersonalNumber',
		direction: 'ascending',
	});
	const dispatch = useDispatch();
	const [appliedFilters, setAppliedFilters] =
		useState<ReportsFilterPayloadModel>();
	const [columns, setColumns] = useState([
		{
			label: t('name-label'), // Label for the column
			field: 'name', // The key in the data object (row)
			sortable: true, // Whether the column is sortable
		},
		{
			label: t('sewadal-no-label'),
			field: 'uniquePersonalNumber',
			sortable: true,
		},
		{
			label: t('reports.percent'),
			field: 'percent', // This column will display percentage data
			sortable: false,
		},
	]);
	const [selectedItemsPerPage, setSelectedItemsPerPage] = useState<number>(10);
	const [currentPage, setCurrentPage] = useState<number>(1);

	const handleItemsPerPageChange = (items: number) => {
		setSelectedItemsPerPage(items);
		setCurrentPage(1); // Reset to page 1 when changing items per page
	};

	const paginatedData = (
		searchTerm || filteredData.length > 0 ? filteredData : reportsData
	).slice(
		(currentPage - 1) * selectedItemsPerPage,
		currentPage * selectedItemsPerPage
	);

	const handlePageChange = (page: number) => {
		setCurrentPage(page);
	};

	const resetColumns = (reportType: REPORT_TYPE) => {
		if (reportType === REPORT_TYPE.ADHIKARI_INDIVIDUAL) {
			let col = [
				{
					label: 'Sr No.',
					field: 'index',
					sortable: true,
				},
				{
					label: 'Name',
					field: 'adhikariName',
					sortable: true,
				},
				{
					label: 'Satsang Name',
					field: 'satsangName',
					sortable: false,
				},
				{
					label: 'Date',
					field: 'date',
					sortable: false,
				},
				{
					label: 'Attendance Type',
					field: 'attendanceType',
					sortable: false,
				},
			];
			setColumns(col);
		} else if (reportType === REPORT_TYPE.ATTENDANCE_DETAILS) {
			let col = [
				{
					label: 'Sr No.',
					field: 'index',
					sortable: true,
				},
				{
					label: 'Name',
					field: 'name',
					sortable: true,
				},
				{
					label: 'Unique Personal Number',
					field: 'uniquePersonalNumber',
					sortable: false,
				},
				{
					label: 'Present Satsang Count',
					field: 'attendanceCount',
					sortable: false,
				},
				{
					label: 'Total Satsang Count',
					field: 'totalSatsangCount',
					sortable: false,
				},
			];
			setColumns(col);
		} else if (reportType === REPORT_TYPE.UNITWISE) {
			let col = [
				{
					label: 'Sr No.',
					field: 'index',
					sortable: true,
				},
				{
					label: 'Name',
					field: 'adhikariName',
					sortable: true,
				},
				{
					label: 'Designation',
					field: 'designation',
					sortable: true,
				},
				{
					label: 'Percentage',
					field: 'percent',
					sortable: false,
				},
			];
			if (userType === USER_TYPE.KSHETRIYA_SANCHALAK) {
				col.push({
					label: 'Group Average',
					field: 'groupAverage',
					sortable: false,
				});
			}
			setColumns(col);
		} else if (reportType === REPORT_TYPE.BELOW_60) {
			let col = [
				{
					label: 'Sr No.',
					field: 'index',
					sortable: true,
				},
				{
					label: 'Name',
					field: 'adhikariName',
					sortable: true,
				},
				{
					label: 'Designation',
					field: 'designation',
					sortable: true,
				},
				{
					label: 'Percentage',
					field: 'percent',
					sortable: false,
				},
				{
					label: 'Group Average',
					field: 'groupAverage',
					sortable: false,
				},
			];
			setColumns(col);
		} else if (reportType === REPORT_TYPE.BLOOD_GROUP) {
			let col = [
				{
					label: 'Sr No.',
					field: 'index',
					sortable: true,
				},
				{
					label: 'Name',
					field: 'name',
					sortable: true,
				},
				{
					label: 'Blood Group',
					field: 'bloodGroup',
					sortable: true,
				},
				{
					label: 'Mobile No',
					field: 'mobileNo',
					sortable: false,
				},
				{
					label: 'Unit No',
					field: 'unitNo',
					sortable: false,
				},
			];
			setColumns(col);
		} else if (reportType === REPORT_TYPE.TRADE) {
			let col = [
				{
					label: 'Sr No.',
					field: 'index',
					sortable: true,
				},
				{
					label: 'Name',
					field: 'name',
					sortable: true,
				},
				{
					label: 'Trade',
					field: 'trade',
					sortable: true,
				},
				{
					label: 'Mobile No',
					field: 'mobileNo',
					sortable: false,
				},
				{
					label: 'Unit No',
					field: 'unitNo',
					sortable: false,
				},
			];
			setColumns(col);
		}
	};

	const escapeRegExp = (value: string) => {
		return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
	};

	const getMutation = useMutation<any, unknown, ReportsFilterPayloadModel>({
		mutationFn: GetReportsApi,
		onSuccess: (data: any) => {
			resetColumns(reportType!);
			if (data) {
				if (data && data?.totalSatsangCount) {
					setReportsData(data.attendanceDetails);
					setTotalSatsangCount(data.totalSatsangCount);
				} else {
					setReportsData(data);
				}
			}
			setFilteredData([]);
			setIsFilterApplied(true);
			dispatch(hideLoader());
		},
		onError: (error: any) => {
			dispatch(hideLoader());
			console.error('Api failed:', error.response?.data || error.message);
		},
	});

	const exportMutation = useMutation<any, unknown, ReportsFilterPayloadModel>({
		mutationFn: ExportReportsApi,
		onSuccess: (data: Blob) => {
			const url = window.URL.createObjectURL(new Blob([data]));

			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', 'Attendance_Report.xlsx');

			document.body.appendChild(link);
			link.click();

			document.body.removeChild(link);
			dispatch(hideLoader());
		},
		onError: (error: any) => {
			dispatch(hideLoader());
			console.error('Api failed:', error.response?.data || error.message);
		},
	});

	const handleSort = (key: string) => {
		let direction: 'ascending' | 'descending' = 'ascending';

		// Toggle sorting direction
		if (sortConfig.key === key && sortConfig.direction === 'ascending') {
			direction = 'descending';
		}

		// Update the sorting configuration
		setSortConfig({ key, direction });

		// Sorting logic for both 'data' and 'filteredData'
		const sortFunction = (a: ReportDataModel, b: ReportDataModel) => {
			if (a[key as keyof ReportDataModel]! < b[key as keyof ReportDataModel]!) {
				return direction === 'ascending' ? -1 : 1;
			}
			if (a[key as keyof ReportDataModel]! > b[key as keyof ReportDataModel]!) {
				return direction === 'ascending' ? 1 : -1;
			}
			return 0;
		};

		// Sort the main data and the filtered data
		const sortedData = [...reportsData].sort(sortFunction);
		const sortedFilteredData = [...filteredData].sort(sortFunction);

		// Update both states with sorted data
		setReportsData(sortedData);
		setFilteredData(sortedFilteredData);
	};

	const getSortIcon = (key: string) => {
		if (sortConfig.key !== key) {
			return <FontAwesomeIcon icon={faSort} />;
		}
		return sortConfig.direction === 'ascending' ? (
			<FontAwesomeIcon icon={faSortUp} />
		) : (
			<FontAwesomeIcon icon={faSortDown} />
		);
	};

	const handleSearch = (searchTerm: string) => {
		setSearchTerm(searchTerm);

		const searchRegex = new RegExp(escapeRegExp(searchTerm), 'i');

		const filteredRows = reportsData.filter((row) => {
			return Object.keys(row).some((field) => {
				// @ts-ignore

				return searchRegex.test(row[field]?.toString());
			});
		});
		if (searchTerm.length > 0) {
			setFilteredData(filteredRows);
		} else {
			setFilteredData([]);
		}
	};

	const handleFilterApply = (
		appliedFilters: { [key: string]: string | number },
		reportType: REPORT_TYPE
	) => {
		// console.log(appliedFilters);
		dispatch(showLoader());
		console.log(appliedFilters);
		let data: ReportsFilterPayloadModel = {
			startDate: appliedFilters?.[FILTER_INPUT_TYPE.START_MONTH]
				? appliedFilters[FILTER_INPUT_TYPE.START_MONTH].toString()
				: '',
			endDate: appliedFilters?.[FILTER_INPUT_TYPE.END_MONTH]
				? appliedFilters[FILTER_INPUT_TYPE.END_MONTH].toString()
				: '',
			reportType: Number(reportType) as REPORT_TYPE,
			adhikariId: Number(appliedFilters[FILTER_INPUT_TYPE.SEWADAL] || 0),
			unitNo: Number(appliedFilters[FILTER_INPUT_TYPE.UNIT] || 0),
			bloodGroup: appliedFilters[FILTER_INPUT_TYPE.BLOOD_GROUP],
			gender: appliedFilters[FILTER_INPUT_TYPE.SEWADAL_GENDER_TYPE]
				? appliedFilters[FILTER_INPUT_TYPE.SEWADAL_GENDER_TYPE].toString()
                : undefined,
            trade:appliedFilters[FILTER_INPUT_TYPE.TRADE]
		};
		setAppliedFilters(data);
		setReportType(Number(reportType) as REPORT_TYPE);

		getMutation.mutate(data);
	};

	const handleReset = () => {
		setIsFilterApplied(false);
		setReportsData([]);
		setFilteredData([]);
	};

	const handleExport = () => {
		if (reportsData.length === 0 || !appliedFilters) return;
		dispatch(hideLoader());
		exportMutation.mutate(appliedFilters);
	};

	const getAttendanceType = (status: PRESENTY_STATUS): string => {
		switch (status) {
			case PRESENTY_STATUS.PV:
				return 'PV';
			case PRESENTY_STATUS.P:
				return 'P';
			case PRESENTY_STATUS.PC:
				return 'PC';
			default:
				return 'A';
		}
	};

	const formatCellDate = (dateString: string) => {
		const date = new Date(dateString);
		const day = String(date.getDate()).padStart(2, '0');
		const month = String(date.getMonth() + 1).padStart(2, '0');
		const year = date.getFullYear();
		return `${day}-${month}-${year}`;
	};

	return (
		<div className="attendancePage">
			<div className="tablecontainer mt-4">
				<h3>Reports</h3>
				<ReportsFilter
					filterType={FILTER_LISTING_TYPE.REPORTS}
					onlySearch={false}
					canExport={true}
					handleExport={reportsData.length > 0 ? handleExport : undefined}
					onApply={handleFilterApply}
					onReset={handleReset}
					onSearch={handleSearch}
					searchPlaceholder={t('search-name-placeholer-label')}
				/>

				<div className="table-responsive">
					{isFilterApplied && (
						<table className="table table-bordered">
							<thead className="tableHeading">
								<tr>
									{columns.map((col, index) => (
										<th
											key={index}
											onClick={() => col.sortable && handleSort(col.field)}>
											{col.label} {col.sortable && getSortIcon(col.field)}
										</th>
									))}
								</tr>
							</thead>
							<tbody>
								{paginatedData.map((row, i) => (
									<tr key={i}>
										<td>{(currentPage - 1) * selectedItemsPerPage + i + 1}</td>
										<td>{row?.name}</td>
										{reportType === REPORT_TYPE.ADHIKARI_INDIVIDUAL && (
											<>
												<td>{row?.satsangName}</td>
												<td>{formatCellDate(row?.date || '')}</td>
												<td
													style={{
														color:
															getAttendanceType(
																row?.attendanceType as PRESENTY_STATUS
															) === 'A'
																? 'red'
																: 'green',
													}}>
													{getAttendanceType(
														row?.attendanceType as PRESENTY_STATUS
													)}
												</td>
											</>
										)}
										{reportType === REPORT_TYPE.ATTENDANCE_DETAILS && (
											<>
												<td>{row?.uniquePersonalNumber}</td>
												<td>{row?.attendanceCount}</td>
												<td>{totalSatsangCount}</td>
											</>
										)}
										{reportType === REPORT_TYPE.UNITWISE &&
											userType === USER_TYPE.KSHETRIYA_SANCHALAK && (
												<>
													<td>{row?.designation}</td>
													<td>{row?.percent} %</td>
													<td>{row?.groupAverage}</td>
												</>
											)}
										{reportType === REPORT_TYPE.BELOW_60 && (
											<>
												<td>{row?.designation}</td>
												<td>{row?.percent} %</td>
												<td>{row?.groupAverage}</td>
											</>
										)}
										{reportType === REPORT_TYPE.UNITWISE &&
											userType !== USER_TYPE.KSHETRIYA_SANCHALAK && (
												<>
													<td>{row?.designation}</td>
													<td>{row?.percent} %</td>
												</>
											)}
										{reportType === REPORT_TYPE.BLOOD_GROUP && (
											<>
												<td>{row?.bloodGroup || '-'}</td>
												<td>{row?.mobileNo || '-'} </td>
												<td>{row?.unitNo || '-'}</td>
											</>
										)}
										{reportType === REPORT_TYPE.TRADE && (
											<>
												<td>{row?.trade || '-'}</td>
												<td>{row?.mobileNo || '-'} </td>
												<td>{row?.unitNo || '-'}</td>
											</>
										)}
									</tr>
								))}
							</tbody>
						</table>
					)}
					{/* Pagination component */}
					{isFilterApplied && searchTerm && filteredData.length === 0 && (
						<div className="d-flex j-center">
							{t('no-results-found-help-text')}
						</div>
					)}
					{!isFilterApplied && (
						<div className="d-flex j-center help-text">
							{t('reports.apply-filter-help-text')}
						</div>
					)}
					{isFilterApplied && reportsData.length === 0 && (
						<div className="d-flex j-center help-text">
							{t('no-sewadal-record-found')}
						</div>
					)}
				</div>
				{isFilterApplied && reportsData.length > 0 && (
					<Paginator
						totalItems={reportsData.length}
						itemsPerPage={selectedItemsPerPage}
						currentPage={currentPage}
						onPageChange={handlePageChange}
						onItemsPerPageChange={handleItemsPerPageChange}
					/>
				)}
			</div>
		</div>
	);
};

export default Reports;
