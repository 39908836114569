import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import "../FormsMainPage.css";

const Sd7VisitReportForm: React.FC = () => {
  // State for form data
  const [formData, setFormData] = useState({
    campdate: "",
    unitNumber: "12345",
    branch: "Branch A",
    khetra: "Kshetra Y",
    zone: "Zone Z",
    kshetraSanchalakName: "",
    speakershipName: "",
    satsangCountMale: "",
    satsangCountFemale: "",
    unregistredSewadalMale:"",
    unregistredSewadalFemale:"",
    incompleteVardiMaleCount:"",
    incompleteVardiFemaleCount:"",
  });

  // Hardcoded data for now
  useEffect(() => {
    const hardcodedData = {
      campdate: "",
      unitNumber: "12345",
      branch: "Branch A",
      khetra: "Kshetra Y",
      zone: "Zone Z",
      kshetraSanchalakName: "",
      speakershipName: "",
      satsangCountMale: "",
      satsangCountFemale: "",
      unregistredSewadalMale:"",
      unregistredSewadalFemale:"",
      incompleteVardiMaleCount:"",
      incompleteVardiFemaleCount:"",
    };

    setFormData(hardcodedData);
  }, []);

  const [tableData, setTableData] = useState([{name: "",post: "",reasonForAbsence: "",},]);

  // Sample members data with dummy images for John
  const membersData = [{name: "John",post: "Sanchalak",reasonForAbsence: "",},
    {name: "Jane",post: "Shikshak",reasonForAbsence: "",},
  ];

  const handleTableChange = (
    index: number,
    e: React.ChangeEvent<
      HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = e.target;
    const updatedTableData = [...tableData];
    if (updatedTableData[index]) {
      updatedTableData[index] = {
        ...updatedTableData[index],
        [name]: value,
      };
      setTableData(updatedTableData);
    }
  };

  const handleNameChange = (
    index: number,
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const selectedName = e.target.value;
    const selectedMember = membersData.find(
      (member) => member.name === selectedName
    );

    const updatedTableData = [...tableData];
    if (updatedTableData[index]) {
      updatedTableData[index] = {
        ...updatedTableData[index],
        ...selectedMember, // Populate the row with the selected member's details
      };
      setTableData(updatedTableData);
    }
  };

  const addRow = () => {
    setTableData([
      ...tableData,
      {name: "",post: "",reasonForAbsence: "",},
    ]);
  };

  const deleteRow = (index: number) => {
    const updatedTableData = tableData.filter((_, i) => i !== index);
    setTableData(updatedTableData);
  };

  const [groupWiseData, setgroupWiseData] = useState([{name: "",post: "",numberOfSewadal: "",averageAttendance: "",presentSewadalCount: "",note: "",},]);

  // Sample members data with dummy images for John
  const groupWiseSewadalData = [
    {name: "John",post: "Sanchalak",numberOfSewadal: "20",averageAttendance: "",presentSewadalCount: "",note: "",},
    {name: "Jane",post: "Shikshak",numberOfSewadal: "20",averageAttendance: "",presentSewadalCount: "",note: "",},
  ];

  const handleGroupTableChange = (
    index: number,
    e: React.ChangeEvent<
      HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = e.target;
    const updatedGroupTableData = [...groupWiseData];
    if (updatedGroupTableData[index]) {
      updatedGroupTableData[index] = {
        ...updatedGroupTableData[index],
        [name]: value,
      };
      setgroupWiseData(updatedGroupTableData);
    }
  };

  const handleGroupNameChange = (
    index: number,
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const selectedGroupName = e.target.value;
    const selectedGroupMember = groupWiseSewadalData.find(
      (member) => member.name === selectedGroupName
    );

    const updatedGroupTableData = [...groupWiseData];
    if (updatedGroupTableData[index]) {
      updatedGroupTableData[index] = {
        ...updatedGroupTableData[index],
        ...selectedGroupMember, // Populate the row with the selected member's details
      };
      setgroupWiseData(updatedGroupTableData);
    }
  };

  const addGroupRow = () => {
    setgroupWiseData([
      ...groupWiseData,
      {name: "",post: "",numberOfSewadal: "",averageAttendance: "",presentSewadalCount: "",note: "",},
    ]);
  };

  const deleteGroupRow = (index: number) => {
    const updatedGroupTableData = groupWiseData.filter((_, i) => i !== index);
    setgroupWiseData(updatedGroupTableData);
  };

  // Handle input changes for editable fields
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  //code for male female sewadal report table
  const [sewadalReportData, setsewadalReportData] = useState({
    male: {totalSewadalCount: "",countAfterDeletion: "",presentRegistered: "",presentUnregistered: "",totalCount: "",},
    female: {totalSewadalCount: "",countAfterDeletion: "",presentRegistered: "",presentUnregistered: "",totalCount: "",},
  });

  // Define a type for the gender
  type Gender = "male" | "female";

  // Handle change in input values
  const handlesewadalReportInputChange = (
    gender: Gender,
    field: keyof (typeof sewadalReportData)[Gender],
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = e.target;
    setsewadalReportData((prevData) => ({
      ...prevData,
      [gender]: {
        ...prevData[gender],
        [field]: value,
      },
    }));
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log("Form Data:", formData);
    console.log("Table Data:", tableData);
    console.log("Sewadal Report Data:", sewadalReportData);
  };

  return (
    <div className="container-fluid mt-5">
      <div className="row justify-content-center mt-3">
        <div className="col-12 col-lg-12">
          <div className="card shadow">
            <div className="card-body">
              <legend className="sd2GAdhikariProposal">
                <b>SD-7 Quarterly Unit Inspection Form</b>
              </legend>
              <p>
                Please enter the necessary details for SD-7 Quarterly Unit Inspection Form.
              </p>
              <form onSubmit={handleSubmit}>
                <div className="row">
                  {/* Column 1 */}
                  <div className="col-md-6">
                    <div className="form-group mb-3">
                      <label htmlFor="campdate">Camp Date</label>
                      <input type="Date" className="form-control" id="campdate" value={formData.campdate} onChange={handleInputChange} />
                    </div>
                    <div className="form-group mb-3">
                      <label htmlFor="branch">Branch</label>
                      <input type="text" className="form-control" id="branch" value={formData.branch} disabled />
                    </div>
                    <div className="form-group mb-3">
                      <label htmlFor="zone">Zone</label>
                      <input type="text" className="form-control" id="zone" value={formData.zone} disabled />
                    </div>
                    <div className="form-group mb-3">
                      <label htmlFor="speakershipName">Speakership Name</label>
                      <input type="text" className="form-control" id="speakershipName" value={formData.speakershipName} onChange={handleInputChange} />
                    </div>
                    <div className="form-group mb-3">
                      <label htmlFor="satsangCountFemale">
                        Satsang Count (Female)
                      </label>
                      <input type="text" className="form-control" id="satsangCountFemale" value={formData.satsangCountFemale} onChange={handleInputChange} />
                    </div>
                    <div className="form-group mb-3">
                      <label htmlFor="unregistredSewadalFemale">
                        Un-Registred Sewadal Count (Female)
                      </label>
                      <input type="text"
                        className="form-control"
                        id="unregistredSewadalFemale"
                        value={formData.unregistredSewadalFemale}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="form-group mb-3">
                      <label htmlFor="incompleteVardiFemaleCount">
                        Incomplete Vardi Sewadal Count (Male)
                      </label>
                      <input type="text"
                        className="form-control"
                        id="incompleteVardiFemaleCount"
                        value={formData.incompleteVardiFemaleCount}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>

                  {/* Column 2 */}
                  <div className="col-md-6">
                    <div className="form-group mb-3">
                      <label htmlFor="unitNumber">Unit Number</label>
                      <input type="text" className="form-control" id="unitNumber" value={formData.unitNumber} disabled />
                    </div>
                    <div className="form-group mb-3">
                      <label htmlFor="khetra">Khetra</label>
                      <input type="text" className="form-control" id="khetra" value={formData.khetra} disabled />
                    </div>
                    <div className="form-group mb-3">
                      <label htmlFor="kshetraSanchalakName">
                        Kshetra Sanchalak Name
                      </label>
                      <input type="text" className="form-control" id="kshetraSanchalakName" value={formData.kshetraSanchalakName} onChange={handleInputChange} />
                    </div>

                    <div className="form-group mb-3">
                      <label htmlFor="satsangCountMale">
                        Satsang Count (Male)
                      </label>
                      <input type="text"
                        className="form-control"
                        id="satsangCountMale"
                        value={formData.satsangCountMale}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="form-group mb-3">
                      <label htmlFor="unregistredSewadalMale">
                        Un-Registred Sewadal Count (Male)
                      </label>
                      <input type="text"
                        className="form-control"
                        id="unregistredSewadalMale"
                        value={formData.unregistredSewadalMale}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="form-group mb-3">
                      <label htmlFor="incompleteVardiMaleCount">
                        Incomplete Vardi Sewadal Count (Male)
                      </label>
                      <input type="text"
                        className="form-control"
                        id="incompleteVardiMaleCount"
                        value={formData.incompleteVardiMaleCount}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="table-responsive">
                  <h5>Report For Present Sewadal In Camp:</h5>
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th>Gender</th>
                        <th>Total Sewadal Count</th>
                        <th>Count After Sewadal Deletion</th>
                        <th>Present Registered Sewadal In Camp</th>
                        <th>Present Un-Registered Sewadal In Camp</th>
                        <th>Total Count</th>
                      </tr>
                    </thead>
                    <tbody>
                      {/* Male row */}
                      <tr>
                        <td>Male</td>
                        <td>
                          <input type="text" value={sewadalReportData.male.totalSewadalCount} onChange={(e) =>handlesewadalReportInputChange("male","totalSewadalCount",e)} />
                        </td>
                        <td>
                          <input type="text" value={sewadalReportData.male.countAfterDeletion} onChange={(e) =>handlesewadalReportInputChange("male","countAfterDeletion",e)} />
                        </td>
                        <td>
                          <input type="text" value={sewadalReportData.male.presentRegistered} onChange={(e) =>handlesewadalReportInputChange("male","presentRegistered",e)} />
                        </td>
                        <td>
                          <input type="text" value={sewadalReportData.male.presentUnregistered} onChange={(e) =>handlesewadalReportInputChange("male","presentUnregistered",e)} />
                        </td>
                        <td>
                          <input type="text" value={sewadalReportData.male.totalCount} onChange={(e) =>handlesewadalReportInputChange("male","totalCount",e)} />
                        </td>
                      </tr>
                      {/* Female row */}
                      <tr>
                        <td>Female</td>
                        <td><input type="text" value={sewadalReportData.female.totalSewadalCount} onChange={(e) =>handlesewadalReportInputChange("female", "totalSewadalCount",e)} />
                        </td>
                        <td>
                          <input type="text" value={sewadalReportData.female.countAfterDeletion} onChange={(e) => handlesewadalReportInputChange("female","countAfterDeletion",e)}/>
                        </td>
                        <td>
                          <input type="text" value={sewadalReportData.female.presentRegistered} onChange={(e) => handlesewadalReportInputChange("female","presentRegistered",e)}
                          />
                        </td>
                        <td>
                          <input type="text" value={sewadalReportData.female.presentUnregistered}
                            onChange={(e) => handlesewadalReportInputChange("female","presentUnregistered",e)} />
                        </td>
                        <td>
                          <input type="text" value={sewadalReportData.female.totalCount} onChange={(e) => handlesewadalReportInputChange("female","totalCount",e) }/>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="table-responsive">
                  <h5>Report For Absence Adhikari :</h5>
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Post</th>
                        <th>Reason For Absence</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {tableData.map((row, index) => (
                        <tr key={index}>
                          <td>
                            <select
                              name="name"
                              value={row.name}
                              onChange={(e) => handleNameChange(index, e)}
                            >
                              <option value="">Select Name</option>
                              {membersData.map((member) => (
                                <option key={member.name} value={member.name}> {member.name} </option>
                              ))}
                            </select>
                          </td>
                          <td>
                            <input type="text" name="post" value={row.post} onChange={(e) => handleTableChange(index, e)} />
                          </td>
                          <td>
                            <input type="text" name="reasonForAbsence" value={row.reasonForAbsence}
                              onChange={(e) => handleTableChange(index, e)} />
                          </td>
                          <td>
                            <button className="addRow" type="button" onClick={addRow} >
                              <FontAwesomeIcon icon={faPlus} style={{ color: "#007bff" }} />
                            </button>
                            {index > 0 && (
                              <button className="deleteRow" type="button" onClick={() => deleteRow(index)} style={{ marginLeft: "10px" }} >
                                <FontAwesomeIcon icon={faTrash} style={{ color: "red" }} />
                              </button>)}
                          </td>
                        </tr>))}
                    </tbody>
                  </table>
                </div>
                <div className="table-responsive">
                  <h5>Details Of Sewadals Attached With Adhikari:</h5>
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th>Name</th><th>Post</th><th>Number Of Sewadals Added</th><th>Average Attendance For Last 3 Months</th><th>Present Sewadal In Camp</th><th>Note</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {groupWiseData.map((row, index) => (
                        <tr key={index}>
                          <td>
                            <select name="name" value={row.name} onChange={(e) => handleGroupNameChange(index, e)}
                            >
                              <option value="">Select Name</option>
                              {membersData.map((member) => (
                                <option key={member.name} value={member.name}> {member.name} </option> ))}
                            </select>
                          </td>
                          <td>
                            <input type="text" name="post" value={row.post} onChange={(e) => handleGroupTableChange(index, e)} />
                          </td>
                          <td>
                            <input type="text" name="numberOfSewadal" value={row.numberOfSewadal} onChange={(e) => handleGroupTableChange(index, e)} />
                          </td>
                          <td>
                            <input type="text" name="averageAttendance" value={row.averageAttendance} onChange={(e) => handleGroupTableChange(index, e)} />
                          </td>
                          <td>
                            <input type="text" name="presentSewadalCount" value={row.presentSewadalCount} onChange={(e) => handleGroupTableChange(index, e)} />
                          </td>
                          <td>
                            <input type="text" name="note" value={row.note} onChange={(e) => handleGroupTableChange(index, e)} />
                          </td>
                          <td>
                            <button
                              className="addRow"
                              type="button"
                              onClick={addGroupRow}
                            >
                              <FontAwesomeIcon icon={faPlus} style={{ color: "#007bff" }} />
                            </button>
                            {index > 0 && (
                              <button
                                className="deleteRow"
                                type="button"
                                onClick={() => deleteGroupRow(index)}
                                style={{ marginLeft: "10px" }}
                              >
                                <FontAwesomeIcon icon={faTrash} style={{ color: "red" }} />
                              </button>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div className="d-flex justify-content-end mt-4">
                  <button type="submit" className="btn btn-primary">Submit</button>
                </div></form></div></div></div></div></div>);};
export default Sd7VisitReportForm;
