import React from "react";
import "./FollowUpList.css";
import BreadcrumbsSec from "../Dashboard/Breadcrumbs/Breadcrumbs";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const FollowUpList: React.FC = () => {
    const navigate = useNavigate();

    const handleNavigate = () => {
        navigate('/sewadalForms');
    };
    return (
        <div className="followUpList">

            <div className="tablecontainer mt-4">
                <h5>Follow-Up List</h5>
                {/* Added a scrollable wrapper around the table */}
                <div className="table-responsive">
                    <table className="table table-bordered ">
                        <thead className="tableHeading">
                            <tr>
                                <th rowSpan={1}>Sr No</th>
                                <th rowSpan={1}>Form Submission Date</th>
                                <th rowSpan={1}>Form Name</th>
                                <th rowSpan={1}>Status</th>
                                <th rowSpan={1}>Comment</th>
                                <th rowSpan={1}>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>1</td>
                                <td>2023/6/11</td>
                                <td>SD1</td>
                                <td>Pending</td>
                                <td>
                                    <textarea className="form-control" placeholder="Enter your comments here..."></textarea>
                                </td>
                                <td>
                                    <select>
                                        <option>action1</option>
                                        <option>action2</option>
                                        <option>action3</option>
                                    </select>
                                </td>

                            </tr>
                            <tr>
                                <td>2</td>
                                <td>2023/6/11</td>
                                <td>SD1</td>
                                <td>Pending</td>
                                <td>
                                    <textarea className="form-control" placeholder="Enter your comments here..."></textarea>
                                </td>
                                <td>
                                    <select>
                                        <option>action1</option>
                                        <option>action2</option>
                                        <option>action3</option>
                                    </select>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default FollowUpList;
