// src/Component/Layout.tsx
import React, { useState, useEffect, useRef } from "react";
import Header from "../Header/Header";
import Sidebar from "../SideBar/Sidebar";
import { USER_TYPE } from "src/Common/AppEnum";
import Breadcrumb from "../Dashboard/Breadcrumbs/Breadcrumbs";

interface LayoutProps {
  children: React.ReactNode;
  sidebarOpen: boolean;
  toggleSidebar: () => void;
  userType: USER_TYPE | null | undefined;
}

const Layout: React.FC<LayoutProps> = ({
  children,
  sidebarOpen,
  toggleSidebar,
  userType,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const sidebarRef = useRef<HTMLDivElement>(null);

  const sidebarToggleSwitch = () => {
    setIsOpen((prevState) => !prevState);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      sidebarRef.current &&
      !sidebarRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false); // Close sidebar if clicked outside
    }
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 768) {
        setIsOpen(true); // Open sidebar for web view (larger screens)
        document.removeEventListener("mousedown", handleClickOutside);
      } else {
        setIsOpen(false); // Close sidebar for mobile view
        document.addEventListener("mousedown", handleClickOutside);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Initial check when component mounts

    return () => {
      window.removeEventListener("resize", handleResize);
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div>
      <Header toggleSidebar={sidebarToggleSwitch} userType={userType} />
      <Sidebar
        sidebarOpen={isOpen}
        toggleSidebar={sidebarToggleSwitch}
        userType={userType}
        sidebarRef={sidebarRef}
      />
      <main>
        <div
          className={`snmpage-container ${
            sidebarOpen ? "with-sidebar" : ""
          } pageContainer`}
        >
          <div className={`content-area ${sidebarOpen ? "shifted" : ""}`}>
            <Breadcrumb />
            {children}
          </div>
        </div>
      </main>
    </div>
  );
};

export default Layout;
