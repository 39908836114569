import React, {
  useEffect,
  useState,
} from 'react';

import { GetGroupApi } from 'src/api/groupApi';
import { GetSewadalApi1 } from 'src/api/sewadalApi';
import {
  BLOOD_GROUP,
  SEWADAL_TYPE,
} from 'src/Common/AppEnum';
import { GroupModel } from 'src/Common/Models/Group/GroupModel';
import { AddSewadalFormModel } from 'src/Common/Models/Sewadal/SewadalModel';

import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMutation } from '@tanstack/react-query';

interface SewadalFormProps {
    handleFormSubmit: (e: React.FormEvent, formData: AddSewadalFormModel) => void;
    handleBackClick: () => void;
    fromUnit: boolean;
    handleChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
    formData: AddSewadalFormModel;
    unitNo: number;
}

const SewadalForm: React.FC<SewadalFormProps> = ({ handleFormSubmit, handleBackClick, fromUnit, handleChange, formData, unitNo = 0 }) => {

    // const [formData, setFormData] = useState<AddSewadalFormModel>(new AddSewadalFormModel(fromUnit ? SEWADAL_TYPE.SANCHALAK : SEWADAL_TYPE.SEWADAL));
    const [group, setGroup] = useState<GroupModel[]>([]);
    const [addedDesignation, setAddedDesignation] = useState<SEWADAL_TYPE[]>();

    const getMutation = useMutation({
        mutationFn: GetGroupApi,
        onSuccess: (data: GroupModel[]) => {
            setGroup(data);
        },
        onError: (error: any) => {
            console.error("Api failed:", error.response?.data || error.message);
        }
    });

    const getAdhikariMutation = useMutation<any, Error, string>({
        mutationFn: GetSewadalApi1,
        onSuccess: (data: any[]) => {

            let typeArray: SEWADAL_TYPE[] = [];
            data.forEach(item => {
                typeArray.push(item.type);
            });
            setAddedDesignation(typeArray);
        },
        onError: (error: any) => {
            console.error("Api failed:", error.response?.data || error.message);
        }
    });

    useEffect(() => {
        if (unitNo && unitNo > 0) {
            let designationString = Object.values(SEWADAL_TYPE).filter(desg => desg !== SEWADAL_TYPE.SEWADAL && desg !== SEWADAL_TYPE.UR_SEWADAL && desg !== SEWADAL_TYPE.BAL_SEWADAL).join(",");

            let queryParam = `?unitNo=${unitNo}&type=${designationString}`;

            getAdhikariMutation.mutate(queryParam);
        }
    }, [unitNo])

    useEffect(() => {
        if (!fromUnit)
            getMutation.mutate();
    }, [fromUnit])


    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        // Optionally add form validation here
        handleFormSubmit(e, formData);
    };

    const handleBack = () => {
        handleBackClick();
    }

    const getSewadalTypes = () => {
        if (fromUnit) {
            return Object.values(SEWADAL_TYPE).filter((designation) => designation !== SEWADAL_TYPE.KSHETRIYA_SANCHALAK && designation !== SEWADAL_TYPE.SEWADAL && designation !== SEWADAL_TYPE.UR_SEWADAL && designation !== SEWADAL_TYPE.BAL_SEWADAL);
        } else {
            return Object.values(SEWADAL_TYPE).filter((designation) => designation === SEWADAL_TYPE.SEWADAL || designation === SEWADAL_TYPE.UR_SEWADAL || designation === SEWADAL_TYPE.BAL_SEWADAL);
        }
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className="row">
                <div className="mandatory-text">Fields marked with * are mandatory</div>
                <div className="col-md-6 mt-4">
                    <label htmlFor="type" className="form-label">
                        Sewadal Type <div className="astric"> * </div>
                    </label>
                    <select
                        id="type"
                        className="form-select"
                        value={formData.type}
                        onChange={handleChange}
                        required
                    >
                        <option value="">Select Designation</option>
                        {getSewadalTypes().map((designation) => (
                            <option key={designation} value={designation} disabled={addedDesignation?.includes(designation)}>
                                {designation === SEWADAL_TYPE.SEWADAL ? 'Registered Sewadal' : designation}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="col-md-6 mt-4">
                    <label htmlFor="uniquePersonalNumber" className="form-label">
                        New P N <div className="astric"> * </div>
                    </label>
                    <input
                        type="text"
                        id="uniquePersonalNumber"
                        className="form-control"
                        placeholder={fromUnit ? "SNSD87372367" : (formData.type === SEWADAL_TYPE.SEWADAL ? "" : "SNSD87372367")}
                        value={formData.uniquePersonalNumber}
                        onChange={handleChange}
                        disabled={!fromUnit && formData.type !== SEWADAL_TYPE.SEWADAL}
                        required
                    />
                </div>

                <div className="col-md-6 mt-4">
                    <label htmlFor="personalNumber" className="form-label">
                        Old P N <div className="astric"> * </div>
                    </label>
                    <input
                        type="text"
                        className="form-control"
                        id="personalNumber"
                        value={formData.personalNumber}
                        onChange={handleChange}
                        disabled={!fromUnit && formData.type !== SEWADAL_TYPE.SEWADAL}
                        required
                    />
                </div>
                {/* </div> */}

                {/* <div className="row"> */}
                <div className="col-md-6 mt-4">
                    <label htmlFor="name" className="form-label">
                        Name <div className="astric"> * </div>
                    </label>
                    <input
                        type="text"
                        className="form-control"
                        id="name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                    />
                </div>
                {!fromUnit && (
                    <div className="col-md-6 mt-4">
                        <label htmlFor="type" className="form-label">
                            Select Group <div className="astric"> * </div>
                        </label>
                        <select
                            id="groupNo"
                            className="form-select"
                            value={formData.groupNo}
                            onChange={handleChange}
                            required
                        >
                            <option value="">Select Group</option>
                            {group.map((group: GroupModel) => (
                                <option key={group.id} value={group.id}>
                                    {group.adhikariName + ' - ' + group.groupNo}
                                </option>
                            ))}
                        </select>
                    </div>
                )}

                <div className="col-md-6 mt-4">
                    <label htmlFor="gender" className="form-label">
                        Gender <div className="astric"> * </div>
                    </label>
                    <select
                        id="gender"
                        className="form-select"
                        value={formData.gender}
                        onChange={handleChange}
                        required
                    >
                        <option value="">Select Gender</option>
                        <option value="M">Male</option>
                        <option value="F">Female</option>
                    </select>
                </div>

                <div className="col-md-6 mt-4">
                    <label htmlFor="dateOfAdd" className="form-label">
                        DO Add <div className="astric"> * </div>
                    </label>
                    <input
                        type="date"
                        className="form-control"
                        id="dateOfAdd"
                        value={formData.dateOfAdd}
                        onChange={handleChange}
                        required
                    />
                </div>
                {/* </div> */}

                {/* <div className="row"> */}
                <div className="col-md-6 mt-4">
                    <label htmlFor="fathersName" className="form-label">
                        Father's Name <div className="astric"> * </div>
                    </label>
                    <input
                        type="text"
                        className="form-control"
                        id="fathersName"
                        value={formData.fathersName}
                        onChange={handleChange}
                        required
                    />
                </div>

                <div className="col-md-6 mt-4">
                    <label htmlFor="dateOfBirth" className="form-label">
                        DOB <div className="astric"> * </div>
                    </label>
                    <input
                        type="date"
                        className="form-control"
                        id="dateOfBirth"
                        value={formData.dateOfBirth}
                        onChange={handleChange}
                        required
                    />
                </div>
                {/* </div> */}

                {/* <div className="row"> */}
                <div className="col-md-6 mt-4">
                    <label htmlFor="qualification" className="form-label">
                        Qualification<div className="astric"> * </div>
                    </label>
                    <input
                        type="text"
                        className="form-control"
                        id="qualification"
                        value={formData.qualification}
                        onChange={handleChange}
                        required
                    />
                </div>

                <div className="col-md-6 mt-4">
                    <label htmlFor="occupation" className="form-label">
                        Occupation <div className="astric"> * </div>
                    </label>
                    <input
                        type="text"
                        className="form-control"
                        id="occupation"
                        value={formData.occupation}
                        onChange={handleChange}
                        required
                    />
                </div>
                {/* </div> */}

                {/* <div className="row"> */}
                <div className="col-md-6 mt-4">
                    <label htmlFor="address" className="form-label">
                        Address<div className="astric"> * </div>
                    </label>
                    <input
                        type="text"
                        className="form-control"
                        id="address"
                        value={formData.address}
                        onChange={handleChange}
                        required
                    />
                </div>

                <div className="col-md-6 mt-4">
                    <label htmlFor="contactNo" className="form-label">
                        Contact No.<div className="astric"> * </div>
                    </label>
                    <input
                        type="number"
                        className="form-control"
                        id="contactNo"
                        value={formData.contactNo}
                        onChange={handleChange}
                        required
                    />
                </div>
                {/* </div> */}

                {/* <div className="row"> */}
                <div className="col-md-6 mt-4">
                    <label htmlFor="bloodGroup" className="form-label">
                        Blood Group<div className="astric"> * </div>
                    </label>
                    <select
                        id="bloodGroup"
                        className="form-select"
                        value={formData.bloodGroup}
                        onChange={handleChange}
                        required
                    >
                        <option value="">Select Blood Group</option>
                        {Object.values(BLOOD_GROUP).map((bloodGroup) => (
                            <option key={bloodGroup} value={bloodGroup}>
                                {bloodGroup}
                            </option>
                        ))}
                    </select>
                </div>

                <div className="col-md-6 mt-4">
                    <label htmlFor="dateOfBadge" className="form-label ">DO Belt/Badges</label>
                    <input
                        type="date"
                        className="form-control "
                        id="dateOfBadge"
                        value={formData.dateOfBadge}
                        onChange={handleChange}
                        // disabled={!fromUnit && formData.type !== SEWADAL_TYPE.SEWADAL}

                    />
                </div>
                {/* </div> */}

                {/* <div className="row "> */}
                <div className="col-md-6 mt-4">
                    <label htmlFor="dateOfEntry" className="form-label ">Date Of Entry<div className="astric"> * </div> </label>
                    <input
                        type="date"
                        className="form-control "
                        id="dateOfEntry"
                        value={formData.dateOfEntry}
                        onChange={handleChange}
                        required
                    />
                </div>

                <div className="col-md-6 mt-4">
                    <label htmlFor="dateOfDeletion" className="form-label ">Date Of Deletion </label>
                    <input
                        type="date"
                        className="form-control "
                        id="dateOfDeletion"
                        value={formData.dateOfDeletion}
                        onChange={handleChange}
                    />
                </div>
                {/* </div> */}

                {/* <div className="row "> */}
                <div className="col-md-6 mt-4">
                    <label htmlFor="trade" className="form-label ">Trade </label>
                    <select
                        id="trade"
                        className="form-select"
                        value={formData.trade}
                        onChange={handleChange}
                    >
                        <option value="0">Select Trade</option>
                        <option value={"trade1"}>Trade 1</option>
                        <option value={"trade2"}>Trade 2</option>
                    </select>
                </div>

                <div className="col-md-6 mt-4">
                    <label htmlFor="email" className="form-label ">Email
                        {fromUnit && (
                            <div className="astric"> * </div>
                        )}
                    </label>
                    <input
                        type="email"
                        className="form-control "
                        id="email"
                        value={formData.email}
                        onChange={handleChange}
                        required={fromUnit}
                    />
                </div>
                {/* </div> */}

                {/* <div className="row "> */}
                <div className="col-md-6 mt-4">
                    <label htmlFor="uploadPhoto" className="form-label ">Upload Photo </label>
                    <input
                        type="text"
                        className="form-control "
                        id="uploadPhoto"
                        value={formData.uploadPhoto}
                        onChange={handleChange}
                    />
                </div>

                <div className="col-md-6 mt-4">
                    <label htmlFor="remarks" className="form-label ">remarks </label>
                    <input
                        type="text"
                        className="form-control "
                        id="remarks"
                        value={formData.remarks}
                        onChange={handleChange}
                    />
                </div>
                {/* </div> */}
                {/* </div> */}
                {fromUnit && (
                    <div className="row">
                        <div className="col-md-6 mt-4">
                            <label htmlFor="password" className="form-label">
                                Password <div className="astric"> * </div>
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                id="password"
                                value={formData.password}
                                onChange={handleChange}
                                required
                            />
                        </div>
                    </div>
                )}


                {/* Submit and Back Button */}
                <div className="d-flex justify-content-between align-items-center border-top pt-3 mt-4">
                    <button className="btn icon-btn" onClick={handleBack}>
                        <FontAwesomeIcon icon={faChevronLeft} /> Back
                    </button>
                    <button type="submit" className="btn btn-primary" >Submit</button>
                </div>
            </div>
        </form>
    );
};

export default SewadalForm;
