import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import "../FormsMainPage.css";

const Sd3BeltBadgeForm: React.FC = () => {
  // State for form data
  const [formData, setFormData] = useState({
    unitNumber: "12345",
    branch: "Branch A",
    khetra: "Kshetra Y",
    zone: "Zone 1",
  });

  // Hardcoded data for now
  useEffect(() => {
    const hardcodedData = {
      unitNumber: "12345",
      branch: "Branch A",
      khetra: "Kshetra Y",
      zone: "Zone 1",
    };

    setFormData(hardcodedData);
  }, []);

  const [tableData, setTableData] = useState([
    {
      name: "",
      post: "",
      personalNumber: "",
      beltCount: "",
      badgeCount: "",
      newMember: false, // Changed to boolean for checkbox
      ripped: false, // Changed to boolean for checkbox
      lost: "",
    },
  ]);

  const membersData = [
    {
      name: "John",
      post: "",
      personalNumber: "PN12345",
      beltCount: "",
      badgeCount: "",
      newMember: false,
      ripped: false,
      lost: "",
    },
    {
      name: "Jane",
      post: "",
      personalNumber: "PN54321",
      beltCount: "",
      badgeCount: "",
      newMember: false,
      ripped: false,
      lost: "",
    },
  ];

  const handleTableChange = (
    index: number,
    e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>
  ) => {
    const { name, value, type } = e.target;

    // Narrow down the target to handle the `checked` property for checkboxes
    const updatedTableData = [...tableData];

    if (type === "checkbox") {
      const checked = (e.target as HTMLInputElement).checked;
      updatedTableData[index] = {
        ...updatedTableData[index],
        [name]: checked, // Update for checkbox
      };
    } else {
      updatedTableData[index] = {
        ...updatedTableData[index],
        [name]: value, // Update for other input types
      };
    }

    setTableData(updatedTableData);
  };

  const handleNameChange = (
    index: number,
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const selectedName = e.target.value;
    const selectedMember = membersData.find(
      (member) => member.name === selectedName
    );
    const updatedTableData = [...tableData];
    if (updatedTableData[index]) {
      updatedTableData[index] = {
        ...updatedTableData[index],
        ...selectedMember,
      };
      setTableData(updatedTableData);
    }
  };

  const addRow = () => {
    setTableData([
      ...tableData,
      {
        name: "",
        post: "",
        personalNumber: "",
        beltCount: "",
        badgeCount: "",
        newMember: false,
        ripped: false,
        lost: "",
      },
    ]);
  };

  const deleteRow = (index: number) => {
    const updatedTableData = tableData.filter((_, i) => i !== index);
    setTableData(updatedTableData);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log("Form Data:", formData);
    console.log("Table Data:", tableData);
  };

  return (
    <div className="container-fluid mt-5">
      <div className="row justify-content-center mt-3">
        <div className="col-12 col-lg-12">
          <div className="card shadow">
            <div className="card-body">
              <legend className="sd3BBeltBadge">
                <b>SD-3 Belt Badge Form</b>
              </legend>
              <p>Please enter the necessary details for SD-3 Belt Badge.</p>

              <form onSubmit={handleSubmit}>
                <div className="row">
                  {/* Column 1 */}
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="unitNumber">Unit Number</label>
                      <input
                        type="text"
                        className="form-control"
                        id="unitNumber"
                        value={formData.unitNumber}
                        disabled
                      />
                    </div>
                    <div className="form-group mt-3">
                      <label htmlFor="branch">Branch</label>
                      <input
                        type="text"
                        className="form-control"
                        id="branch"
                        value={formData.branch}
                        disabled
                      />
                    </div>
                  </div>

                  {/* Column 2 */}
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="khetra">Kshetra</label>
                      <input
                        type="text"
                        className="form-control"
                        id="khetra"
                        value={formData.khetra}
                        disabled
                      />
                    </div>
                    <div className="form-group mt-3">
                      <label htmlFor="zone">Zone</label>
                      <input
                        type="text"
                        className="form-control"
                        id="zone"
                        value={formData.zone}
                        disabled
                      />
                    </div>
                  </div>
                </div>

                <div className="table-responsive mt-4">
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Post</th>
                        <th>Personal Number</th>
                        <th>Belt Count</th>
                        <th>Badges Count in Pair</th>
                        <th>New Recruitment</th>
                        <th>Bad/Ripped</th>
                        <th>Lost (F.I.R. No. & Date)</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {tableData.map((row, index) => (
                        <tr key={index}>
                          <td>
                            <select
                              name="name"
                              value={row.name}
                              onChange={(e) => handleNameChange(index, e)}
                            >
                              <option value="">Select Name</option>
                              {membersData.map((member) => (
                                <option key={member.name} value={member.name}>
                                  {member.name}
                                </option>
                              ))}
                            </select>
                          </td>
                          <td>
                            <input
                              type="text"
                              name="post"
                              value={row.post}
                              onChange={(e) => handleTableChange(index, e)}
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              name="personalNumber"
                              value={row.personalNumber}
                              onChange={(e) => handleTableChange(index, e)}
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              name="beltCount"
                              value={row.beltCount}
                              onChange={(e) => handleTableChange(index, e)}
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              name="badgeCount"
                              value={row.badgeCount}
                              onChange={(e) => handleTableChange(index, e)}
                            />
                          </td>
                          <td>
                            <input
                              type="checkbox"
                              name="newMember"
                              checked={row.newMember}
                              onChange={(e) => handleTableChange(index, e)}
                            />
                          </td>
                          <td>
                            <input
                              type="checkbox"
                              name="ripped"
                              checked={row.ripped}
                              onChange={(e) => handleTableChange(index, e)}
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              name="lost"
                              value={row.lost}
                              onChange={(e) => handleTableChange(index, e)}
                            />
                          </td>
                          <td>
                            <button className="addRow" type="button" onClick={addRow}>
                              <FontAwesomeIcon
                                icon={faPlus}
                                style={{ color: "#007bff" }}
                              />
                            </button>
                            {index > 0 && (
                              <button
                                className="deleteRow"
                                type="button"
                                onClick={() => deleteRow(index)}
                                style={{ marginLeft: "10px" }}
                              >
                                <FontAwesomeIcon
                                  icon={faTrash}
                                  style={{ color: "red" }}
                                />
                              </button>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>

                <div className="d-flex justify-content-end mt-4">
                  <button type="submit" className="btn btn-primary">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sd3BeltBadgeForm;
