// ./AddSatsang/index.tsx
import React, { useEffect, useState } from "react";
import "./index.css";
import Filter from "../Filter";
import { useTranslation } from "react-i18next";
import { AttendanceFilterDataModel } from "src/Common/Models/Attendance/FilterDataModel";
import { formatDate } from "src/utils/utils";
import {
  SatsangModel,
  SatsangResultDataModel,
} from "src/Common/Models/Satsang/SatsangModel";
import { useMutation } from "@tanstack/react-query";
import {
  DeleteSatsangByIdApi,
  GetSatsangApi,
  UndoDeleteSatsangByIdApi,
} from "src/api/satsangApi";
import {
  faSort,
  faSortUp,
  faSortDown,
  faPencilAlt,
  faTrash,
  faUndo,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { FILTER_INPUT_TYPE, FILTER_LISTING_TYPE } from "src/Common/AppEnum";

const Satsang = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [searchTerm, setSearchTerm] = useState<string>("");
  const [isFilterApplied, setIsFilterApplied] = useState<boolean>(true);
  const [filterData, setFilterData] = useState<any>({});
  const [sortConfig, setSortConfig] = useState<{
    key: string;
    direction: "ascending" | "descending" | "";
  }>({
    key: "satsangName",
    direction: "ascending",
  });
  const [satsangData, setSatsangData] = useState<SatsangModel[]>([]);
  const [filteredData, setFilteredData] = useState<SatsangModel[]>([]);

  const escapeRegExp = (value: string) => {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
  };

  useEffect(() => {
    getMutation.mutate(filterData);
  }, []);

  // const filterFields: FilterField[] = [
  //     {
  //         type: "select",
  //         label: t('select-satsang-type-label'),
  //         key: FILTER_INPUT_TYPE.SATSANG_TYPE,
  //         placeholder: t('select-satsang-type-label'),
  //         options: [
  //             { value: "Local", key: 1 },
  //             { value: "Morning", key: 2 },
  //             { value: "Naari Satsang", key: 3 },
  //             { value: "Baal Satsang", key: 4 },
  //             { value: "Sewadal Camp", key: 5 },
  //         ],
  //         dependentIndex: -1
  //     }
  // ];

  const getMutation = useMutation<SatsangResultDataModel, unknown, any>({
    mutationFn: GetSatsangApi,
    onSuccess: (data: SatsangResultDataModel) => {
      setSatsangData(data.satsangs);
      setFilteredData([]);
      setIsFilterApplied(true);
    },
    onError: (error: any) => {
      console.error("Api failed:", error.response?.data || error.message);
    },
  });

  const deleteMutation = useMutation({
    mutationFn: DeleteSatsangByIdApi,
    onSuccess: (data: SatsangResultDataModel) => {
      getMutation.mutate(filterData);
    },
    onError: (error: any) => {
      console.error("Api failed:", error.response?.data || error.message);
    },
  });

  const undoDeleteMutation = useMutation({
    mutationFn: UndoDeleteSatsangByIdApi,
    onSuccess: (data: SatsangResultDataModel) => {
      getMutation.mutate(filterData);
    },
    onError: (error: any) => {
      console.error("Api failed:", error.response?.data || error.message);
    },
  });

  const deleteSatsang = (id: number | undefined, isActive: boolean = true) => {
    if (id) {
      isActive ? deleteMutation.mutate(id) : undoDeleteMutation.mutate(id);
    }
  };

  const handleFilterApply = (appliedFilters: {
    [key: string]: string | number;
  }) => {
    let selectedFilter = {
      unitNo: Number(appliedFilters.unitNo),
      satsangTypeId: Number(appliedFilters.satsangTypeId),
    };
    setFilterData(selectedFilter);
    getMutation.mutate(selectedFilter);
  };

  const handleReset = () => {
    setIsFilterApplied(false);
    setFilterData(new AttendanceFilterDataModel(formatDate(new Date())));
  };

  const handleSearch = (searchTerm: string) => {
    setSearchTerm(searchTerm);

    const searchRegex = new RegExp(escapeRegExp(searchTerm), "i");

    const filteredRows = satsangData.filter((row) => {
      return Object.keys(row).some((field) => {
        // @ts-ignore

        return searchRegex.test(row[field]?.toString());
      });
    });
    if (searchTerm.length > 0) {
      setFilteredData(filteredRows);
    } else {
      setFilteredData([]);
    }
  };

  const handleSort = (key: string) => {
    let direction: "ascending" | "descending" = "ascending";

    // Toggle sorting direction
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }

    // Update the sorting configuration
    setSortConfig({ key, direction });

    // Sorting logic for both 'data' and 'filteredData'
    const sortFunction = (a: SatsangModel, b: SatsangModel) => {
      if (a[key as keyof SatsangModel]! < b[key as keyof SatsangModel]!) {
        return direction === "ascending" ? -1 : 1;
      }
      if (a[key as keyof SatsangModel]! > b[key as keyof SatsangModel]!) {
        return direction === "ascending" ? 1 : -1;
      }
      return 0;
    };

    // Sort the main data and the filtered data
    const sortedData = [...satsangData].sort(sortFunction);
    const sortedFilteredData = [...filteredData].sort(sortFunction);

    // Update both states with sorted data
    setSatsangData(sortedData);
    setFilteredData(sortedFilteredData);
  };

  const getSortIcon = (key: string) => {
    if (sortConfig.key !== key) {
      return <FontAwesomeIcon icon={faSort} />;
    }
    return sortConfig.direction === "ascending" ? (
      <FontAwesomeIcon icon={faSortUp} />
    ) : (
      <FontAwesomeIcon icon={faSortDown} />
    );
  };

  const navigateToAddSatsang = () => {
    navigate("/satsang/add");
  };

  const navigateToEditSatsang = (satsangId: number | undefined) => {
    if (satsangId)
      navigate(`/satsang/add`, {
        state: { satsangId }, // Pass the satsangId as state
      });
  };
  return (
    <div>
      <div className="satsang-container">
        <div className="row mb-1 align-items-center">
          <div className="col-md-6">
            <h3>{t("satsang.satsang-title-label")}</h3>
          </div>
          <div className="col-md-6 d-flex justify-content-end">
            <button
              type="button"
              className="btn btn-secondary "
              aria-label="Add Satsang"
              onClick={navigateToAddSatsang}
            >
              {t("satsang.add-satsang-btn-label")}
            </button>
          </div>
        </div>

        <Filter
          onlySearch={true}
          filterType={FILTER_LISTING_TYPE.ATTENDANCE}
          onApply={handleFilterApply}
          onReset={handleReset}
          onSearch={handleSearch}
          searchPlaceholder={t("search-only-name-placeholer-label")}
        />

        <div className="table-responsive">
          {isFilterApplied && (
            <table className="table table-bordered">
              <thead className="tableHeading">
                <tr>
                  <th onClick={() => handleSort("name")}>
                    {t("name-label")} {getSortIcon("name")}
                  </th>
                  <th onClick={() => handleSort("location")}>
                    {t("location-label")} {getSortIcon("location")}
                  </th>
                  <th>{t("satsang-type-label")}</th>
                  <th>{t("action-label")}</th>
                </tr>
              </thead>
              <tbody>
                {(searchTerm || filteredData.length > 0
                  ? filteredData
                  : satsangData
                ).map((row, i) => (
                  <tr key={i} className={!row.isActive ? "deleted-row" : ""}>
                    <td>{row.name}</td>
                    <td>{row.location}</td>
                    <td>{row?.satsangTypes?.length}</td>
                    <td>
                      <FontAwesomeIcon
                        className="icon-btn"
                        icon={faPencilAlt}
                        onClick={() => navigateToEditSatsang(row.id)}
                      />
                      <FontAwesomeIcon
                        className="mx-3 icon-btn"
                        icon={row.isActive ? faTrash : faUndo}
                        onClick={() => deleteSatsang(row.id, row.isActive)}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
          {isFilterApplied && searchTerm && filteredData.length === 0 && (
            <div className="d-flex j-center">
              {t("no-results-found-help-text")}
            </div>
          )}
          {!isFilterApplied && (
            <div className="d-flex j-center help-text">
              {t("apply-filter-help-text")}
            </div>
          )}
          {isFilterApplied && satsangData.length === 0 && (
            <div className="d-flex j-center help-text">
              {t("satsang.no-satsang-record-found")}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Satsang;
