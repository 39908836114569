// ./Common/ApiRoutes.ts
export const API_ROUTES = {
  // auth
  LOGIN: "/auth/login",

  // attendance
  GET_ATTENDANCE: "/attendance",
  MARK_ATTENDANCE: "/attendance/mark",
  DASHBOARD_ATTENDANCE_COUNT: "/dashboard/attendance-data",

  // Satsang
  SATSANG: "/satsang",
  UNDO_SATSANG_DELETE: "/satsang/add-back",
  SEWADAL_KEY_VALUE: "/sewadal-key-value",

  // Filter
  FILTER: "/filter",
  FILTER_ADHIKARI_KEY_VALUE: "/filter/adhikari-key-value",


  // UNITS
  GET_UNITS: "units",
  GET_UNIT: "unit",
  UNIT: "unit",
  UNIT_BULK_UPLOAD: "/unit/bulk",

  // Group
  GROUP: "group",

  // Sewadal
  GET_SEWADAL: "sewadal",
  SEWADAL: "sewadal",
  SEWADAL_BULK_UPLOAD: "/sewadal/bulk",
  ADD_ATTENDANCE_USER: "/sewadal/attendance",

  // Reports
  REPORTS: "reports",
  REPORTS_EXPORT: "reports/export",

  //SEWADAL_FORMS
  SEWADAL_FORMS: "form",
  SEWADAL_FORMS_DETAILS: "formDetails",
  SEWADALFORMS: "sewadalForms",
};
