import React, { useState } from 'react';
import './UploadUnitBulk.css';
import { useNavigate } from 'react-router-dom';
import { UnitBulkUploadApi, UnitDownloadBulkUploadFileApi } from 'src/api/unitApi';
import { useMutation } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { hideLoader, showLoader } from 'src/redux/loaderSlice';

const UploadUnitBulk: React.FC = () => {
    const navigate = useNavigate();
    const [file, setFile] = useState<File | null>(null);
    const dispatch = useDispatch();

    const navigateToAddSingle = () => {
        navigate('/unit/add');
    }

    const postMutation = useMutation<void, Error, File>({
        mutationFn: UnitBulkUploadApi,
        onSuccess: () => {
            navigate('/success');
            dispatch(hideLoader());
        },
        onError: (error: any) => {
            dispatch(hideLoader());
            console.error("Api failed:", error.response?.data || error.message);
        }
    });

    const downloadSampleFileMutation = useMutation({
        mutationFn: UnitDownloadBulkUploadFileApi,
        onSuccess: (data: Blob) => {
            const url = window.URL.createObjectURL(new Blob([data]));

            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'sample_file.xlsx');

            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);
        },
        onError: (error: any) => {
            console.error("Api failed:", error.response?.data || error.message);
        }
    });

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFile = event.target.files?.[0];
        if (selectedFile) {
            setFile(selectedFile);
        }
    };

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault(); // Prevent default form submission
        if (file) {
            dispatch(showLoader());
            postMutation.mutate(file)
        }
    };

    const handleDownloadSample = () => {
        downloadSampleFileMutation.mutate();
    }

    return (<div className="container-fluid mt-5">
        <div className="row justify-content-center mt-3">
            <div className="col-12 col-lg-12">
                <div className="card shadow">
                    <div className="card-body p-4 ">
                        <legend> <b> Create Unit </b></legend>
                        <form onSubmit={handleSubmit}>

                            <div>
                                <div className='col mt-4 mb-3'>Upload Unit List</div>
                                <input
                                    type="file"
                                    accept=".xlsx, .xls"
                                    onChange={handleFileChange}
                                    style={{ display: 'none' }}
                                    id="fileUpload"
                                />
                                <label className="col-md-12 btn btn-light py-5 text-secondary" htmlFor="fileUpload"><b>Click to upload files here</b></label>
                            </div>
                            {/* Show the selected file name */}
                            {file && (
                                <div className="mb-3">
                                    <span className="text-success"><b>Selected File:</b> {file.name}</span>
                                </div>
                            )}

                            <div className="row">
                                <div className="col d-flex justify-content-end align-items-center mt-4">
                                    <button type="button" className="btn btn-outline-dark" onClick={handleDownloadSample}>Download Sample file</button>
                                </div>
                            </div>

                            <div className="d-flex align-items-center my-4">
                                <hr className="flex-grow-1 border-top hr" />
                                <span className="px-3 text-muted">OR</span>
                                <hr className="flex-grow-1 border-top hr" />
                            </div>
                            <div>
                                <div>
                                    Add Unit Manually
                                </div>
                                <div>
                                    <button type="submit" className="btn btn-primary mt-3 mauallyUpoladBtn" onClick={navigateToAddSingle}>Click here to add Unit details</button>
                                </div>
                            </div>


                            <div className="d-flex justify-content-end border-top pt-3 mt-4">
                                <button type="submit" className="btn btn-primary">Done</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>)
}

export default UploadUnitBulk;
