import React, { useState } from 'react';
import './UploadSewadalBulk.css';
import { useMutation } from '@tanstack/react-query';
import { SewadalBulkUploadApi, SewadalDownloadBulkUploadFileApi } from 'src/api/sewadalApi';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { hideLoader, showLoader } from 'src/redux/loaderSlice';

interface UploadSewadalBulkData {
    addManual: () => void;
}

const UploadSewadalBulk: React.FC<UploadSewadalBulkData> = ({ addManual }: UploadSewadalBulkData) => {
    const [file, setFile] = useState<File | null>(null);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFile = event.target.files?.[0];
        if (selectedFile) {
            setFile(selectedFile);
        }
    };
    const postMutation = useMutation<void, Error, File>({
        mutationFn: SewadalBulkUploadApi,
        onSuccess: () => {
            dispatch(hideLoader());
            navigate('/success');
        },
        onError: (error: any) => {
            dispatch(hideLoader());
            console.error("Api failed:", error.response?.data || error.message);
        }
    });

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault(); // Prevent default form submission
        if (file) {
            dispatch(showLoader());
            postMutation.mutate(file)
        }
    };

    const downloadSampleFileMutation = useMutation({
        mutationFn: SewadalDownloadBulkUploadFileApi,
        onSuccess: (data: Blob) => {
            const url = window.URL.createObjectURL(new Blob([data]));

            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'sample_sewadal_bulk_upload_file.xlsx');

            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);
        },
        onError: (error: any) => {
            console.error("Api failed:", error.response?.data || error.message);
        }
    });

    const handleDownloadSample = () => {
        downloadSampleFileMutation.mutate();
    }


    return (<div className="container-fluid mt-5">

        <div className="row justify-content-center mt-3">
            <div className="col-12 col-lg-12">
                <div className="">
                    <div className="">
                        {/* <legend> <b> Add Sewadal </b></legend> */}
                        <form onSubmit={handleSubmit}>
                            <div className="row">
                                {/* <div className="col-md-6">
                                    <label htmlFor="doAdd" className="form-label">Sewadal Unit No.</label>
                                    <input
                                        type="text"
                                        className="form-control py-3"
                                        id="doAdd"
                                        required
                                    />
                                </div> */}

                                <div className="col d-flex justify-content-end align-items-center mt-4">
                                    <button type="button" onClick={handleDownloadSample} className="btn btn-outline-dark">Download Sample file</button>
                                </div>
                            </div>


                            <div>
                                <div className='col mt-4 mb-3'>Upload Sewadal List</div>
                                {/* <button type="button" className="col-md-12 btn btn-light py-5 text-secondary"><b>CLick to upload files here</b></button> */}
                                <input
                                    type="file"
                                    accept=".xlsx, .xls"
                                    onChange={handleFileChange}
                                    style={{ display: 'none' }}
                                    id="fileUpload"
                                />
                                <label className="col-md-12 btn btn-light py-5 text-secondary" htmlFor="fileUpload"><b>Click to upload files here</b></label>
                            </div>

                            {file && (
                                <div className="mb-3">
                                    <span className="text-success"><b>Selected File:</b> {file.name}</span>
                                </div>
                            )}

                            <div className="d-flex align-items-center my-4">
                                <hr className="flex-grow-1 border-top hr" />
                                <span className="px-3 text-muted">OR</span>
                                <hr className="flex-grow-1 border-top hr" />
                            </div>

                            <div>
                                <div>
                                    Add Sewadal Manually
                                </div>
                                <div>
                                    <button type="button" onClick={addManual} className="btn btn-primary mt-3 mauallyUpoladBtn">Click here to add sewadal details</button>
                                </div>
                            </div>


                            <div className="d-flex justify-content-end border-top pt-3 mt-4">
                                <button type="submit" className="btn btn-primary">Done</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>)
}

export default UploadSewadalBulk;
