import 'bootstrap/dist/css/bootstrap.min.css';
import './FormsMainPage.css';

// import Breadcrumb  from '../Dashboard/Breadcrumbs/Breadcrumbs';
import React, { useState } from 'react';

import {
  QueryClient,
  QueryClientProvider,
} from 'react-query';

import Sd1AUnitCreationForm from './SD1Form/Sd1AUnitCreationForm';
import Sd1EnrolmentForm from './SD1Form/Sd1EnrolmentForm';
import Sd2ProposalForm_G from './SD2Forms/Sd2ProposalForm(G)';
import Sd2ProposalForm_L from './SD2Forms/Sd2ProposalForm(L)';
import Sd3BeltBadgeForm from './SD3Forms/Sd3BeltBadgeForm';
import Sd3AUniformClothForm from './SD3Forms/Sd3UniformClothsForm';
import Sd4DeletionForm from './SD4Forms/Sd4DeletionForm';
import Sd5TransferForm from './SD5Forms/Sd5TransferForm';
import Sd6RetiretmentForm from './SD6Forms/Sd6RetiretmentForm';
import Sd7VisitReportForm from './SD7Forms/Sd7VisitReportForm';

const FormsMainPage: React.FC = () => {
  const [selectedForm, setSelectedForm] = useState<string>("");
	const queryClient = new QueryClient();

  const formsOptions = [
    { value: "sd1", label: "Sd-1 Enrolment Form" },
    { value: "sd1A", label: "Sd-1-A New Unit Created Form" },
    { value: "sd2G", label: "Sd-2 (G) Proposal Form" },
    { value: "sd2L", label: "Sd-2 (L) Proposal Form" },
    { value: "sd3A", label: "SD-3 (A) Uniform Cloth Form" },
    { value: "sd3B", label: "SD-3 Belt & Badges Form" },
    { value: "sd4", label: "Sd-4 Deletion Form" },
    { value: "sd5", label: "Sd-5 Transfer Information Form" },
    { value: "sd6B", label: "Sd-6-B Retirement Form for Adhikaries" },
    { value: "sd7", label: "Sd-7 Visit Report 010824" },
  ];

  const handleFormChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedForm(event.target.value);
  };

  // Conditional rendering for the form components
  const renderFormComponent = () => {
    switch (selectedForm) {
      case "sd1":
        return <Sd1EnrolmentForm />;
      case "sd1A":
        return <Sd1AUnitCreationForm />;
      case "sd2G":
        return <Sd2ProposalForm_G />;
      case "sd2L":
        return <Sd2ProposalForm_L />;
      case "sd3A":
        return <Sd3AUniformClothForm />;
      case "sd3B":
        return <Sd3BeltBadgeForm />;
      case "sd4":
        return <Sd4DeletionForm />;
      case "sd5":
        return <Sd5TransferForm />;
      case "sd6B":
        return <Sd6RetiretmentForm />;
      case "sd7":
        return <Sd7VisitReportForm />;
      default:
        // return <SdFormsListing />;
    }
  };

  return (
    <div className="container-fluid mt-5">
      <div className="row justify-content-center mt-3">
        <div className="card shadow">
          <div className="card-body p-4">
            <legend className="SewadalForms">
              <b>Sewadal Forms</b>
            </legend>
            <div className="col-md-6 mt-4" style={{ marginLeft: "0.8%" }}>
              <label htmlFor="selectedForm" className="form-label">
                Select Sewadal Form:
              </label>
              <select
                id="selectedForm"
                className="form-select py-3"
                value={selectedForm}
                onChange={handleFormChange}
              >
                <option value="">-- Select Form --</option>
                {formsOptions.map((form) => (
                  <option key={form.value} value={form.value}>
                    {form.label}
                  </option>
                ))}
              </select>
            </div>

            <div className="mt-4">
              	<QueryClientProvider client={queryClient}>
                {renderFormComponent()} {/* Render the selected form */}
                </QueryClientProvider>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormsMainPage;
