import React, {
    useEffect,
    useState,
} from 'react';

import { useSelector } from 'react-redux';
import {
    Navigate,
    Route,
    Routes,
} from 'react-router-dom';

import { BitwisePermissions } from './Common/PermissionEnum';
import Attendance from './Component/Attendance';
import Login from './Component/Auth/Login';
import Dashboard from './Component/Dashboard/Dashboard';
import FollowUpPage from './Component/FollowUpSection/FollowUpCard';
import FollowUpList from './Component/FollowUpSection/FollowUpList';
import Group from './Component/Group';
import Layout from './Component/Layout/Layout'; // Import the Layout component
import PageNotFound from './Component/PageNotFound';
import Reports from './Component/Reports';
import Satsang from './Component/Satsang';
import AddSatsang from './Component/Satsang/AddSatsang';
import Sd1EnrolmentForm
    from './Component/SewadalForms/SD1Form/Sd1EnrolmentForm';
import SdFormsListing
    from './Component/SewadalForms/SDFormsListing/SdFormsListing';
import Toaster from './Component/Toast';
import AddSewadalDetails
    from './Component/Unit/AddSewadalDetails/AddSewadalDetails';
//   import AddUnit from './Component/Unit/AddUnit/AddUnit';
import SewadalDetails from './Component/Unit/SewadalDetails/SewadalDetails';
import Units from './Component/Unit/Units/Units';
import UnitSuccess from './Component/Unit/UnitSuccess/UnitSuccess';
import UploadUnitBulk from './Component/Unit/UploadUnit/UploadUnitBulk';
import ProtectedRoute
    from './ProtectedRoute'; // Import the ProtectedRoute component
import { RootState } from './redux/store';
import { AddUnitApi } from './api/unitApi';
import AddUnit from './Component/Unit/AddUnit/AddUnit';
import DutyChart from './Component/DutyCharts/DutyChart';

// import SewadalDetails from './Component/Unit/SewadalDetails/SewdalDetails';

// import SewadalDetails from './Component/Unit/SewadalDetails/SewdalDetails';

const App: React.FC = () => {
    const { isAuthenticated, user } = useSelector((state: RootState) => state.auth);

    const [sidebarOpen, setSidebarOpen] = useState<boolean>(false); // Sidebar closed initially for mobile

    const toggleSidebar = () => setSidebarOpen(!sidebarOpen);
    const loading = useSelector((state: RootState) => state.loader.loading);

    // Detect screen width for mobile vs desktop view
    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth >= 768) {
                setSidebarOpen(true); // Open sidebar for web view (larger screens)
            } else {
                setSidebarOpen(false); // Close sidebar for mobile view
            }
        };

        window.addEventListener("resize", handleResize);
        handleResize(); // Initial check when component mounts

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
        <>
            {loading && (
                <div className="spinner-overlay">
                    <div className="spinner"></div>
                </div>
            )}
            <div className={loading ? 'fade-content' : ''}>
                {/* <div> */}
                <Toaster />
                {/* </div> */}
                <Routes>
                    <Route
                        path="/"
                        element={isAuthenticated ? <Navigate to="/dashboard" /> : <Login />}
                    />

                    {/* Wrap protected routes in the Layout component */}
                    <Route
                        path="/dashboard"
                        element={
                            <ProtectedRoute userType={user?.userType} requiredPermission={BitwisePermissions.DASHBOARD}>
                                <Layout
                                    sidebarOpen={sidebarOpen}
                                    toggleSidebar={toggleSidebar}
                                    userType={user?.userType}>
                                    <Dashboard userType={user?.userType} />
                                </Layout>
                            </ProtectedRoute>
                        }
                    />

                    <Route
                        path="/attendance"
                        element={
                            <ProtectedRoute
                                userType={user?.userType} requiredPermission={BitwisePermissions.MARK_ATTENDANCE}>
                                <Layout
                                    sidebarOpen={sidebarOpen}
                                    toggleSidebar={toggleSidebar}
                                    userType={user?.userType}>
                                    <Attendance />
                                </Layout>
                            </ProtectedRoute>
                        }
                    />

                    <Route
                        path="/satsang/add"
                        element={
                            <ProtectedRoute userType={user?.userType} requiredPermission={BitwisePermissions.SATSANG}>
                                <Layout
                                    sidebarOpen={sidebarOpen}
                                    toggleSidebar={toggleSidebar}
                                    userType={user?.userType}>
                                    <AddSatsang />
                                </Layout>
                            </ProtectedRoute>
                        }
                    />


                    <Route
                        path="/satsang/add"
                        element={
                            <ProtectedRoute userType={user?.userType} requiredPermission={BitwisePermissions.SATSANG}>
                                <Layout
                                    sidebarOpen={sidebarOpen}
                                    toggleSidebar={toggleSidebar}
                                    userType={user?.userType}>
                                    <AddSatsang />
                                </Layout>
                            </ProtectedRoute>
                        }
                    />

                    <Route
                        path="/satsang"
                        element={
                            <ProtectedRoute userType={user?.userType} requiredPermission={BitwisePermissions.SATSANG}>
                                <Layout
                                    sidebarOpen={sidebarOpen}
                                    toggleSidebar={toggleSidebar}
                                    userType={user?.userType}>
                                    <Satsang />
                                </Layout>
                            </ProtectedRoute>
                        }
                    />

                    <Route
                        path="/units"
                        element={
                            <ProtectedRoute userType={user?.userType} requiredPermission={BitwisePermissions.UNIT}>
                                <Layout
                                    sidebarOpen={sidebarOpen}
                                    toggleSidebar={toggleSidebar}
                                    userType={user?.userType}>
                                    <Units />
                                </Layout>
                            </ProtectedRoute>
                        }
                    />

                    <Route
                        path="/unit/add"

                        element={
                            <ProtectedRoute userType={user?.userType} requiredPermission={BitwisePermissions.UNIT}>
                                <Layout
                                    sidebarOpen={sidebarOpen}
                                    toggleSidebar={toggleSidebar}
                                    userType={user?.userType}>
                                    <AddUnit />
                                </Layout>
                            </ProtectedRoute>
                        }
                    />

                    <Route
                        path="/unit-upload-bulk"
                        element={
                            <ProtectedRoute userType={user?.userType} requiredPermission={BitwisePermissions.UNIT}>
                                <Layout
                                    sidebarOpen={sidebarOpen}
                                    toggleSidebar={toggleSidebar}
                                    userType={user?.userType}>
                                    <UploadUnitBulk />
                                </Layout>
                            </ProtectedRoute>
                        }
                    />

                    <Route
                        path="/success"
                        element={
                            <ProtectedRoute userType={user?.userType} requiredPermission={null}>
                                <Layout
                                    sidebarOpen={sidebarOpen}
                                    toggleSidebar={toggleSidebar}
                                    userType={user?.userType}>
                                    <UnitSuccess />
                                </Layout>
                            </ProtectedRoute>
                        }
                    />

                    <Route
                        path="/sewadal/add"
                        element={
                            <ProtectedRoute userType={user?.userType} requiredPermission={BitwisePermissions.SEWADAL}>
                                <Layout
                                    sidebarOpen={sidebarOpen}
                                    toggleSidebar={toggleSidebar}
                                    userType={user?.userType}>
                                    <AddSewadalDetails />
                                </Layout>
                            </ProtectedRoute>
                        }
                    />

                    {/* <Route path="/sewadal/upload-bulk" element={
              <ProtectedRoute userType={user?.userType} requiredPermission={BitwisePermissions.SEWADAL}>
                <Layout sidebarOpen={sidebarOpen} toggleSidebar={toggleSidebar} userType={user?.userType}>
                  <UploadSewadalBulk />
                </Layout>
              </ProtectedRoute>
            } /> */}

                    <Route
                        path="/groups"
                        element={
                            <ProtectedRoute userType={user?.userType} requiredPermission={BitwisePermissions.GROUP}>
                                <Layout
                                    sidebarOpen={sidebarOpen}
                                    toggleSidebar={toggleSidebar}
                                    userType={user?.userType}>
                                    <Group />
                                </Layout>
                            </ProtectedRoute>
                        }
                    />

                    <Route
                        path="/sewadalForms/allSubmittedForms"
                        element={
                            <ProtectedRoute userType={user?.userType} requiredPermission={null}>
                                <Layout
                                    sidebarOpen={sidebarOpen}
                                    toggleSidebar={toggleSidebar}
                                    userType={user?.userType}>
                                    <SdFormsListing />
                                </Layout>
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/sewadalForms/SD1"
                        element={
                            <ProtectedRoute userType={user?.userType} requiredPermission={null}>
                                <Layout
                                    sidebarOpen={sidebarOpen}
                                    toggleSidebar={toggleSidebar}
                                    userType={user?.userType}>
                                    <Sd1EnrolmentForm
                                    />
                                </Layout>
                            </ProtectedRoute>
                        }
                    />
                    <Route path="/sewadalForms" element={
                        <ProtectedRoute userType={user?.userType} requiredPermission={null}>
                            <Layout
                                sidebarOpen={sidebarOpen}
                                toggleSidebar={toggleSidebar}
                                userType={user?.userType}
                            >
                                {/* <FollowUpList /> */}
                                <FollowUpPage />
                                {/* <FormsMainMage /> */}
                            </Layout>
                        </ProtectedRoute>
                    }
                    />

                    <Route
                        path="/followUpListAdhikari"
                        element={
                            <ProtectedRoute userType={user?.userType} requiredPermission={null}>
                                <Layout
                                    sidebarOpen={sidebarOpen}
                                    toggleSidebar={toggleSidebar}
                                    userType={user?.userType}>
                                    <FollowUpList />
                                </Layout>
                            </ProtectedRoute>
                        }
                    />

                    <Route
                        path="/sewadal"
                        element={
                            <ProtectedRoute userType={user?.userType} requiredPermission={BitwisePermissions.SEWADAL}>
                                <Layout
                                    sidebarOpen={sidebarOpen}
                                    toggleSidebar={toggleSidebar}
                                    userType={user?.userType}>
                                    <SewadalDetails />
                                </Layout>
                            </ProtectedRoute>
                        }
                    />

                    <Route
                        path="/reports"
                        element={
                            <ProtectedRoute userType={user?.userType} requiredPermission={BitwisePermissions.REPORT}>
                                <Layout
                                    sidebarOpen={sidebarOpen}
                                    toggleSidebar={toggleSidebar}
                                    userType={user?.userType}>
                                    <Reports userType={user?.userType} />
                                </Layout>
                            </ProtectedRoute>
                        }
                    />

                    <Route
                        path="/entry/dutyChart"
                        element={
                            <ProtectedRoute userType={user?.userType} requiredPermission={BitwisePermissions.REPORT}>
                                <Layout
                                    sidebarOpen={sidebarOpen}
                                    toggleSidebar={toggleSidebar}
                                    userType={user?.userType}>
                                    <DutyChart />
                                </Layout>
                            </ProtectedRoute>
                        }
                    />

                    <Route
                        path="*"
                        element={
                            <ProtectedRoute userType={user?.userType} requiredPermission={null}>
                                <Layout
                                    sidebarOpen={sidebarOpen}
                                    toggleSidebar={toggleSidebar}
                                    userType={user?.userType}>
                                    <PageNotFound />
                                </Layout>
                            </ProtectedRoute>
                        }
                    />


                    <Route
                        path="/unauthorized"
                        element={<div>You are not authorized to access this page.</div>}
                    />
                </Routes>
            </div>
        </>
    );

};

export default App;