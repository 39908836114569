// ./AddSatsang/index.tsx
import React, { useEffect, useState } from 'react';
import "./index.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { SatsangModel, SatsangTypeModel } from 'src/Common/Models/Satsang/SatsangModel';
import { AddSatsangApi, GetSatsangByIdApi, UpdateSatsangByIdApi } from 'src/api/satsangApi';
import { useMutation } from '@tanstack/react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const AddSatsang = () => {

    const location = useLocation();
    const satsangId = location.state?.satsangId || null;
    const [satsangName, setSatsangName] = useState('');
    const [satsangLocation, setSatsangLocation] = useState('');
    const [satsangData, setSatsangData] = useState<SatsangModel>(new SatsangModel());
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [satsangTypes, setSatsangTypes] = useState<SatsangTypeModel[]>([new SatsangTypeModel()]);

    const getMutation = useMutation<SatsangModel, unknown, number>({
        mutationFn: (satsangId: number) => GetSatsangByIdApi(satsangId),
        onSuccess: (data: SatsangModel) => {
            setSatsangData(data);  // Handle the successful data response
            console.log(satsangData);
            setSatsangName(data.name);
            setSatsangLocation(data.location);
            setSatsangTypes(data.satsangTypes);
        },
        onError: (error: any) => {
            console.error("API failed:", error.response?.data || error.message);
        },
    });

    useEffect(() => {
        if (satsangId) {
            getMutation.mutate(satsangId);
        }
    }, [satsangId]);



    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, field: string) => {
        const value = e.target.value;
        if (field === 'name') {
            setSatsangName(value);
        } else if (field === 'location') {
            setSatsangLocation(value);
        }
    };

    // Handle input change for satsang types
    const handleSatsangTypeChange = (index: number, field: string, value: string) => {
        const updatedTypes: any[] = [...satsangTypes];
        updatedTypes[index][field] = value;
        setSatsangTypes(updatedTypes);
    };

    // Add new satsang type row
    const addSatsangType = () => {
        setSatsangTypes([...satsangTypes, { name: '', timeFrom: '', timeTo: '' }]);
    };

    // Remove satsang type row
    const removeSatsangType = (index: number) => {
        const updatedTypes = satsangTypes.filter((_, i) => i !== index);
        setSatsangTypes(updatedTypes);
    };

    const postMutation = useMutation({
        mutationFn: AddSatsangApi,
        onSuccess: () => {
            resetForm()
        },
        onError: (error: any) => {
            console.error("Api failed:", error.response?.data || error.message);
        }
    });

    const updateMutation = useMutation({
        mutationFn: ({ id, data }: { id: number; data: SatsangModel }) => UpdateSatsangByIdApi(id, data),
        onSuccess: () => {
            resetForm();
            navigateBack();
        },
        onError: (error: any) => {
            console.error("Api failed:", error.response?.data || error.message);
        }
    });

    const resetForm = () => {
        setSatsangLocation("");
        setSatsangName("");
        setSatsangTypes([new SatsangTypeModel()]);
    }

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        const satsangData = {
            name: satsangName,
            location: satsangLocation,
            satsangTypes: satsangTypes,
        };
        if (satsangId) {
            updateMutation.mutate({ id: satsangId, data: satsangData });
        } else {
            postMutation.mutate(satsangData);  // Call add API
        }
    }

    const navigateBack = () => {
        navigate(-1);
    }
    return (
        <div>        
        <div className='satsangContainer'>
            <div className='formContainer'>
                <h3>{t(satsangId ? 'satsang.edit-satsang-btn-label' : 'satsang.add-satsang-btn-label')}</h3>

                <form onSubmit={handleSubmit}>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="mb-2">
                                <label className="form-label">Satsang Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={satsangName}
                                    onChange={(e) => handleInputChange(e, 'name')}
                                    required
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mb-2">
                                <label className="form-label">Satsang Location</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={satsangLocation}
                                    onChange={(e) => handleInputChange(e, 'location')}
                                    required
                                />
                            </div>
                        </div>
                    </div>
                    <div className='satsang-type-form'>
                        <h4>Add Satsang Types</h4>
                        {satsangTypes.map((type, index) => (
                            <div className="row align-center" key={index}>
                                <div className="col-md-5">
                                    <div className="mb-2">
                                        <label className="form-label">Satsang Type</label>
                                        {/* <input
                                            type="text"
                                            className="form-control"
                                            value={type.name}
                                            onChange={(e) => handleSatsangTypeChange(index, 'name', e.target.value)}
                                            required
                                        /> */}
                                        <select
                                            className="form-select"
                                            value={type.name}
                                            onChange={(e) => handleSatsangTypeChange(index, 'name', e.target.value)}
                                            required
                                        >
                                            <option value={0}>Select Satsang Type</option>
                                            <option value="local satsang">Local Satsang</option>
                                            <option value="morning satsang">Morning Satsang</option>
                                            <option value="naari satsang">Naari Satsang</option>
                                            <option value="baal satsang">Baal Satsang</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-6 col-md-3">
                                    <div className="mb-2">
                                        <label className="form-label">From</label>
                                        <input
                                            type="time"
                                            className="form-control"
                                            value={type.timeFrom}
                                            onChange={(e) => handleSatsangTypeChange(index, 'timeFrom', e.target.value)}
                                            required
                                        />
                                    </div>
                                </div>

                                <div className="col-6 col-md-3">
                                    <div className="mb-2">
                                        <label className="form-label">To</label>
                                        <input
                                            type="time"
                                            className="form-control"
                                            value={type.timeTo}
                                            onChange={(e) => handleSatsangTypeChange(index, 'timeTo', e.target.value)}
                                            required
                                        />
                                    </div>
                                </div>

                                <div className='col-12 col-md-1 mt-2'>
                                    {satsangTypes.length > 1 && (
                                        <FontAwesomeIcon
                                            className=' icon-btn delete-icon'
                                            icon={faTrash}
                                            onClick={() => removeSatsangType(index)}
                                        />
                                    )}

                                    {index === satsangTypes.length - 1 && (
                                        <FontAwesomeIcon
                                            className='icon-btn add-icon mx-3'
                                            icon={faPlus}
                                            onClick={addSatsangType}
                                        />
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                    <hr className='divider' />
                    <div className="mt-4 d-flex justify-content-end ">
                        <button type="button" className="btn btn-light mx-3" onClick={navigateBack}>
                            Cancel
                        </button>
                        <button type="submit" className="btn btn-primary">
                            Submit
                        </button>
                    </div>
                </form>
            </div>
        </div>
        </div>
    )
}

export default AddSatsang;