import React from 'react';
import "./index.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

interface PaginatorProps {
    totalItems: number;
    itemsPerPage: number;
    currentPage: number;
    onPageChange: (page: number) => void;
    onItemsPerPageChange: (items: number) => void;
}

const Paginator = ({ totalItems, itemsPerPage, currentPage, onPageChange, onItemsPerPageChange }: PaginatorProps) => {
    const totalPages = Math.ceil(totalItems / itemsPerPage);

    const handleItemsPerPageChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        onItemsPerPageChange(Number(event.target.value));
    };

    return (
        <div className="paginator">
            <div className='col-md-2'>
                <select className="form-select items-per-page" value={itemsPerPage} onChange={handleItemsPerPageChange}>
                    {[20, 30, 50].map((count) => (
                        <option key={count} value={count}>
                            {count} per page
                        </option>
                    ))}
                </select>
            </div>

            <div className="pagination-controls">
                <button
                    className="icon-button"
                    disabled={currentPage === 1}
                    onClick={() => onPageChange(currentPage - 1)}
                >
                    <FontAwesomeIcon icon={faChevronLeft} />
                </button>
                <span>Page {currentPage} of {totalPages}</span>
                <button
                    className="icon-button"
                    disabled={currentPage === totalPages}
                    onClick={() => onPageChange(currentPage + 1)}
                >
                    <FontAwesomeIcon icon={faChevronRight} />
                    
                </button>
            </div>
        </div>
    );
};

export default Paginator;
