import React, { useEffect, useState, useRef } from "react";
import "./index.css";
import {
  FILTER_INPUT_TYPE,
  FILTER_LISTING_TYPE,
  PRESENTY_STATUS,
} from "src/Common/AppEnum";
import {
  AttendanceDataModel,
  AttendanceMarkPayloadModel,
} from "../../Common/Models/Attendance/AttendanceDataModel";
import MarkAttendancePopup from "./MarkAttendancePopup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSort,
  faSortUp,
  faSortDown,
} from "@fortawesome/free-solid-svg-icons";
import { AttendanceFilterDataModel } from "src/Common/Models/Attendance/FilterDataModel";
import { useTranslation } from "react-i18next";
import {
  GetSewadalAttendanceApi,
  MarkAttendanceApi,
} from "src/api/AttendanceApi";
import { useMutation } from "@tanstack/react-query";
import Filter from "../Filter";
import {
  AttendanceFilterModel,
  FilterField,
} from "src/Common/Models/Filter/FilterModel";
import { formatDate } from "src/utils/utils";
import { GetFilterApi } from "src/api/filterApi";
import Breadcrumb from "../Dashboard/Breadcrumbs/Breadcrumbs";
import { useDispatch } from "react-redux";
import { hideLoader, showLoader } from "src/redux/loaderSlice";
import Paginator from "src/Common/Paginator";
// import { Breadcrumb } from "..Breadcrumbs/Dashboard/Breadcrumbs/Breadcrumbs";

const Attendance = () => {
  const { t } = useTranslation();

  const [selectedSewadal, setSelectedSewadal] =
    useState<AttendanceDataModel | null>(null);
  const [type, setType] = useState<PRESENTY_STATUS>(PRESENTY_STATUS.UNSET);
  const [isDelete, setIsDelete] = useState<boolean>(false);
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 768);
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 10; // Number of rows per page
  const [sewadalAtdData, setSewadalAtdData] = useState<AttendanceDataModel[]>(
    []
  );
  const [filteredData, setFilteredData] = useState<AttendanceDataModel[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [isFilterApplied, setIsFilterApplied] = useState<boolean>(false);
  const [filterData, setFilterData] = useState<AttendanceFilterDataModel>(
    new AttendanceFilterDataModel(formatDate(new Date()))
  );
  const [sortConfig, setSortConfig] = useState<{
    key: string;
    direction: "ascending" | "descending" | "";
  }>({
    key: "sewadalNo",
    direction: "ascending",
  });
  const [resetTrigger, setResetTrigger] = useState(false);

  const [totalPages, setTotalPages] = useState<number>(0);
  const [selectedItemsPerPage, setSelectedItemsPerPage] = useState<number>(20);
  // const [currentPage, setCurrentPage] = useState<number>(1);

  const escapeRegExp = (value: string) => {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
  };

  useEffect(() => {
    if (window.innerWidth <= 768) {
      setIsMobile(true);
    } // Adjust breakpoint as needed
  }, [isMobile]);

  const dispatch = useDispatch();
  const getMutation = useMutation<
    AttendanceDataModel[],
    unknown,
    AttendanceFilterDataModel
  >({
    mutationFn: GetSewadalAttendanceApi,
    onSuccess: (data: AttendanceDataModel[]) => {
      setSewadalAtdData(data);
      setTotalPages(data.length);
      setFilteredData([]);
      setIsFilterApplied(true);
      dispatch(hideLoader());
    },
    onError: (error: any) => {
      dispatch(hideLoader());
      console.error("Api failed:", error.response?.data || error.message);
    },
  });

  const postMutation = useMutation({
    mutationFn: MarkAttendanceApi,
    onError: (error: any) => {
      console.error("Api failed:", error.response?.data || error.message);
    },
  });

  const handleSort = (key: string) => {
    let direction: "ascending" | "descending" = "ascending";

    // Toggle sorting direction
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }

    // Update the sorting configuration
    setSortConfig({ key, direction });

    // Sorting logic for both 'data' and 'filteredData'
    const sortFunction = (a: AttendanceDataModel, b: AttendanceDataModel) => {
      if (
        a[key as keyof AttendanceDataModel]! <
        b[key as keyof AttendanceDataModel]!
      ) {
        return direction === "ascending" ? -1 : 1;
      }
      if (
        a[key as keyof AttendanceDataModel]! >
        b[key as keyof AttendanceDataModel]!
      ) {
        return direction === "ascending" ? 1 : -1;
      }
      return 0;
    };

    // Sort the main data and the filtered data
    const sortedData = [...sewadalAtdData].sort(sortFunction);
    const sortedFilteredData = [...filteredData].sort(sortFunction);

    // Update both states with sorted data
    setSewadalAtdData(sortedData);
    setFilteredData(sortedFilteredData);
  };

  const getSortIcon = (key: string) => {
    if (sortConfig.key !== key) {
      return <FontAwesomeIcon icon={faSort} />;
    }
    return sortConfig.direction === "ascending" ? (
      <FontAwesomeIcon icon={faSortUp} />
    ) : (
      <FontAwesomeIcon icon={faSortDown} />
    );
  };

  const resetSearchBar = () => {
    setSearchTerm('');
    setResetTrigger(prev => !prev);
  }

  const handleSearch = (searchTerm: string) => {
    setSearchTerm(searchTerm);

    const searchRegex = new RegExp(escapeRegExp(searchTerm), "i");

    const filteredRows = sewadalAtdData.filter((row) => {
      return Object.keys(row).some((field) => {
        // @ts-ignore

        return searchRegex.test(row[field]?.toString());
      });
    });
    if (searchTerm.length > 0) {
      setFilteredData(filteredRows);
    } else {
      setFilteredData([]);
    }
  };

  // Handle Save logic to store attendance status and time
  const handleSave = (
    sewadal: AttendanceDataModel,
    status: PRESENTY_STATUS,
    time: string
  ) => {
    dispatch(showLoader());
    let markPayload: AttendanceMarkPayloadModel = {
      ...filterData,
      sewadalId: sewadal.sewadalId,
      attendanceType:
        status === PRESENTY_STATUS.UNSET ? sewadal.attendanceType : status,
      time: time === "" ? sewadal.time : time,
    };

    postMutation.mutate(markPayload, {
      onSuccess: () => {
        const updatedData = sewadalAtdData.map((row) =>
          row.sewadalId === sewadal.sewadalId
            ? { ...row, attendanceType: status, time: time }
            : row
        );
        setSewadalAtdData([...updatedData]);
        setFilteredData([...updatedData]);
        resetSearchBar();
        dispatch(hideLoader());
      },
      onError: (error) => {
        console.error("Failed to save attendance:", error);
        dispatch(hideLoader());
      },
    });

    handleClose();
  };

  // Handle delete logic to remove attendance
  const handleDelete = (sewadal: AttendanceDataModel) => {
    dispatch(showLoader());
    let markPayload: AttendanceMarkPayloadModel = {
      ...filterData,
      sewadalId: sewadal.sewadalId,
      attendanceType: sewadal.attendanceType, // Ensure correct field is being updated
      time: sewadal.time,
    };
    postMutation.mutate(markPayload, {
      onSuccess: () => {
        const updatedData = sewadalAtdData.map((row) =>
          row.sewadalId === sewadal.sewadalId
            ? { ...row, attendanceType: PRESENTY_STATUS.UNSET, time: "" }
            : row
        );

        setSewadalAtdData([...updatedData]);
        setFilteredData([...updatedData]);
        dispatch(hideLoader());
      },
    });

    handleClose();
  };

  // Handle checkbox click for PV or P/PC
  const handleCheckboxChange = (
    row: AttendanceDataModel,
    status: PRESENTY_STATUS
  ) => {
    if (row.attendanceType === status) {
      setIsDelete(true); // If unchecking, confirm deletion
      setSelectedSewadal(row);
    } else {
      setSelectedSewadal(row);
      setType(status);
      setIsDelete(false); // Not a delete, mark attendance
    }
  };

  const handleClose = () => {
    setSelectedSewadal(null);
  };

  const handleFilterApply = (appliedFilters: {
    [key: string]: string | number;
  }) => {
    dispatch(showLoader());
    let selectedFilter: AttendanceFilterDataModel = {
      date: appliedFilters[FILTER_INPUT_TYPE.DATE]?.toString(),
      satsangId: Number(appliedFilters[FILTER_INPUT_TYPE.SATSANG]),
      satsangTypeId: Number(appliedFilters[FILTER_INPUT_TYPE.SATSANG_TYPE]),
    };
    setFilterData({ ...selectedFilter });
    setCurrentPage(1);
    getMutation.mutate(selectedFilter);
  };

  const handleReset = () => {
    setIsFilterApplied(false);
    setFilterData(new AttendanceFilterDataModel(formatDate(new Date())));
    setCurrentPage(1);
  };

  // Prepare the data to display based on filters and search
  const dataToDisplay =
    searchTerm || filteredData.length > 0 ? filteredData : sewadalAtdData;

  // Calculate the current rows for pagination
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  // const currentRows = dataToDisplay.slice(indexOfFirstRow, indexOfLastRow);

  const [showScrollToTop, setShowScrollToTop] = useState(false);
  const paginationRef = useRef<HTMLDivElement | null>(null); // Specify the type of the ref

  useEffect(() => {
    const handleScroll = () => {
      if (paginationRef.current) {
        const rect = paginationRef.current.getBoundingClientRect();
        // Check if pagination is in the viewport
        const isInView = rect.top >= 0 && rect.bottom <= window.innerHeight;
        setShowScrollToTop(isInView);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handleItemsPerPageChange = (items: number) => {
    setSelectedItemsPerPage(items);
    setCurrentPage(1); // Reset to page 1 when changing items per page
  };

  const paginatedData = ((searchTerm && searchTerm.length) ? filteredData : sewadalAtdData).slice(
    (currentPage - 1) * selectedItemsPerPage,
    currentPage * selectedItemsPerPage
  );

  useEffect(() => {
    let pagesCount = filteredData.length || sewadalAtdData.length;
    setTotalPages(pagesCount);
  }, [filteredData, sewadalAtdData]);

  return (
    <div className="attendancePage">
      <div className="tablecontainer mt-4">
        <Filter
          filterType={FILTER_LISTING_TYPE.ATTENDANCE}
          onlySearch={false}
          onApply={handleFilterApply}
          onReset={handleReset}
          onSearch={handleSearch}
          searchPlaceholder={t("search-name-placeholer-label")}
          resetSearchBar={resetTrigger}
        />
        <div className="table-responsive">
          {isFilterApplied && (
            <div>
              {paginatedData.length > 0 ? (
                !isMobile ? (
                  <table className="table table-bordered">
                    <thead className="tableHeading">
                      <tr>
                        <th rowSpan={2} onClick={() => handleSort("sewadalNo")}>
                          {t("sewadal-no-label")} {getSortIcon("sewadalNo")}
                        </th>
                        <th rowSpan={2} onClick={() => handleSort("name")}>
                          {t("name-label")} {getSortIcon("name")}
                        </th>
                        <th colSpan={2}>{t("action-label")}</th>
                      </tr>
                      <tr>
                        <th>{t("pv-attendance-label")}</th>
                        <th>{t("pc-attendance-label")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {paginatedData.map((row, i) => (
                        <tr key={i}>
                          <td>{row.sewadalNo}</td>
                          <td>{row.name}</td>
                          <td>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                checked={
                                  row.attendanceType === PRESENTY_STATUS.PV
                                }
                                onChange={() =>
                                  handleCheckboxChange(row, PRESENTY_STATUS.PV)
                                }
                              />
                            </div>
                          </td>
                          <td>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                checked={
                                  row.attendanceType === PRESENTY_STATUS.P ||
                                  row.attendanceType === PRESENTY_STATUS.PC
                                }
                                onChange={() =>
                                  handleCheckboxChange(row, PRESENTY_STATUS.PC)
                                }
                              />
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  paginatedData.map((row, i) => (
                    <div className="card" key={i}>
                      <div className="card-header">
                        Sewadal No: {row.sewadalNo}
                      </div>
                      <div className="card-body">
                        <div>
                          <strong>{t("name-label")}: </strong>
                          {row.name}
                        </div>
                        <div className="checkbox-row">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              checked={
                                row.attendanceType === PRESENTY_STATUS.PV
                              }
                              onChange={() =>
                                handleCheckboxChange(row, PRESENTY_STATUS.PV)
                              }
                            />
                            <label>{t("pv-attendance-label")}</label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              checked={
                                row.attendanceType === PRESENTY_STATUS.P ||
                                row.attendanceType === PRESENTY_STATUS.PC
                              }
                              onChange={() =>
                                handleCheckboxChange(row, PRESENTY_STATUS.PC)
                              }
                            />
                            <label>{t("pc-attendance-label")}</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                )
              ) : (
                <div className="d-flex j-center help-text">
                  {t("no-sewadal-record-found")}
                </div>
              )}
              {sewadalAtdData.length > 0 && (
                <Paginator
                  totalItems={totalPages}
                  itemsPerPage={selectedItemsPerPage}
                  currentPage={currentPage}
                  onPageChange={handlePageChange}
                  onItemsPerPageChange={handleItemsPerPageChange}
                />
              )}
            </div>
          )}
          {isFilterApplied && searchTerm && filteredData.length === 0 && (
            <div className="d-flex j-center">
              {t("no-results-found-help-text")}
            </div>
          )}
          {!isFilterApplied && (
            <div className="d-flex j-center help-text">
              {t("attendance.apply-filter-help-text")}
            </div>
          )}
          {isFilterApplied && sewadalAtdData.length === 0 && (
            <div className="d-flex j-center help-text">
              {t("no-sewadal-record-found")}
            </div>
          )}

        </div>
      </div>

      {/* Pagination Controls */}
      {/* {isFilterApplied && (
        <div className="pagination" ref={paginationRef}>
          <button
            className="pagination-button"
            onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
            disabled={currentPage === 1}
          >
            Previous
          </button>
          <span className="pagination-info">
            {currentPage} of {Math.ceil(dataToDisplay.length / rowsPerPage)}
          </span>
          <button
            className="pagination-button"
            onClick={() =>
              setCurrentPage((prev) =>
                Math.min(
                  prev + 1,
                  Math.ceil(dataToDisplay.length / rowsPerPage)
                )
              )
            }
            disabled={
              currentPage === Math.ceil(dataToDisplay.length / rowsPerPage)
            }
          >
            Next
          </button>
        </div>
      )} */}
      {isMobile && showScrollToTop && (
        <div className="move-to-top" onClick={scrollToTop}>
          <i className="fa fa-arrow-up" aria-hidden="true"></i>
        </div>
      )}

      {selectedSewadal && (
        <MarkAttendancePopup
          selectedSewadal={selectedSewadal}
          type={type}
          handleSave={handleSave}
          handleDelete={handleDelete}
          isDelete={isDelete}
          handleClose={handleClose}
        />
      )}
    </div>
  );
};

export default Attendance;
