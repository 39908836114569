export enum BitwisePermissions {
  UNIT = 1 << 0, // 1  (Binary: 0001)
  SATSANG = 1 << 1,
  GROUP = 1 << 2,
  REPORT = 1 << 3,
  MARK_ATTENDANCE = 1 << 4, // 16 (Binary: 10000)
  SEWADAL = 1 << 5,
  FORM = 1 << 6,
  FORM_UPDATE = 1 << 7,
  DASHBOARD = 1 << 8,

  // Sidebar-specific permissions
  VIEW_DASHBOARD = 1 << 18, //
  VIEW_SEWADAL_ATTENDANCE = 1 << 19,
  VIEW_MASTER_DATA = 1 << 20,
  VIEW_ENTRY = 1 << 21,
  VIEW_REPORTS = 1 << 22,
  VIEW_SETTINGS = 1 << 23,
  VIEW_SIGN_OUT = 1 << 24,
  VIEW_HELP = 1 << 25,
}
