import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";

const Sd1AUnitCreationForm: React.FC = () => {
  // State for form data
  const [formData, setFormData] = useState({
    branch: "Branch A",
    khetra: "Kshetra Y",
    zone: "Zone Z",
    creationDate: "", // Add creationDate to the state
  });

  // Hardcoded data for now
  useEffect(() => {
    const hardcodedData = {
      branch: "Branch A",
      khetra: "Kshetra Y",
      zone: "Zone Z",
    };

    setFormData((prevData) => ({
      ...prevData,
      ...hardcodedData,
    }));
  }, []);

  // Handle input changes for editable fields
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value, // Update formData for every input, including the creationDate
    }));
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log("Form Data:", formData);
  };

  return (
    <div className="container-fluid mt-5">
      <div className="row justify-content-center mt-3">
        <div className="col-12 col-lg-12">
          <div className="card shadow">
            <div className="card-body">
              <legend className="sd1AUnitCreationForm">
                <b>SD-1A New Unit Creation Form</b>
              </legend>
              <p>
                Please enter the necessary details for SD-1A new unit creation.
              </p>

              <form onSubmit={handleSubmit}>
                <div className="row">
                  {/* First Row: Branch and Khetra */}
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="branch">Branch</label>
                      <input
                        type="text"
                        className="form-control"
                        id="branch"
                        value={formData.branch}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="khetra">Khetra</label>
                      <input
                        type="text"
                        className="form-control"
                        id="khetra"
                        value={formData.khetra}
                        disabled
                      />
                    </div>
                  </div>

                  {/* Second Row: Zone and Unit Creation Date */}
                  <div className="col-md-6">
                    <div className="form-group mt-3">
                      <label htmlFor="zone">Zone</label>
                      <input
                        type="text"
                        className="form-control"
                        id="zone"
                        value={formData.zone}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mt-3">
                      <label htmlFor="creationDate">Unit Creation Date</label>
                      <input
                        type="date"
                        className="form-control"
                        id="creationDate"
                        value={formData.creationDate} // Bind the value to formData.creationDate
                        onChange={handleInputChange} // Handle the change
                      />
                    </div>
                  </div>
                </div>

                <div className="d-flex justify-content-end mt-4">
                  <button type="submit" className="btn btn-primary">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sd1AUnitCreationForm;
