// ./Common/AppEnum
export enum GENDER {
  UNSET = "",
  MALE = 1,
  FEMALE,
}

export enum PRESENTY_STATUS {
  UNSET = "0",
  P = "1",
  PV = "2",
  PC = "3",
  A = "4",
  AD = "5",
}

export enum FILTER_INPUT_TYPE {
  DATE = 1,
  START_DATE,
  END_DATE,
  GROUP_DUTY,
  SATSANG,
  SATSANG_TYPE,
  DESIGNATION,
  AREA,
  BLOOD_GROUP,
  UNIT,
  SEWADAL_REGISTER_TYPE,
  SEWADAL_GENDER_TYPE,
  SEWADAL_DESIGNATION_TYPE,
  VIEW_TYPE,
  START_MONTH,
  END_MONTH,
  REPORT_TYPE,
  SEWADAL,
  TRADE
}

export enum USER_TYPE {
  ADMIN = "Admin",
  KSHETRIYA_SANCHALAK = "Kshetriya Sanchalak",
  ADHIKARI = "Adhikari",
  ATTENDANCE = "Attendance",
}

export enum FILTER_LISTING_TYPE {
  ATTENDANCE = 1,
  SEWADAL,
  UNIT,
  REPORTS
}

export enum SEWADAL_TYPE {
  KSHETRIYA_SANCHALAK = "Kshetriya Sanchalak",
  SANCHALAK = "Sanchalak",
  SHIKSHAK = "Shikshak",
  SANCHALIKA = "Sanchalika",
  S_SHIKSHAK = "S.Shikshak",
  S_SHIKSHIKA = "S.Shikshika",
  SEWADAL = "Sewadal",
  UR_SEWADAL = "Unregistered Sewadal",
  BAL_SEWADAL = "Bal Sewadal",
}

export enum BLOOD_GROUP {
  A_POSITIVE = "A+",
  A_NEGATIVE = "A-",
  B_POSITIVE = "B+",
  B_NEGATIVE = "B-",
  AB_POSITIVE = "AB+",
  AB_NEGATIVE = "AB-",
  O_POSITIVE = "O+",
  O_NEGATIVE = "O-",
}

export enum DB_GENDER {
  MALE = "M",
  FEMALE = "F",
}

export enum REPORT_VIEW_TYPE {
  DATE_TYPE = "1",
  ONLY_PERCENT = "2"
}

export enum REPORT_TYPE {
  NONE,
  ADHIKARI_INDIVIDUAL,
  UNITWISE,
  BELOW_60,
  BLOOD_GROUP,
  TRADE,
  ATTENDANCE_DETAILS
}

export enum SewadalType {
  KSHETRIYA_SANCHALAK = "Kshetriya Sanchalak",
  SANCHALAK = "Sanchalak",
  // INCHARGE = "Incharge",
  SHIKSHAK = "Shikshak",
  SANCHALIKA = "Sanchalika",
  SHIKSHIKA = "Shikshika",
  S_SHIKSHAK = "S.Shikshak",
  S_SHIKSHIKA = "S.Shikshika",
  SEWADAL = "Sewadal",
  UR_SEWADAL = "Unregistered Sewadal",
  BAL_SEWADAL = "Bal Sewadal",
}

export enum FormType {
  SD1 = "SD1",
  SD1A = "SD1A",
  SD2G = "SD2G",
  SD2L = "SD2L",
  SD3 = "SD3",
  SD3A = "SD3A",
  SD4 = "SD4",
  SD5 = "SD5",
  SD6B = "SD6B",
  SD7 = "SD7",
}

export enum FormStatus {
  SUBMITTED = "submitted",
  DOWNLOADED = "downloaded",
  RECEIVED_AT_KSH_S = "received_by_ksh",
  FORWARDED_TO_SHO = "forwarded_to_sho",
  REJECTED="rejected",
  CLOSED = "closed",
}