import "./Header.css";
import "../SNMPage/Hamburger.css";

import React, { useEffect, useRef, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { USER_TYPE } from "src/Common/AppEnum";
import { logout } from "src/redux/authSlice";
import { RootState } from "src/redux/store";

import {
  faBars,
  faBell,
  faCommentAlt as faCommentAltSolid,
  faMicrophone,
  faSearch as faSearchSolid,
  faSignOutAlt,
  faTimes,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Import the logo image
import { sidebarLogoImage } from "../SideBar/Images/SidebarLogo";

const Header: React.FC<{
  toggleSidebar: () => void;
  userType: USER_TYPE | null | undefined;
}> = ({ toggleSidebar, userType }) => {
  const { user } = useSelector((state: RootState) => state.auth);

  const [profileDropdownOpen, setProfileDropdownOpen] =
    useState<boolean>(false);
  const [searchOpen, setSearchOpen] = useState<boolean>(false); // State to manage search bar visibility
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const dropdownRef = useRef<HTMLDivElement>(null);

  const toggleProfileDropdown = () =>
    setProfileDropdownOpen(!profileDropdownOpen);

  // Toggle search bar visibility for mobile view
  const toggleSearchBar = () => setSearchOpen(!searchOpen);

  const handleSignOut = () => {
    dispatch(logout());
  };
  const navigateToAttendance = () => {
    navigate("/attendance");
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setProfileDropdownOpen(false); // Close the dropdown when clicking outside
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div>
      <header className="full-width-header d-flex align-items-center">
        {/* Hamburger Menu Button */}
        <button
          className="btn btn-primary d-md-none hamburger-menu"
          onClick={toggleSidebar}
          aria-label="Toggle Sidebar"
        >
          <FontAwesomeIcon icon={faBars} />
        </button>

        {/* Mobile Logo (only displayed on mobile view) */}
        <img
          src={sidebarLogoImage}
          alt="Logo"
          className="mobile-logo d-md-none"
        />

        {/* Search Bar for Web View */}
        {/* <div className="search-bar-container d-none d-md-flex">
					<FontAwesomeIcon
						icon={faSearchSolid}
						className="web-search-icon"
					/>
					<input
						type="text"
						className="search-bar"
						placeholder="Search Sewadal name / Sewadal Pr No........."
					/>
					<FontAwesomeIcon
						icon={faMicrophone}
						className="mic-icon"
					/>
				</div> */}

        {/* Buttons on the right side */}
        <div className="btn-toolbar ml-auto">
          <div className="btn-group">
            {userType !== USER_TYPE.ATTENDANCE && (
              <>
                {userType !== USER_TYPE.KSHETRIYA_SANCHALAK &&
                  userType !== USER_TYPE.ADMIN && (
                    <button
                      type="button"
                      className="btn btn-add-attendance"
                      aria-label="Add Attendance"
                      onClick={navigateToAttendance}
                    >
                      Add Attendance
                    </button>
                  )}
                <button
                  className="btn mobile-search-icon-btn d-md-none"
                  onClick={toggleSearchBar}
                  aria-label="Open Search"
                >
                  <FontAwesomeIcon
                    icon={faSearchSolid}
                    className="mobile search-icon"
                  />
                </button>
                <button
                  type="button"
                  className="btn btn-outline-info"
                  aria-label="Messages"
                >
                  <FontAwesomeIcon icon={faCommentAltSolid} />
                </button>
                <button
                  type="button"
                  className="btn btn-outline-info"
                  aria-label="Notifications"
                >
                  <FontAwesomeIcon icon={faBell} />
                </button>
              </>
            )}
            <div className="btn-group" ref={dropdownRef}>
              <button
                type="button"
                className="btn btn-outline-info dropdown-toggle"
                aria-label="Profile"
                onClick={toggleProfileDropdown}
              >
                <FontAwesomeIcon icon={faUser} />
              </button>
              {profileDropdownOpen && (
                <div className="dropdown-menu dropdown-menu-end profileMenu show">
                  <div className="profile-dropdown-wrap">
                    {/* <span className="dropdown-item-text">Login Time: 10:00 AM</span> */}
                    <div className="userIcon">
                      <FontAwesomeIcon icon={faUser} />
                    </div>
                    <p className="profile-login-email">{user?.email}</p>
                    <button
                      className="dropdown-item signout-btn"
                      onClick={handleSignOut}
                    >
                      <FontAwesomeIcon icon={faSignOutAlt} /> Sign Out
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </header>

      {/* Mobile Search Bar Overlay */}
      {searchOpen && (
        <div className="mobile search-overlay">
          <div className="mobile search-bar-mobile">
            <input
              type="text"
              className="mobile search-bar"
              placeholder="Search Sewadal name / Sewadal Pr No........."
            />
            <FontAwesomeIcon
              icon={faTimes}
              className="mobile close-search-icon"
              onClick={toggleSearchBar}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Header;
