import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Image } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { sidebarLogoImage } from "./Images/SidebarLogo";
import {
  faThLarge as faDashboard,
  faUserPlus,
  faUserFriends,
  faListAlt,
  faCog as faCogSolid,
  faSignOutAlt,
  faQuestionCircle,
  faBars,
  faChevronDown, // Add the down arrow icon
  faChevronUp, // Add the up arrow icon
} from "@fortawesome/free-solid-svg-icons";
import { faChartBar as farChartBar } from "@fortawesome/free-regular-svg-icons";
import "./Sidebar.css";
import "../SNMPage/Hamburger.css";
import { USER_TYPE } from "src/Common/AppEnum";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/redux/store";
import { sidebarItems } from "src/Common/AppConstant";
import { logout } from "src/redux/authSlice";

const Sidebar: React.FC<{
  sidebarOpen: boolean;
  toggleSidebar: () => void;
  userType: USER_TYPE | null | undefined;
  sidebarRef: React.RefObject<HTMLDivElement>;
}> = ({ sidebarOpen, toggleSidebar, userType, sidebarRef }) => {
  const [links, setLinks] = useState<any[]>([]);
  const [expandedIndices, setExpandedIndices] = useState<number[]>([]); // Store expanded indices for accordion

  const dispatch = useDispatch();
  const { permissions } = useSelector((state: RootState) => state.auth);
  const location = useLocation();

  const isActive = (route: string) =>
    location.pathname === route ? "active" : "";

  useEffect(() => {
    // Filter the top-level items based on the user's role
    const fetchLinks = async () => {
      const filteredData = sidebarItems
        .filter((item) => item?.role?.includes(userType!))
        .map((item) => {
          if (item.subItems) {
            const filteredSubItems = item.subItems.filter((subItem) =>
              subItem?.role.includes(userType!)
            );
            return { ...item, subItems: filteredSubItems };
          }
          return item;
        });

      setLinks(filteredData);
    };

    fetchLinks();
  }, [permissions, userType]);

  const getIcon = (iconName: string) => {
    switch (iconName) {
      case "faDashboard":
        return faDashboard;
      case "faUserPlus":
        return faUserPlus;
      case "faUserFriends":
        return faUserFriends;
      case "faListAlt":
        return faListAlt;
      case "faCogSolid":
        return faCogSolid;
      case "faSignOutAlt":
        return faSignOutAlt;
      case "faQuestionCircle":
        return faQuestionCircle;
      case "farChartBar":
        return farChartBar;
      default:
        return faBars; // Default icon
    }
  };

  // Toggle function for expanding/collapsing the accordion
  const toggleExpand = (index: number) => {
    if (expandedIndices.includes(index)) {
      setExpandedIndices(expandedIndices.filter((i) => i !== index)); // Collapse
    } else {
      setExpandedIndices([...expandedIndices, index]); // Expand
    }
  };

  const signOut = () => {
    dispatch(logout());
  };

  return (
    <nav
      id="sidebar"
      className={`col-md-2 d-md-block bg-light sidebar ${
        sidebarOpen ? "open" : "closed"
      }`}
      ref={sidebarRef}
    >
      <div className="sidebar-sticky d-flex flex-column">
        {/* Hamburger menu for mobile view */}
        <button
          className="btn btn-primary d-md-none hamburger-menu"
          onClick={toggleSidebar}
          aria-label="Toggle Sidebar"
        >
          <FontAwesomeIcon icon={faBars} />
        </button>
        <div className="dashboardlogo">
          <Image src={sidebarLogoImage} alt="Sidebar Logo" />
        </div>
        <ul className="nav flex-column snm-nav-menu">
          {links.map((link: any, index: number) =>
            link.subItems ? (
              <li key={index} className={`nav-item ${isActive(link.route)}`}>
                <button
                  className="btn btn-link nav-link d-flex justify-content-between align-items-center" // Align content to both ends
                  onClick={() => toggleExpand(index)} // Toggle on click
                  aria-expanded={expandedIndices.includes(index)}
                >
                  <div>
                    <FontAwesomeIcon icon={getIcon(link.icon)} /> {link.label}
                  </div>
                  {/* Render the toggle up/down icon aligned to the right */}
                  <FontAwesomeIcon
                    icon={
                      expandedIndices.includes(index)
                        ? faChevronUp
                        : faChevronDown
                    }
                    className="ms-2"
                  />
                </button>
                {/* Render sub-items if the section is expanded */}
                <div
                  id={`collapse-${index}`}
                  className={`collapse ${
                    expandedIndices.includes(index) ? "show" : ""
                  }`}
                >
                  <ul className="nav flex-column">
                    {link.subItems.map((subItem: any, subIndex: number) => (
                      <li
                        key={subIndex}
                        className={`nav-item ${isActive(subItem.route)}`}
                      >
                        <Link
                          className={`sub-item-link nav-link ${isActive(
                            subItem.route
                          )}`}
                          to={subItem.route}
                        >
                          <FontAwesomeIcon icon={getIcon(subItem.icon)} />{" "}
                          {subItem.label}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </li>
            ) : (
              <li key={index} className={`nav-item ${isActive(link.route)}`}>
                <Link
                  className={`nav-link ${isActive(link.route)}`}
                  to={link.route}
                >
                  <FontAwesomeIcon icon={getIcon(link.icon)} /> {link.label}
                </Link>
              </li>
            )
          )}
        </ul>
        {/* Spacer to push "Sign Out" and "Help" to the bottom */}
        {/* <div className="mt-auto">
          <ul className="nav flex-column">
            <hr className="sidebar-divider" />
            <li className="nav-item">
              <button className="nav-link" onClick={signOut}>
                <FontAwesomeIcon icon={faSignOutAlt} /> Sign Out
              </button>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/help">
                <FontAwesomeIcon icon={faQuestionCircle} /> Help
              </Link>
            </li>
          </ul>
        </div> */}
      </div>
    </nav>
  );
};

export default Sidebar;
