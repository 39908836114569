import React, { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import Toast from 'react-bootstrap/Toast';
import { RootState } from '../../redux/store'; // Adjust import based on your structure
import { hideToast } from '../../redux/toastSlice'; // Import the hideToast action
import './index.css';

const Toaster = () => {
    const dispatch = useDispatch();
    const { visible, title, message, time } = useSelector((state: RootState) => state.toast);

    useEffect(() => {
        if (visible) {
              const timer = setTimeout(() => {
                dispatch(hideToast());
              }, 3000); // Automatically hide toast after 3 seconds

              return () => clearTimeout(timer); // Cleanup timer on unmount
        }
    }, [visible, dispatch]);

    if (!visible) return null; // Don't render the toast if it's not visible

    return (
        <div className="toast-container">
            <Toast show={visible} onClose={() => dispatch(hideToast())}>
                <Toast.Header>
                    <strong className="me-auto">{title}</strong>
                    <small>{time}</small>
                </Toast.Header>
                <Toast.Body>{message}</Toast.Body>
            </Toast>
        </div>
    );
};

export default Toaster;
