import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Breadcrumb as BootstrapBreadcrumb } from "react-bootstrap";
import './Breadcrumbs.css';

const Breadcrumb: React.FC = () => {
  const location = useLocation();
  console.log(location);
  const pathnames: string[] = location.pathname.split("/").filter(Boolean);

  // List of segments that should not be clickable
  const nonClickableSegments = ["entry", "masterdata", "sewadalforms"];

  // Check if the current page is the Dashboard ("/" or "/dashboard")
  const isDashboardOnly = pathnames.length === 0 || (pathnames.length === 1 && pathnames[0].toLowerCase() === "dashboard");

  return (
    pathnames?.length > 1 && location.pathname !== "/dashboard"  ? (
      <BootstrapBreadcrumb>
        <BootstrapBreadcrumb.Item linkAs={Link} linkProps={{ to: "/" }} className="breadcrumb-link">
          Dashboard
        </BootstrapBreadcrumb.Item>

        {!isDashboardOnly && pathnames.map((segment, index) => {
          const path = `/${pathnames.slice(0, index + 1).join("/")}`;
          const isLast = index === pathnames.length - 1;
          const isNonClickable = nonClickableSegments.includes(segment.toLowerCase());

          return (
            <BootstrapBreadcrumb.Item
              key={path}
              linkAs={!isNonClickable ? Link : undefined}
              linkProps={!isNonClickable ? { to: path } : undefined}
              active={isNonClickable || isLast}
              className={isLast ? "breadcrumb-link-last" : "breadcrumb-link"}
            >
              {segment.charAt(0).toUpperCase() + segment.slice(1)}
            </BootstrapBreadcrumb.Item>
          );
        })}
      </BootstrapBreadcrumb>
    ) : <></>
  );
};

export default Breadcrumb;
