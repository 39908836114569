// src/redux/authSlice.ts
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserModel } from "src/Common/Models/Auth/UserModel";

export interface AuthState {
  isAuthenticated: boolean;
  accessToken: string | null;
  refreshToken: string | null;
  permissions: number;
  user: UserModel | null;
}

const initialState: AuthState = {
  isAuthenticated: false, 
  accessToken: null,
  refreshToken: null,
  user: null,
  permissions: 0,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login(
      state,
      action: PayloadAction<{
        accessToken: string;
        refreshToken: string;
        user: UserModel;
        permissions: number;
      }>
    ) {
      state.isAuthenticated = true;
      state.accessToken = action.payload.accessToken;
      state.refreshToken = action.payload.refreshToken;
      state.user = action.payload.user;
      state.permissions = action.payload.permissions;
    },
    setAccessToken(state, action: PayloadAction<string>) {
      state.accessToken = action.payload; 
    },
    logout(state) {
      state.isAuthenticated = false;
      state.accessToken = null;
      state.refreshToken = null;
      state.user = null;
      state.permissions = 0;
    },
  },
});

export const { login, logout, setAccessToken } = authSlice.actions;
export const selectToken = (state: { auth: AuthState }) => state.auth.accessToken;
export const selectRefreshToken = (state: { auth: AuthState }) => state.auth.refreshToken;
export default authSlice.reducer;
