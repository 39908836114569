export class SatsangModel {
  public id?: number;
  public name: string;
  public location: string;
  public isActive?: boolean;
  public satsangTypes: SatsangTypeModel[];

  constructor() {
    this.name = "";
    this.location = "";
    this.isActive = true;
    this.satsangTypes = [];
  }
}

export class SatsangTypeModel {
  public name: string;
  public timeFrom: string;
  public timeTo: string;
  public isActive?: boolean;

  constructor() {
    this.name = "";
    this.timeFrom = "";
    this.timeTo = "";
    this.isActive = true;
  }
}

export class SatsangResultDataModel {
  public satsangs: SatsangModel[];
  public count: number;

  constructor() {
    this.satsangs = [];
    this.count = 0;
  }
}


