import './SewadalDetails.css';

import React, {
	useCallback,
	useEffect,
	useState,
} from 'react';

import { t } from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AddAttendanceUserApi, GetSewadalApi } from 'src/api/sewadalApi';
import {
	DB_GENDER,
	FILTER_INPUT_TYPE,
	FILTER_LISTING_TYPE,
	SEWADAL_TYPE,
	USER_TYPE,
} from 'src/Common/AppEnum';
import {
	AttendanceFilterDataModel,
} from 'src/Common/Models/Attendance/FilterDataModel';
import { SewadalDataModel } from 'src/Common/Models/Sewadal/SewadalModel';
import Filter from 'src/Component/Filter';
import { RootState } from 'src/redux/store';
import { formatDate } from 'src/utils/utils';

import { useMutation } from '@tanstack/react-query';
import Paginator from 'src/Common/Paginator';
import { GetFilterApi } from 'src/api/filterApi';
import { KeyValueObject } from 'src/Common/Models/Filter/FilterModel';
import { hideLoader, showLoader } from 'src/redux/loaderSlice';
import { Button, Dropdown, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV, faLaptopCode } from '@fortawesome/free-solid-svg-icons';
import useDebounce from 'src/utils/debounce/useDebounce';
import { Form } from 'react-router-dom';
import AddAttendanceUserModal from './addAttendanceUserModel';
import { AttendanceUserModel } from 'src/Common/Models/Sewadal/AttendanceUserModel';

const SewadalDetails: React.FC = () => {
	const { user } = useSelector((state: RootState) => state.auth);
	const [genderTab, setGenderTab] = useState<DB_GENDER>(
		DB_GENDER.MALE
	);
	const [selectedSewadalType, setSelectedSewadalType] = useState<SEWADAL_TYPE>(SEWADAL_TYPE.SEWADAL);
	const [searchTerm, setSearchTerm] = useState<string>('');
	const [filterData, setFilterData] = useState<AttendanceFilterDataModel>(
		new AttendanceFilterDataModel(formatDate(new Date()))
	);
	const [selectedUnitId, setSelectedUnitId] = useState<number>(0);
	const [filteredData, setFilteredData] = useState<any[]>([]);
	const [isFilterApplied, setIsFilterApplied] = useState<boolean>(false);
	const [sewadalData, setSewadalData] = useState<SewadalDataModel[]>([]);
	const itemsPerPage = 20;
	const [totalPages, setTotalPages] = useState<number>(0);
	const [selectedItemsPerPage, setSelectedItemsPerPage] = useState<number>(20);
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [allUnits, setAllUnits] = useState<KeyValueObject[]>([]);
	const dispatch = useDispatch();
	const debouncedSearchTerm = useDebounce(searchTerm, 500); // 500ms debounce delay
	const [selectedAttendanceUser, setSelectedAttendanceUser] = useState<SewadalDataModel | null>(null);

	const [show, setShow] = useState(false);

	const handleClose = () => setShow(false);
	const handleShow = (sewadal: SewadalDataModel) => {
		setSelectedAttendanceUser(sewadal);
		setShow(true);
	}

	const handleItemsPerPageChange = (items: number) => {
		setSelectedItemsPerPage(items);
		setCurrentPage(1); // Reset to page 1 when changing items per page
	};

	const paginatedData = (sewadalData).slice(
		(currentPage - 1) * selectedItemsPerPage,
		currentPage * selectedItemsPerPage
	);

	const handlePageChange = (page: number) => {
		setCurrentPage(page);
	};


	// Updated API call to accept parameters for pagination and search
	const getMutation = useMutation({
		mutationFn: (params: {
			gender: DB_GENDER,
			unitNo: number;
			page: number;
			pageSize: number;
			searchTerm: string;
			type: SEWADAL_TYPE
		}) => GetSewadalApi(params),
		onSuccess: (data: { sewadals: SewadalDataModel[]; totalCount: number }) => {
			setFilteredData([]);
			setSewadalData(data.sewadals);
			setTotalPages(data.totalCount);
			setIsFilterApplied(true);
			dispatch(hideLoader());
		},
		onError: (error: any) => {
			dispatch(hideLoader());
			console.error('API failed:', error.response?.data || error.message);
		},
	});

	useEffect(() => {
		dispatch(showLoader());
		getFilterDataMutation.mutate();
	}, [])

	const getFilterDataMutation = useMutation<KeyValueObject[]>({
		mutationFn: () => GetFilterApi({ filterType: FILTER_LISTING_TYPE.REPORTS }),
		onSuccess: (data: any) => {
			setAllUnits(data.units);
			dispatch(hideLoader());
		},
		onError: (error: any) => {
			dispatch(hideLoader());
			console.error("Api failed:", error.response?.data || error.message);
		}
	});

	const addAttendanceUserMutation = useMutation({
		mutationFn: (params: AttendanceUserModel) => AddAttendanceUserApi(params),
		onSuccess: (data: any) => {
			dispatch(hideLoader());
		},
		onError: (error: any) => {
			dispatch(hideLoader());
			console.error('API failed:', error.response?.data || error.message);
		},
	});

	const fetchData = useCallback(() => {
		dispatch(showLoader());
		getMutation.mutate({
			gender: genderTab,
			unitNo: (user?.userType === USER_TYPE.ADHIKARI) ? user?.unitNo : selectedUnitId,
			page: currentPage,
			pageSize: selectedItemsPerPage,
			searchTerm,
			type: selectedSewadalType!
		});
	}, [selectedUnitId, genderTab, selectedSewadalType, currentPage, selectedItemsPerPage, debouncedSearchTerm]); // Only change if these dependencies change

	// Fetch data on mount and when pagination or searchTerm changes
	useEffect(() => {
		fetchData();
	}, [fetchData, currentPage, searchTerm]);

	const handleGenderTab = (tabType: DB_GENDER) => {
		setGenderTab(tabType);
	};

	const handleSearch = (term: string) => {
		setSearchTerm(term);
		setCurrentPage(1); // Reset to page 1 when a new search is initiated
	};

	const handleFilterApply = (appliedFilters: {
		[key: string]: string | number;
	}) => {
		const selectedFilter: AttendanceFilterDataModel = {
			date: appliedFilters[FILTER_INPUT_TYPE.DATE]?.toString(),
			satsangId: Number(appliedFilters[FILTER_INPUT_TYPE.SATSANG]),
			satsangTypeId: Number(appliedFilters[FILTER_INPUT_TYPE.SATSANG_TYPE]),
		};
		setFilterData({ ...selectedFilter });
		setCurrentPage(1);
		fetchData();
	};

	const handleReset = () => {
		setIsFilterApplied(false);
		setFilterData(new AttendanceFilterDataModel(formatDate(new Date())));
		setCurrentPage(1);
		fetchData();
	};

	const goToPage = (page: number) => setCurrentPage(page);

	function handleUnitChange(value: string): void {
		setSelectedUnitId(Number(value));
	}

	const handleSaveAttendanceUser = (attendanceUser: AttendanceUserModel) => {
		setSelectedAttendanceUser(null);
		setShow(false);
		addAttendanceUserMutation.mutate(attendanceUser);
	}

	return (
		<div className="attendancePage">
			<div className="tablecontainer mt-4">
				<div className="d-flex align-items-center justify-content-between flex-wrap">
					{user?.userType === USER_TYPE.KSHETRIYA_SANCHALAK && (
						<div className="col-md-3 me-4 mt-8">
							<select
								id="selectUnit"
								className="form-select "
								required
								onChange={(e) => handleUnitChange(e.target.value)}
								value={selectedUnitId}>
								<option value="">Select Unit</option>
								{allUnits.map((unit) => (
									<option
										key={unit.key}
										value={unit.key}>
										{unit.value}
									</option>
								))}
							</select>
						</div>
					)}
					<div className="flex-grow-1 mt-3">
						<Filter
							filterType={FILTER_LISTING_TYPE.SEWADAL}
							onlySearch={true}
							onApply={handleFilterApply}
							onReset={handleReset}
							onSearch={handleSearch}
							searchPlaceholder={t('search-name-placeholer-label')}
						/>
					</div>
				</div>

				<div className="d-flex gap-3 mt-4">
					<button
						type="button"
						className={
							DB_GENDER.MALE === genderTab
								? 'btn btn-primary color-light'
								: 'btn btn-light'
						}
						onClick={() => handleGenderTab(DB_GENDER.MALE)}>
						Gents Sewadal
					</button>

					<button
						type="button"
						className={
							DB_GENDER.FEMALE === genderTab
								? 'btn btn-primary'
								: 'btn btn-light'
						}
						onClick={() => handleGenderTab(DB_GENDER.FEMALE)}>
						Ladies Sewadal
					</button>

				</div>

				<hr></hr>

				<div className="d-flex align-items-center mb-4">
					<div className="me-3 color">
						<label>
							<input
								type="radio"
								name="gentsSewadal"
								value={SEWADAL_TYPE.SEWADAL}
								checked={selectedSewadalType === SEWADAL_TYPE.SEWADAL}
								onChange={(e) => setSelectedSewadalType(e.target.value as SEWADAL_TYPE)}
							/>
							Register Sewadal
						</label>
					</div>
					<div className="me-3 color">
						<label>
							<input
								type="radio"
								name="gentsSewadal"
								value={SEWADAL_TYPE.UR_SEWADAL}
								checked={selectedSewadalType === SEWADAL_TYPE.UR_SEWADAL}
								onChange={(e) => setSelectedSewadalType(e.target.value as SEWADAL_TYPE)}
							/>
							Unregister Sewadal
						</label>
					</div>
					<div className="me-3 color">
						<label>
							<input
								type="radio"
								name="gentsSewadal"
								value={SEWADAL_TYPE.BAL_SEWADAL}
								checked={selectedSewadalType === SEWADAL_TYPE.BAL_SEWADAL}
								onChange={(e) => setSelectedSewadalType(e.target.value as SEWADAL_TYPE)}
							/>
							Bal Sewadal
						</label>
					</div>
				</div>

				{/* Table UI components */}

				<div className="table-responsive">
					{sewadalData?.length > 0 ? (
						<table className="table table-bordered">
							<thead className="tableHeading">
								<tr>
									<th>Sr No</th>
									<th>New P.N</th>
									<th>Old P.N</th>
									<th>Name</th>
									<th>Sewadal Designation</th>
									<th>DO Add</th>
									<th>Father's Name</th>
									<th>DOB</th>
									<th>Quali.</th>
									<th>Occu.</th>
									<th>Address</th>
									<th>Contact No.</th>
									<th>Blood Group</th>
									<th>Do Belt/Badges</th>
									<th>DOE</th>
									<th>Trade</th>
									<th>Email-id</th>
									<th>Remarks</th>
									<th>Action</th>
								</tr>
							</thead>
							<tbody>
								{sewadalData.map((data: SewadalDataModel, i: number) => (
									<tr key={i}>
										<td>
											{(currentPage - 1) * selectedItemsPerPage + i + 1}
										</td>									<td>{data.uniquePersonalNumber}</td>
										<td>{data.personalNumber}</td>
										<td>{data.name}</td>
										<td>{data.type}</td>
										<td>{data.dateOfAdd}</td>
										<td>{data.fathersName}</td>
										<td>{data.dateOfBirth}</td>
										<td>{data.qualification}</td>
										<td>{data.occupation}</td>
										<td>{data.address}</td>
										<td>{data.contactNo}</td>
										<td>{data.bloodGroup}</td>
										<td>{data.dateOfBadge}</td>
										<td>{data.dateOfEntry}</td>
										<td>{data.trade}</td>
										<td>{data.email}</td>
										<td>{data.remarks}</td>
										<td>
											<Button className="icon-button" onClick={() => handleShow(data)}>
												<FontAwesomeIcon icon={faLaptopCode} />
											</Button>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					) : (((user?.userType === USER_TYPE.ADMIN || user?.userType === USER_TYPE.KSHETRIYA_SANCHALAK) &&
						selectedUnitId === 0) ?
						(<div className="d-flex j-center help-text">
							{t("sewadal.select-unit-help-text")}
						</div>)
						: (
							<div className="d-flex j-center help-text">
								{t("satsang.no-satsang-record-found")}
							</div>)
					)}
					{sewadalData.length > 0 && (
						<Paginator
							totalItems={totalPages}
							itemsPerPage={selectedItemsPerPage}
							currentPage={currentPage}
							onPageChange={handlePageChange}
							onItemsPerPageChange={handleItemsPerPageChange}
						/>
					)}
				</div>
				{(selectedAttendanceUser && (
					<AddAttendanceUserModal show={show} handleClose={handleClose} sewadal={selectedAttendanceUser} handleSave={handleSaveAttendanceUser} />
				))}
			</div>
		</div>
	);
};

export default SewadalDetails;
