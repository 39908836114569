import React, { useState } from 'react';
import { Form, Button, Image } from 'react-bootstrap';
import './Login.css'; // Custom CSS file for specific styling
import { snmImageBase64 } from './Images/SnmLogo';
import { snmLoginImageBase64 } from './Images/SnmLogin';
import { LoginApiModel, LoginPayload } from 'src/Common/Models/Auth/LoginApiModel';
import { useMutation } from '@tanstack/react-query';
import { LoginApi } from 'src/api/authApi';
import { login } from 'src/redux/authSlice';
import { useDispatch } from 'react-redux';
import { hideLoader, showLoader } from 'src/redux/loaderSlice';
import { showToast } from 'src/redux/toastSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEyeSlash, faEye } from '@fortawesome/free-solid-svg-icons';
import { UAParser } from 'ua-parser-js';

interface LoginProps {
  onLogin: (creds: LoginPayload) => void;
}

const Login: React.FC = () => {
  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [showError, setShowError] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState(false);

  const dispatch = useDispatch();

  const parser = new UAParser();
  const browserInfo = parser.getResult();

  // console.log(browserInfo);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };


  const mutation = useMutation<LoginApiModel, unknown, LoginPayload>({
    mutationFn: LoginApi,
    onSuccess: (data: LoginApiModel) => {
      dispatch(login({
        accessToken: data.accessToken,
        refreshToken: data.refreshToken,
        user: data.user,
        permissions: data.user.bitwiseClearance
      }));
      dispatch(hideLoader());
    },
    onError: (error: any) => {
      setShowError(true);
      dispatch(hideLoader());
      console.error("Api failed:", error.response?.data || error.message);
    }
  });

  const handleLogin = (creds: LoginPayload): void => {
    dispatch(showLoader());
    mutation.mutate(creds);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleLogin({ login: username, password, browserInfo }); // Call handleLogin with credentials
  };

  return (
    <div className="login-container">
      {/* Section 1: Image Section */}
      <div className="image-section">
        <Image src={snmLoginImageBase64} alt="Background" fluid />
      </div>

      {/* Section 2: Login Form Section */}
      <div className="form-section position-relative">
        {showError && (
          <div className='alert-wrap'>
            <div className="alert alert-danger d-flex align-items-center" role="alert">
              <div>
                Invalid username or password!
              </div>
            </div>
          </div>
        )}
        <div className="form-content">
          {/* Logo Image */}
          <div className="logo-container">
            <Image src={snmImageBase64} className="logo" />
          </div>
          <h1>Welcome To</h1>
          <h3>SNM Sewadal Attendance Application</h3>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label>Username</Form.Label>
              <Form.Control
                type="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
            </Form.Group>
            {/* <Form.Group className="mb-3">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </Form.Group> */}
            <Form.Group className="mb-3 position-relative">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type={showPassword ? 'text' : 'password'}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              {/* Eye icon to toggle password visibility */}
              <FontAwesomeIcon
                icon={showPassword ? faEyeSlash : faEye}
                onClick={togglePasswordVisibility}
                className="password-toggle-icon"
              />
            </Form.Group>
            <Form.Group className="mb-3 d-flex justify-content-end align-items-center">
              {/* <Form.Check type="checkbox" className="KeepMeLoggedIn" label="Keep me logged in" /> */}
              <a href="#forgot-password" className="forgot-password-link">Forgot password?</a>
            </Form.Group>
            <Button variant="light" type="submit" className="w-100 login-button">
              Login
            </Button>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default Login;
